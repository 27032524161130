import { Component, OnInit, Input, NgModule, ElementRef } from '@angular/core';

import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.scss']
})



export class ModalVideoComponent implements OnInit {
  // iframe_html: any;
  // youtubeUrl = 'https://youtu.be/rOmYLiO_nmQ';


  constructor(public activeModal: NgbActiveModal, private router: Router, private _eref: ElementRef) {
    // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(videoURL);
  }


  ngOnInit() {
  }

}
