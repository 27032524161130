
import { Component, OnInit } from '@angular/core';
import { WizardComponent } from './../wizard/wizard.component';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-subscription-page',
  templateUrl: './subscription-page.component.html',
  styleUrls: ['./subscription-page.component.scss']
})
export class SubscriptionPageComponent implements OnInit {
  type: String  ;
  identif: string;
  private sub: any; constructor(private route: ActivatedRoute) { }
  ngOnInit() {
    this.identif = '1234';
    this.sub = this.route.params.subscribe(params => {
      this.type =  params['type'];
    });
    }
}
