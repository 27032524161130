import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-votre-contrat',
  templateUrl: './page-votre-contrat.component.html',
  styleUrls: ['./page-votre-contrat.component.scss']
})
export class PageVotreContratComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
