
import { Component, Input, NgModule, ElementRef, OnInit } from '@angular/core';
import { ViewChild, AfterViewInit } from '@angular/core';

import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';


@Component({
  selector: 'app-modal-stepone',
  templateUrl: './modal-stepOne.component.html',
  styleUrls: ['./modal-stepOne.component.scss'],
})


export class ModalStepOneComponent implements OnInit {
  // @ViewChild(ContentStepOneComponent) ContentStepOne;
  @Input() name;
  @Input() emailValue: string;
  constructor(public activeModal: NgbActiveModal, private router: Router, private _eref: ElementRef) {

}

ngOnInit() { 
  // const script = document.createElement('script');
  // script.src = '../assets/js/baliseGoogle.js';

  // document.body.appendChild(script);
};
fermer() {
  this.activeModal.close()
}
  //  sendMail(mail: string){
  //    console.log('sendmail to: ' + mail);
  // }
}
