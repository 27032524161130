
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef  } from '@angular/core';
import { child, recapChild } from './../../../assets/models/Client';
import { DatePipe } from '@angular/common'
import { FormControl } from '@angular/forms';
import {  ProspectProductServiceProxy, B3GResponseOfListOfProspectProductDto, ProspectProductDto } from '../../service-proxies/service-proxies';


@Component({
  selector: 'app-childdetails',
  templateUrl: './childdetails.component.html',
  styleUrls: ['./childdetails.component.scss']
})
export class ChilddetailsComponent implements OnInit {

  @Input() myId;
  @Input() NbChild;
  @Input() showComponent: boolean;
  @Input() myName;
  @Input() myIdValue;
  @Output() enfant;

  isFileUpload:boolean = true;
   fileUpload = null;
  _listCards: ProspectProductDto[] = [];

  activeChange:boolean;
  maxFile:boolean;
  activeBoy:boolean;

  loading:boolean=false;

 public idElement: string;

  dt:string;
  slideConfig18 = {'slidesToShow': 4, 'slidesToScroll': 4};
  inputChild: child = new child();
  validateLastName:boolean = true;
  validateName:boolean = true;
  validateCard:boolean = true;
  validateDate:boolean = true;

   maxDate = new Date(this.MaxDateCalendar());
   initDate;
   iDate;
   sysDateTime;
  constructor(private datepipe: DatePipe,
              private _prospectProduct: ProspectProductServiceProxy,private cdRef:ChangeDetectorRef) { }

  ngOnInit() {

    this.activeBoy=true;
    this.inputChild.gender='G';
    this.idElement = 'Code18Element-'+this.myId;
    if(this.myIdValue === this.idElement){
        this.inputChild.showMe = this.showComponent;
    }
    let dt: any;
    dt = this.datepipe.transform( this.MaxDateCalendar(), 'dd-MM-yyyy');
    this.inputChild.BirthDate = dt;
    this.validateDate=true;
    this.loadCards();

  }

  ngAfterViewChecked(){

    this.cdRef.detectChanges();
  }


  @Output() childEvent = new EventEmitter();
  @Output() childEventName = new EventEmitter();
  @Output() childEventRecap = new EventEmitter();

  @Output() childEventShowComponent = new EventEmitter();


getValueGenderGirl(event){
  if(event.target.checked){
   // this.inputChild.Gender = event.target.value;
    this.activeBoy=false;
    this.inputChild.gender='F';
  }
}
getValueGenderBoy(event){
  if(event.target.checked){
    this.activeBoy=true;
    this.inputChild.gender='G';
   }
}

  getRecapChild(){
    this.inputChild.showMe = true;
    this.inputChild.idRecap='idRecap-' + this.myId;
    this.inputChild.idChild = this.myId;
    this.inputChild.ActiveButtonValue = !this.activeChange;
    this.childEventRecap.emit(this.inputChild);
  }



  onFileChanged(event) {
  var res;
  this.fileUpload = null;
    if(event.target.files) {
      this.isFileUpload=true;
      let file = event.target.files[0];

       let blobImg: any;
        this.resizeImage(file,600,600).then(blob => {
         var reader = new FileReader();
         reader.readAsDataURL(blob);
         if(blob.size >= 1000000) {
          this.maxFile = true;
          return;
        } else {
          this.maxFile=false;
        }
         this.inputChild.fileSize = blob.size.toString();
         this.inputChild.fileType= blob.type;
         this.fileUpload = blob.size;
         reader.onload = () => {
          res = reader.result;
          var byteNumbers = new Array(res.length);
          for (var i = 0; i < res.length; i++) {
              byteNumbers[i] = res.charCodeAt(i);
          }
          this.inputChild.fileContent = byteNumbers.toString();
        }

      });
    } else  {
    this.isFileUpload=false;
  }



}

ValidateLastName(v:boolean){
   this.validateLastName = v;
  }
  ValidateName(v:boolean){
   this.validateName = v;
  }

  onSubmit(){
    if(this.fileUpload === null){ this.isFileUpload = false; return;} else { this.isFileUpload = true;}
    if(this.maxFile){ return; }
    if(!this.validateName && (this.inputChild.firstName ===null || this.inputChild.firstName ==="") ){ return; }
    if(!this.validateLastName){ return; }
    if(this.inputChild.cardPath===undefined || this.inputChild.cardPath ===''){this.validateCard=false; return; }
     if(this.inputChild.BirthDate===undefined || this.inputChild.BirthDate ===''){this.validateDate=false; return; }
    this.activeChange = true;

    this.getRecapChild();

  }
  getEdit(){
    this.activeChange=false;
  }
  recap(){
    this.onSubmit();
}
selectedSlide;
    onChangeCard(slide) {
    this.selectedSlide = Object.assign({}, this.selectedSlide, slide);
    this.inputChild.cardPath = this.selectedSlide.picture;
    this.inputChild.cardLibelle = this.selectedSlide.libelle;
    this.inputChild.cardID = this.selectedSlide.id;
    this.validateCard=true;
}

MaxDateCalendar(): Date {
      this.initDate = new Date();
      const d = 18;
      const y = this.initDate.getFullYear();
      const s = y - 18;
      this.initDate.setFullYear(s);
      // console.log('--- ' + this.initDate);
      this.sysDateTime = this.initDate.toString();
      this.iDate = new FormControl(new Date(this.sysDateTime));
      this.maxDate = this.iDate.value;
      return this.maxDate;
      }
      getBirthDate(event) {
        let dt: any;
        dt = this.datepipe.transform(event.value, 'dd-MM-yyyy');
        this.inputChild.BirthDate = dt;
        this.validateDate=true;
      }

      loadCards() {
        this.loading=true;
        this._prospectProduct.getProducts('2','')
        .subscribe((result: B3GResponseOfListOfProspectProductDto) => {
         this._listCards = result.result;
          });
      this.loading=false;
  }

  resizeImage(file:File, maxWidth:number, maxHeight:number):Promise<Blob> {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            let width = image.width;
            let height = image.height;

            if (width <= maxWidth && height <= maxHeight) {
                resolve(file);
            }

            let newWidth;
            let newHeight;

            if (width > height) {
                newHeight = height * (maxWidth / width);
                newWidth = maxWidth;
            } else {
                newWidth = width * (maxHeight / height);
                newHeight = maxHeight;
            }

            let canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            let context = canvas.getContext('2d');

            context.drawImage(image, 0, 0, newWidth, newHeight);

            canvas.toBlob(resolve, file.type);
        };
        image.onerror = reject;
    });
  }

}
