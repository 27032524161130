import { Subscriber } from "./../../../assets/models/Client";
import { sharedDataService } from "./../../sharedModels.service";
import { DeclinePopupComponent } from "./../decline-popup/decline-popup.component";
import { Component, NgZone, OnInit } from "@angular/core";
import * as progressbar from "progressbar.js";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { RefusePopupComponent } from "./../refuse-popup/refuse-popup.component";

import { Router, ActivatedRoute } from "@angular/router";
import {
  ProspectServiceProxy,
  B3GResponseOfInt32,
  ProspectInfoDto,
  OtpInput,
  ChangeEmail,
  MobileFinalizeProcess,
} from "src/app/service-proxies/service-proxies";
import { HttpClient } from "@angular/common/http";
import { ModalClientExistComponent } from "src/app/logOn/modal-client-exist/modal-client-exist.component";
import { ProspectService } from "src/app/service-proxies/prospect.service";
import { DatePipe } from "@angular/common";
import { PopupFatcaComponent } from "../popup-fatca/popup-fatca.component";
import { MreModalComponent } from "../mre-modal/mre-modal.component";

@Component({
  selector: "app-content-stepfive",
  templateUrl: "./content-stepFive.component.html",
  styleUrls: ["./content-stepFive.component.scss"],
})
export class ContentStepFiveComponent implements OnInit {
  client: Subscriber = new Subscriber();
  ReOk: boolean = false;
  meeting: boolean = false;
  editMail = false;
  arryOfNumber;
  otp = "";
  showOtpForm = false;
  newEmail;

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private router: Router,
    private _prospect: ProspectServiceProxy,
    private _prospectService: ProspectServiceProxy,
    public prospe: ProspectService,
    public datepipe: DatePipe,
    private ngZone: NgZone
  ) {
    this.newEmail = this.prospe.prospect.email;
    if (this.prospe.prospect == undefined || this.prospe.prospect.step == 6) {
      this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
    }
  }

  ngOnInit() {
    if (this.prospe.prospect.paysResidence != '144') {
      this.modalService.open(
        MreModalComponent,
        { backdrop: 'static', keyboard: false }
      );
    }
    
    this.client.Gender = this.prospe.prospect.gender;
    this.client.LastName = this.prospe.prospect.lastName;
    this.client.FirstName = this.prospe.prospect.firstName;
    this.client.Gsm = this.prospe.prospect.phoneNumber;
    this.client.Mail = this.prospe.prospect.email;
    this.client.CIN = this.prospe.prospect.identification;
    this.client.AgenceName = this.prospe.prospect.agencyName;
    this.client.TimeSlot = this.prospe.prospect.rendezVousLibelle;
    this.client.situationFamilial = this.prospe.prospect.situationFamilial;
    this.client.lieuNaissance = this.prospe.prospect.lieuNaissance;
    this.client.Adresse = this.prospe.prospect.adresseCIN;
    this.client.dateValidite = this.prospe.prospect.dateValidite;
    this.client.BirthDay = this.datepipe.transform(
      this.prospe.prospect.dateBirth,
      "dd-MM-yyyy"
    );
    this.client.IsFonctionnaire = this.prospe.prospect.fonctionnaire;
    // this.client.Appointement=this.prospe.prospect.rendez;
    this.client.BasicOffre = this.prospe.prospect.prospectOffer.offerLabel;
    //this.client.ComplementaryOffer = this.prospe.prospect.gender;

    const bar = new progressbar.Path("#step-path", {
      easing: "easeInOut",
      duration: 5000,
    });
    bar.set(0.685);
    bar.animate(1);

    const picurlContrat = document.getElementById(
      "idmgContrat"
    ) as HTMLImageElement;
    picurlContrat.src = "assets/image/contrat.png";
    const spanClassContract = document.getElementById(
      "LibelleContrat"
    ) as HTMLSpanElement;
    spanClassContract.classList.add("active");
  }

  rundomPermutation() {
    let arry: Array<any> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    for (let idx = 0; idx < arry.length; idx++) {
      const swpIdx = idx + Math.floor(Math.random() * (arry.length - idx));
      // now swap elements at idx and swpIdx
      const tmp = arry[idx];
      arry[idx] = arry[swpIdx];
      arry[swpIdx] = tmp;
    }
    return this.PermutationAvoidLast(arry);
  }
  PermutationAvoidLast(arry) {
    const tmp = arry[arry.length - 2];
    arry[arry.length - 2] = arry[arry.length - 1];
    arry[arry.length - 1] = tmp;
    return arry;
  }

  OnRefuse() {
    const modalRef = this.modalService.open(RefusePopupComponent);
  }
  prospectmodel: MobileFinalizeProcess = new MobileFinalizeProcess();
  onsubmit() {
    //      this.http.get('https://tbl.tradedoubler.com/report?organization=2242210&event=398084&leadNumber=auto').subscribe(data => {
    //     },

    // error => {

    // });
    //let p= new ProspectModel();
    //  p.id =this.sharedProspect.objectProspect['_id'];
    this.prospectmodel.day = sessionStorage.getItem("day");
    this._prospect
      .finalizeProcess(this.prospectmodel, localStorage.getItem("sessionId"))
      .subscribe(
        (result: B3GResponseOfInt32) => {
          const script = document.createElement("script");
          script.src = "../assets/js/baliseGoogle.js";
          document.head.appendChild(script);
          let ngbModalOptions: NgbModalOptions = {
            backdrop: "static",
          };
          this.http.get("https://richmedia.ma/cih/trackingconv.php").subscribe(
            (data) => {},

            (error) => {}
          );
          if (result.statusCode == "000") {
            this.modalService.open(DeclinePopupComponent, ngbModalOptions);
          } else if (result.statusCode == "30021403") {
            const modalRef1 = this.modalService.open(
              ModalClientExistComponent,
              ngbModalOptions
            );
            modalRef1.componentInstance.showClient = true;
          } else if (result.statusCode == "10300") {
            const modalRef1 = this.modalService.open(
              PopupFatcaComponent,
              ngbModalOptions
            );
          } else {
            alert("une erreur est survenu, merci de reessayer plutard");
          }
        },
        (err) => {
          this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
        }
      );
  }

  keyOfOtp(number) {
    this.otp += number.toString();
  }
  clean() {
    this.otp = "";
  }
  confirmEmail() {
    this._prospectService
      .checkEmailOtp(
        new OtpInput({ otp: this.otp }),
        localStorage.getItem("sessionId")
      )
      .subscribe((e) => {
        if (e.statusCode == "000") {
          this._prospectService
            .getProspectInfo(localStorage.getItem("sessionId"))
            .subscribe((el) => {
              this.prospe.prospect = el.result;
              this.showOtpForm = false;
            });
        }
      });
  }
  cancel() {
    this.showOtpForm = false;
  }
  editMailAttempt() {
    this.editMail = true;
  }
  closeEditEmail() {
    this.editMail = false;
  }
  chengeEmail() {
    this._prospectService
      .changeMail(
        new ChangeEmail({ mail: this.newEmail }),
        localStorage.getItem("sessionId")
      )
      .subscribe((e) => {
        if (e.statusCode == "000") {
          this._prospectService
            .getProspectInfo(localStorage.getItem("sessionId"))
            .subscribe((el) => {
              this.prospe.prospect = el.result;
              this.client.Mail = this.prospe.prospect.email;
              this.editMail = false;
            });
        }
      });
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  back() {
    this.ngZone.run(() => this.router.navigate(["/PriseDeContact"])).then();
  }
}
