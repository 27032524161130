import {
  CompleteProspectDto,
  MediaFileProspectDto,
  ProspectServiceProxy,
  BadFilesInput,
  ProspectMedia,
} from "./../../service-proxies/service-proxies";
import { PrincipalOffer } from "./../../sharedModels.service";
import { StatusSuiviDossier, Status } from "src/app/Globals.enum";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProspectModel } from "src/assets/models/ProspectModel";
import { B3GResponseOfProspectInfoDto } from "src/app/service-proxies/service-proxies";
import { Router } from "@angular/router";
import { NgxImageCompressService } from "ngx-image-compress";
//import paramData from '../../../assets/data/param.json' ;
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Component({
  selector: "app-up-load-prinicipal-offer",
  templateUrl: "./up-load-prinicipal-offer.component.html",
  styleUrls: ["./up-load-prinicipal-offer.component.scss"],
})
export class UpLoadPrinicipalOfferComponent implements OnInit {
  @Input() nom: string;
  @Input() prenom: string;

  @Input() idFileRecto: number;
  @Input() idFileVerso: number;
  @Input() idFileChild: number;
  @Input() isChild: boolean;
  @Input() isFonctionaire: boolean;
  @Input() idFileEngagement: number;
  @Input() principalOffer: PrincipalOffer;
  // @Input() childOffer: EighteenOffer ;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() listMediaFile: ProspectMedia[];
  @Input() prospectSystem;
  @Output() executeFile = new EventEmitter<boolean>();

  @Input() OffreCode18: boolean;
  @Input() OffrePrincipal: boolean;

  @Output() resultUpdateFile: boolean;
  fileRecto;
  fileVerso;
  fileEngagement;
  justifAdresseResidence;
  justifAdresseCourier;
  fileMRER;
  fileMREV;
  fileChild;
  isCINRecto: boolean = true;
  isCINVerso: boolean = true;
  isJAC = true;
  isJAR = true;
  isMreR = true;
  isMreV = true;
  isvalidJUC = false;
  isvalidJUR = false;
  isvalidMreR = false;
  isvalidMreV = false;
  isEngagement: boolean = true;
  isSizeRectoValid: boolean = true;
  isSizeVersoValid: boolean = true;
  isSizeEngagementValid: boolean = true;
  isActeVerso: boolean = true;
  isActeVersoValid: boolean = true;

  modelRecto = new MediaFileProspectDto();
  modelVerso = new MediaFileProspectDto();
  modelEngagement = new MediaFileProspectDto();
  modelJAC = new MediaFileProspectDto();
  modelJAR = new MediaFileProspectDto();
  modelMRER = new MediaFileProspectDto();
  modelMREV = new MediaFileProspectDto();
  modelChild = new MediaFileProspectDto();

  prospect;
  paramData;
  // fileToUploa: { type: string, state: string }[] = []
  fileToUploa: string[] = [];
  listFileType: string[] = [
    "CIR",
    "CIV",
    "JAC",
    "JAR",
    "CSR",
    "CSV",
    "ACT",
    "SS",
  ];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private imageCompress: NgxImageCompressService,
    private modalService: NgbModal,
    private router: Router,
    private _mediaFile: ProspectServiceProxy
  ) {
    // this._mediaFile.getProspectInfo(localStorage.getItem("sessionId"), "")
    //   .subscribe((result: B3GResponseOfProspectInfoDto) => {
    //    this.isFonctinnaire = result.result.fonctionnaire;
    //     this.prospect = result.result.idClient
    //     this.listMediaFile = result.result.prospectMedia
    //     // this.listMediaFile.forEach(e => {
    //     //   if (e.description == 'NonConforme') {
    //     //     this.fileToUploa.push(e.type)
    //     //   }
    //     // })
    //   })

    this.getJSON().subscribe((data) => {
      this.paramData = data;
    });
  }
  public getJSON(): Observable<any> {
    return this.http.get("../../../assets/data/param.json");
  }
  ngOnInit() {
    // this.listFileType.forEach(el => {
    //   if (this.listMediaFile.filter(s => s.type == el).length > 0) {
    //     let r = this.listMediaFile.filter(e => e.type == el)[0]
    //     this.fileToUploa.push({ type: r.type, state: r.description })
    //   }
    // })
    this.listMediaFile.forEach((e) => {
      if (e.description == "NonConforme") {
        this.fileToUploa.push(e.type);
      }
    });
  }

  onFileChangedRecto(event) {
    var res;
    this.fileRecto = null;

    if (event.target.files) {
      const validImageTypes = ["image/jpeg", "image/png"];
      let file = event.target.files[0];
      const fileType = file["type"];
      let blobImg: any;
      if (validImageTypes.includes(fileType)) {
        this.selectFileRecto(event);
      } else {
        if (file["type"] == "application/pdf") {
          this.fileRecto = true;
          if (file["size"] > 1000000) {
            this.isSizeRectoValid = false;
          } else {
            this.isSizeRectoValid = true;
          }
          this.modelRecto.size = file["size"].toString();
          this.modelRecto.name = file.name;
          this.modelRecto.mimeType = file["type"];
          this.modelRecto.prospectId = this.prospect;
          this.fileRecto = file["size"];
          //this.selectFileEngagement(event)
          var reader = new FileReader();

          reader.onload = this.handleReaderLoadedRecto.bind(this);

          reader.readAsBinaryString(file);
        }
      }
    }
  }

  onFileChangedVerso(event) {
    var res;
    this.fileVerso = null;

    if (event.target.files) {
      const validImageTypes = ["image/jpeg", "image/png"];
      let file = event.target.files[0];
      const fileType = file["type"];
      let blobImg: any;
      if (validImageTypes.includes(fileType)) {
        this.selectFileVerso(event);
      } else {
        if (file["type"] == "application/pdf") {
          this.fileVerso = true;
          if (file["size"] > 1000000) {
            this.isSizeVersoValid = false;
          } else {
            this.isSizeVersoValid = true;
          }
          this.modelVerso.size = file["size"].toString();
          this.modelVerso.name = file.name;
          this.modelVerso.mimeType = file["type"];
          this.modelVerso.prospectId = this.prospect;
          this.fileVerso = file["size"];
          var reader = new FileReader();

          reader.onload = this.handleReaderLoadedVerso.bind(this);

          reader.readAsBinaryString(file);
        }
      }
    }
  }
  onFileChangedEngagement(event) {
    var res;
    this.fileEngagement = null;

    if (event.target.files) {
      const validImageTypes = ["image/jpeg", "image/png"];
      let file = event.target.files[0];
      const fileType = file["type"];
      let blobImg: any;
      if (validImageTypes.includes(fileType)) {
        this.selectFileEngagement(event);
      } else {
        if (
          file["type"] == "application/pdf" ||
          file["type"] ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          this.isEngagement = true;
          if (file["size"] >= this.paramData.maxFileSize) {
            this.isSizeEngagementValid = false;
          } else {
            this.isSizeEngagementValid = true;
          }
          this.modelEngagement.size = file["size"].toString();
          this.modelEngagement.name = file.name;
          this.modelEngagement.mimeType = file["type"];
          this.modelEngagement.prospectId = this.prospect;
          this.fileEngagement = file["size"];
          //this.selectFileEngagement(event)
          var reader = new FileReader();

          reader.onload = this.handleReaderLoadedEngagement.bind(this);

          reader.readAsBinaryString(file);
        }
      }
    }
  }

  onFileChangedActeNaissance(event) {
    var res;
    this.fileChild = null;

    let file = event.target.files[0];
    if (event.target.files) {
      let blobImg: any;
      //this.resizeImage(file,600,600)
      this.resizeImage(file, 600, 600).then((blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        if (blob.size >= 15000000) {
          this.isSizeVersoValid = false;
          return;
        } else {
          this.isSizeVersoValid = true;
        }

        this.modelChild.size = blob.size;
        this.modelChild.mimeType = blob.type;
        this.modelChild.name = file.name;
        this.fileChild = blob.size;
        reader.onload = () => {
          res = reader.result;
          var byteNumbers = new Array(res.length);
          for (var i = 0; i < res.length; i++) {
            byteNumbers[i] = res.charCodeAt(i);
          }
          this.modelChild.fileContent = byteNumbers.toString();
        };
      });
    }
    //this.modelChild.id =  this.idFileChild;
  }
  validateButton = false;
  validateFile(event) {
    // let resultat:boolean = false;

    if (this.prospectSystem) {
      if (this.fileToUploa.includes("CIR")) {
        if (this.fileRecto != null && this.fileRecto != undefined) {
          this.validateButton = true;
        } else {
          this.validateButton = false;
        }
      }
      if (this.fileToUploa.includes("CIV")) {
        if (this.fileVerso != null && this.fileVerso != undefined) {
          this.validateButton = true;
        } else {
          this.validateButton = false;
        }
      }
      if (this.fileToUploa.includes("CSV")) {
        if (this.fileMREV != null && this.fileMREV != undefined) {
          this.validateButton = true;
        } else {
          this.validateButton = false;
        }
      }
      if (this.fileToUploa.includes("CSR")) {
        if (this.fileMRER != null && this.fileMRER != undefined) {
          this.validateButton = true;
        } else {
          this.validateButton = false;
        }
      }
      if (this.fileToUploa.includes("ACT")) {
        if (this.fileEngagement != null && this.fileEngagement != undefined) {
          this.validateButton = true;
        } else {
          this.validateButton = false;
        }
      }
      if (this.fileToUploa.includes("JAC")) {
        if (
          this.justifAdresseCourier != null &&
          this.justifAdresseCourier != undefined
        ) {
          this.validateButton = true;
        } else {
          this.validateButton = false;
        }
      }
      if (this.fileToUploa.includes("JAR")) {
        if (
          this.justifAdresseResidence != null &&
          this.justifAdresseResidence != undefined
        ) {
          this.validateButton = true;
        } else {
          this.validateButton = false;
        }
      }
    } else {
      if (this.fileRecto != null && this.fileRecto != undefined) {
        this.validateButton = true;
      } else {
        this.validateButton = false;
      }
      if (this.fileVerso != null && this.fileVerso != undefined) {
        this.validateButton = true;
      } else {
        this.validateButton = false;
      }
      if (this.isFonctionaire) {
        if (this.fileEngagement != null && this.fileEngagement != undefined) {
          this.validateButton = true;
        } else {
          this.validateButton = false;
        }
      }
    }

    if (this.validateButton) {
      this.update(
        this.modelRecto,
        this.modelVerso,
        this.modelEngagement,
        this.modelJAC,
        this.modelJAR,
        this.modelMRER,
        this.modelMREV,
        this.prospect
      );
    } else {
      console.log("veuillez remplir tous mes champs");
    }
    // if (!this.isChild) {
    //   if (this.fileRecto === null || this.fileRecto === undefined) {
    //     this.isCINRecto = false; console.log('dddsd');
    //     return;
    //   } else { this.isCINRecto = true; }
    //   if (this.fileVerso === null || this.fileVerso === undefined) { this.isCINVerso = false; return; } else { this.isCINVerso = true; }

    //   if (!this.isSizeRectoValid) { return; }
    //   if (!this.isSizeVersoValid) { return; }

    //   if (this.isFonctinnaire == true && this.fileToUploa.includes('ACT')) {
    //     if (this.fileEngagement === null || this.fileEngagement === undefined) { this.isEngagement = false; return; }
    //     else { this.isEngagement = true; }

    //   }
    //   else {
    //     this.modelEngagement.fileContent = null
    //     this.update(this.modelRecto, this.modelVerso, this.modelEngagement, this.modelJAC, this.modelJAR,this.modelMRER,this.modelMREV, this.prospect);

    //   }
    // }
    // else {
    //   if (this.fileChild === null || this.fileChild === undefined) { this.isActeVerso = false; return; } else { this.isActeVerso = true; }
    //   if (!this.isActeVersoValid) { return; }
    // };
  }

  update(modelR, modelV, extraits, jac, jar, mreR, mreV, id) {
    //this._mediaFile.uploadBadFiles(model,localStorage.getItem('sessionId'))
    this._mediaFile
      .uploadBadFiles(
        new BadFilesInput({
          recto: modelR,
          verso: modelV,
          extrait: extraits,
          justificationAdressDuCourrier: jac,
          justificationAdressDuResidence: jar,
          prospectId: "",
          mRERecto: mreR,
          mREVerso: mreV,
        }),
        localStorage.getItem("sessionId")
      )
      .subscribe((result: any) => {
        switch (result.statusCode) {
          case Status.OK:
            this.resultUpdateFile = result.result;
            this.passEntry.emit(this.resultUpdateFile);
            this.modalService.dismissAll();
            window.location.reload();
            return;
          default:
            this.resultUpdateFile = result.result;
            this.passEntry.emit(this.resultUpdateFile);
            return;
        }
      });
  }
  close() {
    this.modalService.dismissAll();
  }

  handleReaderLoadedEngagement(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.modelEngagement.fileContent = btoa(binaryString);
  }
  handleReaderLoadedRecto(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.modelRecto.fileContent = btoa(binaryString);
  }
  handleReaderLoadedVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.modelVerso.fileContent = btoa(binaryString);
  }
  handleReaderLoadedJAC(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.modelJAC.fileContent = btoa(binaryString);
  }
  handleReaderLoadedJAR(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.modelJAR.fileContent = btoa(binaryString);
  }
  handleReaderLoadedMRER(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.modelMRER.fileContent = btoa(binaryString);
  }
  handleReaderLoadedMREV(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.modelMREV.fileContent = btoa(binaryString);
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        let canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context = canvas.getContext("2d");

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  file: any;
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;
  isSizeJAC: boolean = true;
  isSizeJAR: boolean = true;
  isSizeMRER: boolean = true;
  isSizeMREV: boolean = true;
  selectFileVerso(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (this.file["size"] > 15000000) {
      this.isSizeVersoValid = false;
    } else {
      this.isSizeVersoValid = true;
    }
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileVerso(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  selectFileRecto(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (this.file["size"] > 15000000) {
      this.isSizeRectoValid = false;
    } else {
      this.isSizeRectoValid = true;
    }
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileRecto(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  selectFileEngagement(event) {
    const validImageTypes = ["image/jpeg", "image/png"];
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (validImageTypes.includes(this.file["type"])) {
      if (this.file["size"] > 15000000) {
        this.isSizeEngagementValid = false;
      } else {
        this.isSizeEngagementValid = true;
      }
      if (event.target.files && event.target.files[0]) {
        this.isEngagement = true;
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.localUrl = event.target.result;
          this.compressFileEngagement(this.localUrl, fileName);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else {
      if (
        this.file["type"] == "application/pdf" ||
        this.file["type"] ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        this.isEngagement = true;
        if (this.file["size"] >= this.paramData.maxFileSize) {
          this.isSizeEngagementValid = false;
          return;
        } else {
          this.isSizeEngagementValid = true;
        }
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.localUrl = event.target.result;
            this.compressFileEngagement(this.localUrl, fileName);
          };
          reader.readAsDataURL(event.target.files[0]);
        }
      }
    }
  }

  compressFileVerso(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.modelVerso.size = imageBlob.size;
        this.modelVerso.mimeType = imageBlob.type;
        this.modelVerso.name = fileName;
        this.modelVerso.prospectId = this.prospect;
        this.fileVerso = imageBlob.size;
        const imageFile = new File([result], fileName, {
          type: this.modelVerso.mimeType,
        });
        this.modelVerso.fileContent = this.localCompressedURl.split(",")[1];
      });
  }
  compressFileRecto(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn("Size in bytes is now:", this.sizeOfOriginalImage);
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.modelRecto.size = imageBlob.size;
        this.modelRecto.mimeType = imageBlob.type;
        this.modelRecto.name = fileName;
        this.modelRecto.prospectId = this.prospect;
        this.fileRecto = imageBlob.size;
        const imageFile = new File([result], fileName, {
          type: this.modelRecto.mimeType,
        });
        this.modelRecto.fileContent = this.localCompressedURl.split(",")[1];
      });
  }
  compressFileEngagement(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.modelEngagement.size = imageBlob.size;
        this.modelEngagement.mimeType = imageBlob.type;
        this.modelEngagement.name = fileName;
        this.modelEngagement.prospectId = this.prospect;
        this.fileEngagement = imageBlob.size;
        const imageFile = new File([result], fileName, {
          type: this.modelEngagement.mimeType,
        });
        this.modelEngagement.fileContent =
          this.localCompressedURl.split(",")[1];
      });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }
  onFileChangedJAC(event) {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      const validImageTypes = ["image/jpeg", "image/png"];
      const fileType = file["type"];
      if (file) {
        var reader = new FileReader();

        reader.onload = this.handleReaderLoadedJAC.bind(this);

        reader.readAsBinaryString(file);
      }
      this.modelJAC.name = file.name;
      if (file["size"] > 15000000) {
        this.isSizeJAC = false;
      } else {
        this.isSizeJAC = true;
      }
      let blobImg: any;
      if (file) {
        if (validImageTypes.includes(fileType)) this.selectFileJAC(event);
        else {
          if (file["type"] == "application/pdf") {
            //if (file['type'] == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            this.isJAC = true;
            this.isvalidJUC = true;
            this.modelJAC.size = file["size"].toString();
            this.modelJAC.mimeType = file["type"];
            this.justifAdresseCourier = file["size"];
            //  this.modelJAC.type = file['type'];
          }
        }
      }
    }
  }
  onFileChangedJAR(event) {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      const validImageTypes = ["image/jpeg", "image/png"];
      const fileType = file["type"];
      if (file) {
        var reader = new FileReader();

        reader.onload = this.handleReaderLoadedJAR.bind(this);

        reader.readAsBinaryString(file);
      }
      this.modelJAR.name = file.name;
      if (file["size"] > 15000000) {
        this.isSizeJAR = false;
      } else {
        this.isSizeJAR = true;
      }
      let blobImg: any;
      if (file) {
        if (validImageTypes.includes(fileType)) this.selectFileJAR(event);
        else {
          if (file["type"] == "application/pdf") {
            //if (file['type'] == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            this.isJAR = true;
            this.isvalidJUR = true;
            this.modelJAR.size = file["size"].toString();
            this.modelJAR.mimeType = file["type"];
            this.justifAdresseResidence = file["size"];
            // this.modelJAR.type = file['type'];
          }
        }
      }
    }
  }
  onFileChangedMRER(event) {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      const validImageTypes = ["image/jpeg", "image/png"];
      const fileType = file["type"];
      if (file) {
        var reader = new FileReader();

        reader.onload = this.handleReaderLoadedMRER.bind(this);

        reader.readAsBinaryString(file);
      }
      this.modelMRER.name = file.name;
      if (file["size"] > 15000000) {
        this.isSizeMRER = false;
      } else {
        this.isSizeMRER = true;
      }
      let blobImg: any;
      if (file) {
        if (validImageTypes.includes(fileType)) this.selectFileMRER(event);
        else {
          if (file["type"] == "application/pdf") {
            //if (file['type'] == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            this.isMreR = true;
            this.isvalidMreR = true;
            this.modelMRER.size = file["size"].toString();
            this.modelMRER.mimeType = file["type"];
            this.fileMRER = file["size"];
          }
        }
      }
    }
  }
  onFileChangedMREV(event) {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      const validImageTypes = ["image/jpeg", "image/png"];
      const fileType = file["type"];
      if (file) {
        var reader = new FileReader();

        reader.onload = this.handleReaderLoadedMREV.bind(this);

        reader.readAsBinaryString(file);
      }
      this.modelMREV.name = file.name;
      if (file["size"] > 15000000) {
        this.isSizeMREV = false;
      } else {
        this.isSizeMREV = true;
      }
      let blobImg: any;
      if (file) {
        if (validImageTypes.includes(fileType)) this.selectFileMREV(event);
        else {
          if (file["type"] == "application/pdf") {
            //if (file['type'] == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            this.isMreV = true;
            this.isvalidMreV = true;
            this.modelMREV.size = file["size"].toString();
            this.modelMREV.mimeType = file["type"];
            this.fileMREV = file["size"];
          }
        }
      }
    }
  }
  selectFileJAC(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileJAC(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  selectFileJAR(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileJAR(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  selectFileMRER(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileMRER(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  selectFileMREV(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileMREV(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  compressFileJAC(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    let imageFile;
    //do{
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.modelJAC.size = imageBlob.size;
        this.modelJAC.mimeType = imageBlob.type;
        this.modelJAC.name = fileName;
        //this.fileRectoAllElement.prospectId = this.prospect
        this.justifAdresseCourier = imageBlob.size;
        imageFile = new File([result], fileName, { type: this.modelJAC.type });
        this.modelJAC.fileContent = this.localCompressedURl.split(",")[1];
      });
  }
  compressFileJAR(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    let imageFile;
    //do{
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.modelJAR.size = imageBlob.size;
        this.modelJAR.mimeType = imageBlob.type;
        this.modelJAR.name = fileName;
        //this.fileRectoAllElement.prospectId = this.prospect
        this.justifAdresseResidence = imageBlob.size;
        imageFile = new File([result], fileName, { type: this.modelJAR.type });
        this.modelJAR.fileContent = this.localCompressedURl.split(",")[1];
      });
  }
  compressFileMRER(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    let imageFile;
    //do{
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.modelMRER.size = imageBlob.size;
        this.modelMRER.mimeType = imageBlob.type;
        this.modelMRER.name = fileName;

        //this.fileRectoAllElement.prospectId = this.prospect
        this.fileMRER = imageBlob.size;
        imageFile = new File([result], fileName, { type: this.modelMRER.type });
        this.modelMRER.fileContent = this.localCompressedURl.split(",")[1];
      });
  }
  compressFileMREV(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    let imageFile;
    //do{
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.modelMREV.size = imageBlob.size;
        this.modelMREV.mimeType = imageBlob.type;
        this.modelMREV.name = fileName;
        //this.fileRectoAllElement.prospectId = this.prospect
        this.fileMREV = imageBlob.size;
        imageFile = new File([result], fileName, { type: this.modelMREV.type });
        this.modelMREV.fileContent = this.localCompressedURl.split(",")[1];
      });
  }
}
