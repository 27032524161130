import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IdentityServiceProxy, CheckTokenInput } from '../service-proxies/service-proxies';

@Injectable()
export class isTokenValid implements Resolve<Observable<any>> {
    constructor(private router: Router, private route: ActivatedRoute, private identityServiceProxy: IdentityServiceProxy) { }

    resolve() {
        //this.route.params.subscribe(params=>{
        let token = this.route.snapshot.params['token']
        if (token) {
            this.identityServiceProxy.forgotPasswordTwo(new CheckTokenInput({ token }), "").subscribe(value => {
                switch (value.statusCode) {
                    case "000":
                        if (value.result)
                            return of(null);
                        else {
                            this.router.navigate(['LogIn'])
                            return of(null);
                        }


                    default:
                        this.router.navigate(['LogIn'])
                        return of(null);

                }
            })
        } else {
            /* this.router.navigate(['login']); */
            this.router.navigate(['LogIn'])
            return of(null);
        }
    }
}