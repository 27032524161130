import { sharedDataService } from "./../../sharedModels.service";
import { Subscriber } from "./../../../assets/models/Client";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
} from "@angular/core";
import { Router } from "@angular/router";
import * as progressbar from "progressbar.js";

import {
  ProspectServiceProxy,
  B3GResponseOfProspectInfoDto,
  ConfirmGSMInput,
} from "./../../service-proxies/service-proxies";

import { viewProspect } from "src/app/sharedModels.service";
import { Status } from "src/app/Globals.enum";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-content-steptwo",
  templateUrl: "./content-stepTwo.component.html",
  styleUrls: ["./content-stepTwo.component.scss"],
})
export class ContentStepTwoComponent implements OnInit {
  loading: boolean = false;
  @Output() renovyer = new EventEmitter<string>();
  @Input() viewProspect: Subscriber;
  isOptpValid: boolean = true;

  public confirmGSMIn: ConfirmGSMInput = new ConfirmGSMInput();

  client: Subscriber = new Subscriber();
  valone;
  valtwo;
  valtree;
  valfour;
  valfive;
  valsix;
  constructor(
    private router: Router,
    private _prospect: ProspectServiceProxy,
    private _sharedData: sharedDataService,
    private datePipe: DatePipe,
    private ngZone: NgZone
  ) {}

  valueBtn = [];
  varNb: number;

  ngOnInit() {
    // this.ngxLoader.start();
    /*    if (this.viewProspect.Mail) window.localStorage.setItem('LHC_username', `${viewProspect['FirstName']} ${viewProspect['LastName']}`);
       if (this.viewProspect.Mail) var email = window.localStorage.setItem('LHC_email', this.viewProspect.Mail);
       if (this.viewProspect.phoneNumber) var phone = window.localStorage.setItem('LHC_phone', this.viewProspect.phoneNumber); */
    const bar = new progressbar.Path("#step-path", {
      easing: "easeInOut",
      duration: 5000,
    });
    bar.set(0.214);
    bar.animate(0.365);
    const picurl = document.getElementById("idmg") as HTMLImageElement;
    picurl.src = "assets/image/confirm.png";
    const spanClassConfirm = document.getElementById(
      "LibelleConfirm"
    ) as HTMLSpanElement;
    spanClassConfirm.classList.add("active");
    const list: Number[] = new Array(10);
    this.varNb = 0;
    while (this.varNb < 10) {
      const n = Math.floor(Math.random() * 10);
      if (list.indexOf(n) === -1) {
        list[this.varNb] = n;
        this.varNb += 1;
      }
    }
    for (let i = 0; i < 10; i++) {
      this.valueBtn[i] = list[i];
    }
  }

  clickNumber(value: string) {
    for (let i = 1; i < 7; i++) {
      const input = document.getElementById("text-" + i) as HTMLInputElement;
      if (input.value === "" || input.value === null) {
        input.value = value;
        this.changeInputValue(value, i);
        return;
      } else {
        input.value = input.value;
        this.changeInputValue(input.value, i);
      }
    }
  }

  clearInput() {
    const emptyvalue = "";
    for (let j = 6; j > 0; j--) {
      const input = document.getElementById("text-" + j) as HTMLInputElement;
      const valInput = input.value;
      if (input.value.toString() !== "") {
        input.value = emptyvalue;
        this.changeInputValue(emptyvalue, j);
        return false;
      } else {
        input.value = valInput;
        this.changeInputValue(valInput, j);
      }
    }
  }

  clearAll() {
    for (let j = 1; j < 7; j++) {
      const input = document.getElementById("text-" + j) as HTMLInputElement;
      this.changeInputValue("", j);
    }
  }

  RenvoyerSMS() {
    this.renovyer.emit();
    // service renvoi otp
  }

  ConfirmSMS() {
    this.loading = true;
    // this.ngxLoader.start();

    this.changeStyles();
    if (this.valone === "" || this.valone === undefined) {
      return;
    }
    if (this.valtwo === "" || this.valtwo === undefined) {
      return;
    }
    if (this.valtree === "" || this.valtree === undefined) {
      return;
    }
    if (this.valfour === "" || this.valfour === undefined) {
      return;
    }
    if (this.valfive === "" || this.valfive === undefined) {
      return;
    }
    if (this.valsix === "" || this.valsix === undefined) {
      return;
    }
    const val =
      this.valone +
      this.valtwo +
      this.valtree +
      this.valfour +
      this.valfive +
      this.valsix;
    this.confirmGSMIn.prospectToken = localStorage.getItem("token");
    this.confirmGSMIn.otp = val;
    this.confirmGSMIn.email = this.viewProspect.Mail;
    this.confirmGSMIn.numberPhone = this.viewProspect.phoneNumber;

    this._prospect.confirmGSM(this.confirmGSMIn, "").subscribe(
      (result: B3GResponseOfProspectInfoDto) => {
        result.statusCode = "000";
        switch (result.statusCode) {
          case Status.OK:
            let _vProspectConfirmer = new viewProspect();
            _vProspectConfirmer.id = this.viewProspect.idClient;
            // _vProspectConfirmer.gender = result.result.gender;
            _vProspectConfirmer.firstName = result.result.firstName;
            _vProspectConfirmer.lastName = result.result.lastName;

            _vProspectConfirmer.dateBirth = this.datePipe.transform(
              result.result.dateBirth,
              "dd-MM-yyyy"
            );
            _vProspectConfirmer.mobile = result.result.phoneNumber;
            _vProspectConfirmer.operatorLabel = result.result.operatorLabel;
            _vProspectConfirmer.mail = result.result.email;
            _vProspectConfirmer.nbChild = result.result.nbChild;
            // _vProspectConfirmer.offerId = result.result.prospectOffers.offerId.toString();
            // _vProspectConfirmer.offerLabel = result.result.prospectOffers.offerLabel;
            // _vProspectConfirmer.offerPicture = result.result.prospectOffers.offerPicture;
            // _vProspectConfirmer.FFonctiaire = result.result.fonctionnaire
            this._sharedData.objectProspect = _vProspectConfirmer;
            // this.ngxLoader.stop()
            this.ngZone.run(() => this.router.navigate(["/VotreOffre"])).then();
            break;
          default:
            this.isOptpValid = false;
            break;
        }
      },
      (err) => {
        this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
      }
    );
  }

  changeInputValue(value: string, i: number) {
    const input = document.getElementById("text-" + i) as HTMLInputElement;
    input.value = value;
    switch (i) {
      case 1: {
        this.valone = value;
        break;
      }
      case 2: {
        this.valtwo = value;
        break;
      }
      case 3: {
        this.valtree = value;
        break;
      }
      case 4: {
        this.valfour = value;
        break;
      }
      case 5: {
        this.valfive = value;
        break;
      }
      case 6: {
        this.valsix = value;
        break;
      }
      default: {
        // console.log('Invalid choice');
        break;
      }
    }

    if (!Number(value) && value !== "0") {
      input.classList.add("isRequired");
      input.classList.remove("isValid");
      return;
    } else input.classList.remove("isRequired");
    input.classList.add("isValid");
    return;
  }

  changeStyles() {
    let lst = [1, 2, 3, 4, 5, 6];
    lst.forEach((el) => {
      const input = document.getElementById("text-" + el) as HTMLInputElement;
      if (
        input.value === "" ||
        input.value === undefined ||
        input.value === null
      ) {
        input.classList.add("isRequired");
        input.classList.remove("isValid");
      } else {
        input.classList.remove("isRequired");
        input.classList.add("isValid");
      }
    });
  }
}
