import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { StepsMenuComponent } from './subscriptionSteps/steps-menu/steps-menu.component';
import { FooterComponent } from './subscriptionSteps/footer/footer.component';
import { ContentStepOneComponent } from './subscriptionSteps/content-stepOne/content-stepOne.component';
import { FormsModule } from '@angular/forms';
import { ModalStepOneComponent } from './subscriptionSteps/modal-stepOne/modal-stepOne.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { routes } from './routes';
import { RouterModule } from '@angular/router';
import { SubscriptionPageComponent } from './subscriptionSteps/subscription-page/subscription-page.component';
import { ContentStepTwoComponent } from './subscriptionSteps/content-stepTwo/content-stepTwo.component';
import { ContentStepThreeComponent } from './subscriptionSteps/content-stepThree/content-stepThree.component';
import { ContentStepFourComponent } from './subscriptionSteps/content-stepFour/content-stepFour.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { ContentStepFiveComponent } from './subscriptionSteps/content-stepFive/content-stepFive.component';
import { AuthenticationPageComponent } from './logOn/authentication-page/authentication-page.component';
import { FollowUpFilePageComponent } from './logOn/followUpFile-page/followUpFile-page.component';
import { WizardComponent } from './subscriptionSteps/wizard/wizard.component';
import { ModalVideoComponent } from './landing-page/modal-video/modal-video.component';
import { ServiceProxyModule } from './service-proxies/service-proxies.module';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material';
import 'hammerjs';
import { SlickModule } from 'ngx-slick';
import { NgxIntlTelInputComponent } from './ngx-intl-tel-input/ngx-intl-tel-input.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { EqualValidator } from './equal-validator.directive';
import { ParallaxModule } from 'ngx-parallax';
import { ChilddetailsComponent } from './subscriptionSteps/childdetails/childdetails.component';
import { OffreComplementaireComponent } from './subscriptionSteps/offre-complementaire/offre-complementaire.component';
import { RefusePopupComponent } from './subscriptionSteps/refuse-popup/refuse-popup.component';
import { DeclinePopupComponent } from './subscriptionSteps/decline-popup/decline-popup.component';
import { PageVosInformationsComponent } from './subscriptionSteps/page-vos-informations/page-vos-informations.component';
import { PageConfirmationComponent } from './subscriptionSteps/page-confirmation/page-confirmation.component';
import { PageVotreOffreComponent } from './subscriptionSteps/page-votre-offre/page-votre-offre.component';
import { PagePriseContactComponent } from './subscriptionSteps/page-prise-contact/page-prise-contact.component';
import { PageVotreContratComponent } from './subscriptionSteps/page-votre-contrat/page-votre-contrat.component';
import { PageNotFoundComponent } from './sharedPage/page-not-found/page-not-found.component';
import { sharedDataService } from './sharedModels.service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { tokenVerfier } from './auth.guard';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { UpLoadPrinicipalOfferComponent } from './logOn/up-load-prinicipal-offer/up-load-prinicipal-offer.component';
import { PopupCRCComponent } from './logOn/popup-crc/popup-crc.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ForgotPwdComponent } from './logOn/forgot-pwd/forgot-pwd.component';
import { ModalClientExistComponent } from './logOn/modal-client-exist/modal-client-exist.component';
import { ModalDossierexistComponent } from './subscriptionSteps/modal-dossierexist/modal-dossierexist.component';
import { ModalSendMailPwdComponent } from './logOn/modal-send-mail-pwd/modal-send-mail-pwd.component';
import { ChangePwdComponent } from './logOn/change-pwd/change-pwd.component';
import { MatchValueDirective } from './directives/MatchValueDirective';
import { isTokenValid } from './resolver/isTokenValid.resolver';
import { PopupChangepassSecussComponent } from './logOn/popup-changepass-secuss/popup-changepass-secuss.component';
import { ModalProspectExistComponent } from './subscriptionSteps/modal-prospect-exist/modal-prospect-exist.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { CinControleDirective } from './directives/cinControle.directive';
import { PopupFatcaComponent } from './subscriptionSteps/popup-fatca/popup-fatca.component';
import { ModalModifyMailsComponent } from './subscriptionSteps/modal-modify-mails/modal-modify-mails.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Landing2Component } from './landing2/landing2.component';
import { GrdFilterPipe } from './pipe/filter.pipe';
import { ModalInvtComponent } from './landing2/modal-invt/modal-invt.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ChatPageComponent } from './sharedPage/chat-page/chat-page.component';
import { MreModalComponent } from './subscriptionSteps/mre-modal/mre-modal.component';
import { ModalNoMoroccanComponent } from './subscriptionSteps/modal-no-moroccan/modal-no-moroccan.component';
import { ModalHelpCenterComponent } from './subscriptionSteps/modal-help-center/modal-help-center.component';
import { environment } from 'src/environments/environment';
import { ModalAideSocialComponent } from "src/app/subscriptionSteps/modal-aidesocial/model-aidesocial.component";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsPosition: POSITION.centerCenter,
  bgsSize: 100,
  bgsType: SPINNER.rectangleBounceParty,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
  overlayColor: "rgba(40, 40, 40, 0.2)",
};

@NgModule({
  declarations: [
    PopupFatcaComponent,
    GrdFilterPipe,
    AppComponent,
    StepsMenuComponent,
    FooterComponent,
    ContentStepOneComponent,
    ModalStepOneComponent,
    LandingPageComponent,
    SubscriptionPageComponent,
    ContentStepTwoComponent,
    ContentStepThreeComponent,
    ContentStepFourComponent,
    ContentStepFiveComponent,
    AuthenticationPageComponent,
    FollowUpFilePageComponent,
    WizardComponent,
    ModalVideoComponent,
    NgxIntlTelInputComponent,
    EqualValidator,
    ChilddetailsComponent,
    OffreComplementaireComponent,
    RefusePopupComponent,
    DeclinePopupComponent,
    PageVosInformationsComponent,
    PageConfirmationComponent,
    PageVotreOffreComponent,
    PagePriseContactComponent,
    PageVotreContratComponent,
    PageNotFoundComponent,
    UpLoadPrinicipalOfferComponent,
    PopupCRCComponent,
    ForgotPwdComponent,
    ModalClientExistComponent,
    ModalDossierexistComponent,
    ModalSendMailPwdComponent,
    ModalHelpCenterComponent,
    MatchValueDirective,
    CinControleDirective,
    ChangePwdComponent,
    PopupChangepassSecussComponent,
    ModalProspectExistComponent,
    PopupFatcaComponent,
    ModalModifyMailsComponent,
    Landing2Component,
    ModalInvtComponent,
    ChatPageComponent,
    MreModalComponent,
    ModalNoMoroccanComponent,
    ModalAideSocialComponent],
  entryComponents: [ModalInvtComponent, ModalClientExistComponent, PopupFatcaComponent, ModalStepOneComponent, ModalVideoComponent, PopupChangepassSecussComponent, ModalSendMailPwdComponent, ModalDossierexistComponent, RefusePopupComponent, DeclinePopupComponent, UpLoadPrinicipalOfferComponent, ModalProspectExistComponent, PopupCRCComponent, ModalModifyMailsComponent, MreModalComponent, ModalNoMoroccanComponent, ModalHelpCenterComponent, ModalAideSocialComponent],
  imports: [CommonModule,
    FormsModule,
    MatAutocompleteModule,
    AngularSvgIconModule.forRoot(),
    AgmJsMarkerClustererModule,
    BrowserModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' }),
    NgbModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyDKtjIosZ41cUqygRKpa7nHXqycBqnuXok', region: 'ma' }),
    HttpClientModule,
    MaterialModule,
    SlickModule,
    BsDropdownModule.forRoot(),
    ParallaxModule,
    ServiceProxyModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    LoggerModule.forRoot({ serverLoggingUrl: '/logs', level: NgxLoggerLevel.LOG, serverLogLevel: NgxLoggerLevel.ERROR })],
  exports: [GrdFilterPipe],
  providers: [DeviceDetectorService, sharedDataService, NgbActiveModal, isTokenValid, GoogleMapsAPIWrapper, { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }, DatePipe,
    tokenVerfier, {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'fr', // 6LeDCBwUAAAAAMccGRlnvlmLTWCpcmDdcRm6p3d-     6LeursQUAAAAAGer1CBzB1qRmEekiIujx7_a6ljt   --------- use French language
    }, { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LeDCBwUAAAAAMccGRlnvlmLTWCpcmDdcRm6p3d-' } as RecaptchaSettings }, NgxImageCompressService
    ,{
      provide: 'API_BASE_URL', // Utilisez une chaîne comme clé pour éviter les problèmes d'injection
      useValue: environment.apiBaseUrl
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

