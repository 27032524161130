import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-changepass-secuss',
  templateUrl: './popup-changepass-secuss.component.html',
  styleUrls: ['./popup-changepass-secuss.component.scss']
})
export class PopupChangepassSecussComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }
  close() {
    this.modalService.dismissAll();
  }
}
