import {
  Component,
  Input,
  NgModule,
  ElementRef,
  OnInit,
  Renderer2,
  NgZone,
} from "@angular/core";
import { ViewChild, AfterViewInit } from "@angular/core";

import {
  NgbActiveModal,
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";

@Component({
  selector: "app-decline-popup",
  templateUrl: "./decline-popup.component.html",
  styleUrls: ["./decline-popup.component.scss"],
})
export class DeclinePopupComponent implements OnInit {
  @ViewChild("target", { static: true }) toggleButton: ElementRef;
  listenFunc: Function;
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private _eref: ElementRef,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private ngZone: NgZone
  ) {
    this.listenFunc = this.renderer.listen("window", "click", (e: Event) => {
      this.modalService.dismissAll();
      this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
    });
  }

  ngOnInit() {}
  Accepter() {
    this.modalService.dismissAll();
    this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
  }
  ngOnDestroy() {
    this.listenFunc();
  }
}
