import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import {
  Component,
  ElementRef,
  ViewChild,
  NgZone,
  OnInit,
} from "@angular/core";

import { GoogleMapsAPIWrapper } from "@agm/core";
import { AgmCoreModule } from "@agm/core";
import { NgModule,ChangeDetectorRef } from "@angular/core";

import { BrowserModule } from "@angular/platform-browser";
import { Router } from "@angular/router";
import * as progressbar from "progressbar.js";
import {
  AgencyMapServiceProxy,
  AgenceMapOutputDto,
  B3GResponseOfListOfAgenceMapOutputDto,
  GetAgencyMapInputModel,
  B3GResponseOfListOfCityOutputModel,
  CityOutputModel,
  DataFromBDServiceProxy,
} from "./../../service-proxies/service-proxies";

import {
  ProspectServiceProxy,
  TakeAppointmentDto,
} from "./../../service-proxies/service-proxies";
import { ProspectService } from "src/app/service-proxies/prospect.service";
import { MreModalComponent } from "../mre-modal/mre-modal.component";


@Component({
  selector: "app-content-stepfour",
  templateUrl: "./content-stepFour.component.html",
  styleUrls: ["./content-stepFour.component.scss"],
})
export class ContentStepFourComponent implements OnInit {
  showBlocAgenceDetails: boolean;
  selectedCity;
  selectedCityAdresseCin;
  selectedOtherCity;
  selectedAgency;
  selectedTimeSlot;
  listAgencies: AgenceMapOutputDto[] = [];
  listAgenciesBackup: AgenceMapOutputDto[] = [];
  public latitude: number;
  public longitude: number;
  public zoom: number;
  listCities: CityOutputModel[] = [];
  listCitiesBackup: CityOutputModel[] = [];
  adresse: string;
  adressee: string;
  villeValue: string;
  codePostale: string;
  tel: string;
  codeAgence: string;
  NameAgence: string;
  apointementTime: string;
  TimeSlot: string;
  prospectmodel: TakeAppointmentDto = new TakeAppointmentDto();
  checked = false;
  disab = true;
  allAgences;

  IsAdresseAgence = false;
  IsAdresseCin = false;
  IsOtherAdresse = false;
  cgValue: boolean = false;
  selectedOption: string = ''; 
  isCgValueRequired: boolean = false;
  disableCinAndOtherAddressRadioButtons = false;

  ngAfterViewChecked() {
    if (
      this.selectedAgency != "" &&
      this.selectedAgency != undefined &&
      this.selectedCity != "" &&
      this.selectedCity != undefined &&
      this.selectedTimeSlot != "" &&
      this.selectedTimeSlot != undefined
    ) {
      this.disab = false;
    } else {
      this.disab = true;
    }
    }
    
    /*if (this.selectedTimeSlot != "" &&
      this.selectedTimeSlot != undefined &&
      this.IsAdresseAgence && 
      this.selectedAgency != "" &&
      this.selectedAgency != undefined &&
      this.selectedCity != "" &&
      this.selectedCity != undefined 
     ) {
      this.disab = false;
     }
     else if (this.selectedTimeSlot != "" &&
     this.selectedTimeSlot != undefined &&
     this.IsAdresseCin &&  this.selectedCityAdresseCin != "" &&
     this.selectedCityAdresseCin != undefined  
     ) {
     this.disab = false;
     }
     else if (this.selectedTimeSlot != "" &&
     this.selectedTimeSlot != undefined &&
     this.IsOtherAdresse &&  this.selectedOtherCity != "" &&
     this.selectedOtherCity != undefined  &&  this.adressee != "" &&
     this.adressee != undefined 
     ) {
      this.disab = false;
     }else {
      this.disab = true;
     }*/

     /*if (this.selectedTimeSlot && this.selectedTimeSlot != undefined) {

       if (this.IsAdresseAgence &&  this.selectedAgency != "" &&
          this.selectedAgency != undefined &&
          this.selectedCity != "" &&
          this.selectedCity != undefined) {
            this.disab = false;
        }
        else if (this.cgValue &&
            this.IsAdresseCin &&  
            this.selectedCityAdresseCin != "" &&
            this.selectedCityAdresseCin != undefined
            ) {
              this.disab = false;
        }  
        else if(this.cgValue && 
              this.IsOtherAdresse &&  
              this.selectedOtherCity != "" &&
              this.selectedOtherCity != undefined &&  
              this.adressee != "" &&
              this.adressee != undefined
             ){
                this.disab = false;
        }
        else{
          this.disab = true;
        }
      }
      this.cdRef.detectChanges(); */

  /*   if (this.selectedTimeSlot && this.selectedTimeSlot != undefined) {
      if (this.selectedAgency != "" &&
          this.selectedAgency != undefined &&
          this.selectedCity != "" &&
          this.selectedCity != undefined) {
        this.disab = false;
        this.disableCinAndOtherAddressRadioButtons = true;
      } else if (this.IsAdresseCin &&  
                 this.selectedCityAdresseCin != "" &&
                 this.selectedCityAdresseCin != undefined && !this.cgValue) {
        this.disab = false;
        this.isCgValueRequired = true; // Mettre à jour la variable isCgValueRequired
        this.disableCinAndOtherAddressRadioButtons = false;
      } else if (this.IsOtherAdresse &&  
                 this.selectedOtherCity != "" &&
                 this.selectedOtherCity != undefined &&  
                 this.adressee != "" &&
                 this.adressee != undefined && !this.cgValue ) {
        this.disab = false;
        this.isCgValueRequired = true; // Mettre à jour la variable isCgValueRequired
        this.disableCinAndOtherAddressRadioButtons = false;
      } else {
        this.isCgValueRequired = false; // Mettre à jour la variable isCgValueRequired
        this.disableCinAndOtherAddressRadioButtons = false;
        this.disab = true;
      }
    } else {
      this.disab = true;
    }*/
    

  ag: AgenceMapOutputDto = new AgenceMapOutputDto();
  iconMarker = "../../../../assets/image/markerAgenceOuvert.png";
  load = false;
  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;
  listTime;
  constructor(
    private router: Router,
    private _agency: AgencyMapServiceProxy,
    private _prospect: ProspectServiceProxy,
    private prospe: ProspectService,
    private timeslotData: DataFromBDServiceProxy,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
  ) {
    if (this.prospe.prospect == undefined || this.prospe.prospect.step == 6) {
      this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
    }
    this.timeslotData.getListAppoitement("").subscribe((result) => {
      this.listTimeSlot = result.result;
      this.listTime = result.result[0].listAppoientement;
      this.selectedDay = result.result[0].jour;
      this.dayappoitement = result.result[0].ordrejour;
      sessionStorage.setItem("day", this.dayappoitement);
    });
  }

  gender: string;
  Firstname: string;
  Lastname: string;

  listTimeSlot;

  ngOnInit() {
    
    this._agency.getAll("").subscribe(
      (result: B3GResponseOfListOfAgenceMapOutputDto) => {
        this.allAgences = result.result.filter(
          (el) => el.agenceName != null && el.agenceName != "Agence Centrale"
        );
        this.setCurrentPosition();
      },
      (err) => {
        this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
      }
    );

    this.showBlocAgenceDetails = false;
    this.gender = this.prospe.prospect.gender;
    this.Firstname = this.prospe.prospect.firstName;
    this.Lastname = this.prospe.prospect.lastName;
    this.adresse = this.prospe.prospect.adresseCIN;

   // this.adressee =  this.prospectmodel.adresse;

    this.getAllCities();
    const bar = new progressbar.Path("#step-path", {
      easing: "easeInOut",
      duration: 5000,
    });
    bar.set(0.528);
    bar.animate(0.685);

    const picurlContact = document.getElementById(
      "idmgContact"
    ) as HTMLImageElement;
    picurlContact.src = "assets/image/contact.png";
    const spanClassContact = document.getElementById(
      "LibelleContact"
    ) as HTMLSpanElement;
    spanClassContact.classList.add("active");

    if (
      this.prospe.prospect.step == 5 ||
      (this.prospe.prospect.city != "" &&
        this.prospe.prospect.city != undefined)
    ) {
      this.selectedDay = this.prospe.prospect.rendezVousLibelle
        .split("/")[0]
        .trim();
      this.selectedTimeSlot = this.listTimeSlot.find(
        (x) =>
          x.label == this.prospe.prospect.rendezVousLibelle.split("/")[1].trim()
      ).id;
      this.selectedCity = this.prospe.prospect.city;
      this.getAgencies(this.selectedCity, false);
    }

    if (this.prospe.prospect.paysResidence != '144') {
      this.modalService.open(
        MreModalComponent,
        { backdrop: 'static', keyboard: false }
      );
    }
  }

  cgValuee($event) {
    this.cgValue = $event.target.checked;
   /* if (!$event.target.checked) {
      this.justificatifAddresseCourier = new Justificatif();
      this.infoIdentification.adrDiffCourrier = "";
    }*/
  }
  
  selectOption(option: string): void {
    this.selectedOption = option;
    
    if(this.selectedOption === "adresseAgence"){
      this.IsAdresseAgence = true;
      this.IsOtherAdresse = false;
      this.IsAdresseCin = false;
    }else if(this.selectedOption === "adresseCin"){
      this.IsAdresseCin = true;
      this.IsAdresseAgence = false;
      this.IsOtherAdresse = false;
    }else if(this.selectedOption === "autreAdresse"){
      this.IsOtherAdresse = true;
      this.IsAdresseCin = false;
      this.IsAdresseAgence = false;
    }
    console.log(option);
  }
  
 
  /* onGenderMme(event) {
    if (event.target.checked) {
      this.gender = "Mme";
      this.infoIdentification.gender = this.gender;
    }
  }
  onGenderMme(event) {
    if (event.target.checked) {
      this.gender = "Mme";
      this.infoIdentification.gender = this.gender;
    }
  }
  onGenderMme(event) {
    if (event.target.checked) {
      this.gender = "Mme";
      this.infoIdentification.gender = this.gender;
    }
  }
  */

  lat;
  long;
  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var pos = this.Nearestagence(
            position.coords.latitude,
            position.coords.longitude
          );
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
          this.latitude = pos.age.latitude;
          this.longitude = pos.age.longitude;
          this.selectedCity = pos.age.ville.toLowerCase();
          this.selectedAgency = pos.age.codeAgence;
          this.getAgencies(this.selectedCity, true);
          this.zoom = 16;
          this.adresse = pos.age.adresse;
          this.tel = pos.age.numeroFixe;
          this.villeValue = this.selectedCity;
          setTimeout(() => {
            this.showBlocAgenceDetails = true;
          }, 100);
        },
        (err) => {
          if (err.code == 1) {
            this.latitude = 33.58459;
            this.longitude = -7.62214;
            this.zoom = 4;
          }
        }
      );
    }
  }
  PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
    lat1 = this.Deg2Rad(lat1);
    lat2 = this.Deg2Rad(lat2);
    lon1 = this.Deg2Rad(lon1);
    lon2 = this.Deg2Rad(lon2);
    var R = 6371; // km
    var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    var y = lat2 - lat1;
    var d = Math.sqrt(x * x + y * y) * R;
    return d;
  }
  Deg2Rad(deg) {
    return (deg * Math.PI) / 180;
  }
  Nearestagence(latitude, longitude) {
    let minDif = 99999;
    let e: any;
    let dif;
    let agen: { age: AgenceMapOutputDto; dist: number };
    this.allAgences.forEach((el) => {
      dif = this.PythagorasEquirectangular(
        latitude,
        longitude,
        el.latitude,
        el.longitude
      );
      if (dif < minDif) {
        e = el;
        minDif = dif;
        agen = { age: e, dist: dif * 1000 };
      }
    });
    //sthis.allagencesclose.sort
    //this.allAgences.splice(this.allAgences.indexOf(e), 1)

    return agen;
  }
  NearestListagence(latitude, longitude) {
    let minDif = 99999;
    let e: any;
    let dif;
    let agen: { age: AgenceMapOutputDto; dist: number };
    this.listAgencies.forEach((el) => {
      dif = this.PythagorasEquirectangular(
        latitude,
        longitude,
        el.latitude,
        el.longitude
      );
      if (dif < minDif) {
        e = el;
        minDif = dif;
        agen = { age: e, dist: dif * 1000 };
      }
    });
    this.listAgencies.splice(this.listAgencies.indexOf(e), 1);
    return agen;
  }
  selectedDay = "";
  dayappoitement;
  clickDay(day, jour) {
    this.listTime = this.listTimeSlot.filter(
      (r) => r.jour == jour
    )[0].listAppoientement;
    this.selectedDay = jour;
    console.log(day, "dhdhfd");
    this.dayappoitement = day;
    sessionStorage.setItem("day", this.dayappoitement);
  }

  onSubmit() {

    if (this.selectedCity === undefined) {
      return;
    }
    if (this.selectedAgency === undefined) {
      return;
    }
    if (this.selectedTimeSlot === undefined) {
      return;
    }
    
    this.prospectmodel.agenceCode = this.selectedAgency;
    this.prospectmodel.appointmentDateTime =
    this.selectedDay + " / " + this.selectedTimeSlot;
    this.prospectmodel.city = this.selectedCity;
    this.prospectmodel.day = this.dayappoitement;
    this.prospectmodel.canal = "Web";
    this.prospectmodel.isImmidiat = this.selectedTimeSlot.isImmediat;
    

    // if(this.tkPnt.city === undefined){return;};
    // if(this.tkPnt.agenceCode === undefined){return;};
    // if(this.tkPnt.appointmentDateTime === undefined){return;};

   // if (this.selectedCity === undefined && this.selectedCityAdresseCin === undefined && this.selectedOtherCity === undefined && this.adressee === undefined && this.selectedAgency === undefined) {
   //   return;
   // }

    /* if (this.selectedAgency === undefined) {
      return;
    } */

    //if (this.selectedTimeSlot === undefined) {
    //  return;
    //}

    // alert(this.NameAgence + ' ' + this.TimeSlot + ' ' + this.apointementTime)
    //this._sharedProspect.objectProspect['_agenceName'] = this.NameAgence;
    //this._sharedProspect.objectProspect['_timeSlot'] = this.tkPnt.appointmentDateTime;
    // this._sharedProspect.objectProspect['_appointement'] = this.tkPnt.appointmentDateTime;
    // this.prospectmodel.id = this._sharedProspect.objectProspect['_id'];
  
    /* if(this.selectedAgency != "" && this.selectedAgency != undefined){
      this.prospectmodel.agenceCode = this.selectedAgency;
      this.IsAdresseAgence = true;
    }

    this.prospectmodel.appointmentDateTime =
      this.selectedDay + " / " + this.selectedTimeSlot;
    this.prospectmodel.day = this.dayappoitement;
    this.prospectmodel.canal = "Web";
   
    if(this.IsAdresseAgence){
      this.prospectmodel.isAdresseAgence = true;
      this.prospectmodel.isAdresseCin = false;
      this.prospectmodel.isOtherAdresse = false;
      this.prospectmodel.city = this.selectedCity;
      this.prospectmodel.acceptConditionsHorsAgence = false;
    }
    if(this.IsAdresseCin){
      this.prospectmodel.isAdresseCin = true;
      this.prospectmodel.isAdresseAgence= false;
      this.prospectmodel.isOtherAdresse = false;
      this.prospectmodel.city = this.selectedCityAdresseCin;
      this.prospectmodel.acceptConditionsHorsAgence = this.cgValue;
    }
    if(this.IsOtherAdresse){
      this.prospectmodel.isOtherAdresse = true;
      this.prospectmodel.isAdresseAgence= false;
      this.prospectmodel.isAdresseCin = false;
      this.prospectmodel.city = this.selectedOtherCity;   
      this.prospectmodel.adresse = this.adressee;
      this.prospectmodel.acceptConditionsHorsAgence = this.cgValue;
    }
    */

    // let prosp: any = this._sharedProspect.objectProspect
    // prosp._timeSlot = this.prospectmodel.appointmentDateTime
    // this._sharedProspect.objectProspect = prosp
    this._prospect
      .takeAppointement(this.prospectmodel, localStorage.getItem("sessionId"))
      .subscribe(
        (result) => {
          if (result.statusCode === "000") {
            this.ngZone
              .run(() => this.router.navigate(["/VotreContrat"]))
              .then();
            window.scrollTo(0, 0);
          } else {
            alert("merci de réesayer plutard");
          }
        },
        (err) => {
          this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
        }
      );
  }

  getAllCities() {
    this._agency.getAvailableCities("").subscribe(
      (result: B3GResponseOfListOfCityOutputModel) => {
        if (result.statusCode === "000") {
          this.listCities = result.result;
          this.listCities = this.listCities.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          this.listCities = (
            [{ name: "casablanca" }, { name: "rabat" }] as CityOutputModel[]
          ).concat(this.listCities);
          this.listCitiesBackup = this.listCities;
        } else {
          alert("Merci de réessayer plutard");
        }
      },
      (err) => {
        //  this.router.navigate(['/NotPageFound'])
      }
    );
  }
  listAgenceSortByPosition = [];
  label = "Agence";
  getAgencies(city, geolocation) {
    this.listAgencies = [];
    this._agency
      .getAgenciesByCity(new GetAgencyMapInputModel({ city: city }), "")
      .subscribe(
        (result: B3GResponseOfListOfAgenceMapOutputDto) => {
          this.listAgencies = result.result.filter(
            (el) => el.agenceName != null && el.agenceName != "Agence Centrale"
          );
          this.listAgenciesBackup = this.listAgencies;
          if (geolocation) {
            this.listAgencies.forEach((e) => {
              this.listAgenceSortByPosition.push(
                this.NearestListagence(this.lat, this.long).age
              );
            });
            this.listAgencies = this.listAgenceSortByPosition;
            this.label = "Agences les plus proches";
          }
          if (
            this.prospe.prospect.step == 5 ||
            (this.prospe.prospect.city != "" &&
              this.prospe.prospect.city != undefined)
          ) {
            this.selectedAgency = this.prospe.prospect.agenceCode;
            this.label = "Agence";
          }
        },
        (err) => {
          this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
        }
      );
  }
  getTimeSlot(val) {
    this.TimeSlot = this.listTimeSlot
      .filter((item) => item.id === val)
      .shift().label;
  }

  getZone(agence) {
    this.ag = this.listAgencies
      .filter((item) => item.codeAgence === agence)
      .shift();
    this.codeAgence = this.ag.codeAgence;
    this.NameAgence = this.ag.agenceName;
    this.latitude = Number(this.ag.latitude);
    this.longitude = Number(this.ag.longitude);
    this.zoom = 19;
    this.adresse = this.ag.adresse;
    this.tel = this.ag.numeroFixe;
    this.villeValue = this.ag.ville.toString();
    setTimeout(() => {
      this.showBlocAgenceDetails = true;
    }, 100);
    // this.codePostale= '*code postal*';

    //  console.log(this.codeAgence + ' ' + this.ag.codeAgence);
    //  console.log(this.NameAgence + ' ' + this.ag.agenceName);
    //  console.log(this.adresse +' ' + this.ag.adresse);
    //  console.log(this.villeValue + ' ' + this.ag.ville);
    //  console.log( this.tel + ' ' + this.ag.numeroFixe);
  }
  previous_info_window = null;
  infoWindowOpened = null;
  selectMarker(infoWindow) {
    if (this.previous_info_window == null)
      this.previous_info_window = infoWindow;
    else {
      this.infoWindowOpened = infoWindow;
      this.previous_info_window.close();
    }
    this.previous_info_window = infoWindow;
  }
  mapClicked($event) {
    this.showBlocAgenceDetails = false;
  }
  zoomChange($event) {
    this.showBlocAgenceDetails = false;
  }
  centerChange($event) {
    this.showBlocAgenceDetails = false;
  }
  goToPosition(index, infoWindow) {
    infoWindow.close();

    this.listAgencies = [];
    this._agency
      .getAgenciesByCity(new GetAgencyMapInputModel({ city: index.ville }), "")
      .subscribe(
        (result: B3GResponseOfListOfAgenceMapOutputDto) => {
          this.listAgencies = result.result.filter(
            (el) => el.agenceName != null && el.agenceName != "Agence Centrale"
          );
          this.selectedAgency = index.codeAgence;
        },
        (err) => {
          this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
        }
      );
    this.selectedCity = index.ville.toLowerCase();
    this.codeAgence = index.codeAgence;
    this.NameAgence = index.agenceName;
    this.latitude = Number(index.latitude);
    this.longitude = Number(index.longitude);
    //this.zoom = 12;
    this.adresse = index.adresse;
    this.tel = index.numeroFixe;
    this.villeValue = index.ville.toString();
    setTimeout(() => {
      this.showBlocAgenceDetails = true;
    }, 0.1);
  }
  back() {
    this.ngZone.run(() => this.router.navigate(["/VotreOffre"])).then();
  }
  onKey(value, list, type) {
    if (value != "") {
      switch (type) {
        case "Ville":
          this.listCities = list.filter(
            (option) =>
              option.name != undefined &&
              option.name.toLowerCase().startsWith(value.toLowerCase())
          );
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case "Ville":
          this.listCities = this.listCitiesBackup;
          break;
        default:
          break;
      }
    }
  }
}
