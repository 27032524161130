import {
  ProspectInfoDto,
  CountryServiceProxy,
  ProfessionServiceProxy,
} from "./../../service-proxies/service-proxies";
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  sharedDataService,
  PrincipalOffer,
} from "src/app/sharedModels.service";

import { Status } from "src/app/Globals.enum";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UpLoadPrinicipalOfferComponent } from "../up-load-prinicipal-offer/up-load-prinicipal-offer.component";
import { PopupCRCComponent } from "../popup-crc/popup-crc.component";
import {
  ProspectServiceProxy,
  B3GResponseOfProspectInfoDto,
} from "src/app/service-proxies/service-proxies";
import { ProspectModel } from "src/assets/models/ProspectModel";
import * as moment from "moment";

@Component({
  selector: "app-FollowUpFile-page",
  templateUrl: "./followUpFile-page.component.html",
  styleUrls: ["./followUpFile-page.component.scss"],
})
export class FollowUpFilePageComponent implements OnInit {
  @ViewChild("ancreTarget", { static: false }) ancreTarget: ElementRef;
  statusCard;
  keysStatusCardChild;
  keysStatusCardParent;
  FolderProgress;
  prospectModel = new ProspectModel();
  principalOffer = new PrincipalOffer();
  token: string;
  agencyInfo: any = {};
  showBlocFilesProspect: boolean = true;
  showBlocFilesChild: boolean = true;
  idChild;
  listMediaFile;
  listCountry;
  listSocioProfessionnel;
  listSituationPro;
  listProfession;
  callBack = false;
  prospect: ProspectInfoDto;

  folderProgress: IFolderProgress = {};

  constructor(
    private router: Router,
    private _prospect: sharedDataService,
    private modalService: NgbModal,
    private _countryProxy: CountryServiceProxy,
    private _sitProf: ProfessionServiceProxy,
    private ngZone: NgZone,
    private prospectService: ProspectServiceProxy //      private _mediaFile:MediaFileServiceProxy,
  ) {
    this.token = localStorage.getItem("sessionId");
    this.checkOffersCompleted();
  }

  ngOnInit() {
    this._countryProxy.getAll("").subscribe((e) => {
      this.listCountry = e.result;
    });
    this.prospectService.getCollSocio("").subscribe((e) => {
      this.listSocioProfessionnel = e.result;
    });
    this._sitProf.getAll("").subscribe((e) => {
      this.listSituationPro = e.result;
    });
  }

  returnToPageLogin() {
    this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
  }

  scrollToDetails() {
    this.ancreTarget.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  ContinueInOfferPage() {
    if (
      this.prospect.step == 1 ||
      this.prospect.step == 7 ||
      this.prospect.step == 2 ||
      this.prospect.step == 3
    )
      this.ngZone.run(() => this.router.navigate(["/VotreOffre"])).then();
    else if (this.prospect.step == 4 || this.prospect.step == 5)
      this.ngZone.run(() => this.router.navigate(["/PriseDeContact"])).then();
  }

  updateProspectFilePopUp(
    isChild: Boolean,
    idFile,
    nom: string,
    prenom: string
  ) {
    const modalRef = this.modalService.open(UpLoadPrinicipalOfferComponent);
    modalRef.componentInstance.listMediaFile = this.prospect.prospectMedia;
    modalRef.componentInstance.prospectSystem = this.prospect.isNewSystem;

    if (isChild == false) {
      modalRef.componentInstance.nom = this.prospectModel.firstName;
      modalRef.componentInstance.prenom = this.prospectModel.lastName;
      modalRef.componentInstance.idFileRecto = this.prospectModel.fileRectoId;
      modalRef.componentInstance.idFileVerso = this.prospectModel.fileVersoId;
      modalRef.componentInstance.idFileEngagement =
        this.prospectModel.fileEngagement;
      modalRef.componentInstance.isFonctionaire =
        this.prospectModel.isFonctionaire;
      modalRef.componentInstance.isChild = isChild;
    } else {
      modalRef.componentInstance.nom = nom;
      modalRef.componentInstance.prenom = prenom;
      modalRef.componentInstance.idFileChild = idFile;
      modalRef.componentInstance.isChild = isChild;
    }
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry) {
        this.checkOffersCompleted();
      }
    });

    if (!isChild) {
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.prospectModel.isFileConforme = receivedEntry;
        this.showBlocFilesProspect = !receivedEntry;
      });
    } else {
      this.prospectModel.childrens.forEach((ch) => {
        if ((ch.fileId = idFile)) {
          modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            ch.isFileConforme = receivedEntry;
            this.showBlocFilesChild = !receivedEntry;
            this.idChild = ch.id;
          });
        }
      });
    }
  }

  showContact() {
    if (this.callBack) {
      this.prospectService.callBack(this.token).subscribe((response) => {
        if (response.statusCode == "000") {
          const modalRef = this.modalService.open(PopupCRCComponent);
          modalRef.componentInstance.callMeBack = true;
          this.callBack = false;
        }
      });
    } else if (
      this.folderProgress.hasAnsweredPhoneCall == false &&
      this.prospect.folderProgress == "09"
    ) {
      const modalRef = this.modalService.open(PopupCRCComponent);
      modalRef.componentInstance.activateAccount = true;
    }
  }

  checkOffersCompleted() {
    if (
      this.principalOffer.offerId === null ||
      this.principalOffer.offerId === undefined
    ) {
      this.principalOffer.offerId = "";
    }

    this.prospectService
      .getMediaFileProspect(localStorage.getItem("sessionId"))
      .subscribe((e) => {
        this.listMediaFile = e.result;
      });

    this.prospectService
      .getProspectInfo(localStorage.getItem("sessionId"))
      .subscribe(
        (result: B3GResponseOfProspectInfoDto) => {
          switch (result.statusCode) {
            case Status.OK:
              this.prospect = result.result;
              this.setFolderProgress();
              if (
                this.prospect.isNewSystem &&
                this.prospect.step > 2 &&
                this.prospect.socioProfessional != undefined
              ) {
                this.prospectService
                  .getProfession(this.prospect.socioProfessional, "")
                  .subscribe((e) => {
                    this.listProfession = e.result;
                  });
              }
              this.principalOffer.nom = result.result.firstName;
              this.principalOffer.gender = result.result.gender;
              this.principalOffer.prenom = result.result.lastName;
              this.prospectModel.id = Number(result.result.idClient);
              this.principalOffer.tel = result.result.phoneNumber;
              this.prospectModel.isFonctionaire = result.result.fonctionnaire;
              //       this.principalOffer.mail= result.result.;
              if (
                result.result.prospectOffers != undefined &&
                result.result.prospectOffers.length != 0
              ) {
                this.principalOffer.offerId =
                  result.result.prospectOffers[0].offerId;
                this.principalOffer.cardImage =
                  result.result.prospectOffers[0].offerPicture;
                this.principalOffer.productLabel =
                  result.result.prospectOffers[0].offerLabel;
              }
              this.principalOffer.dateNaissance =
                result.result.dateBirth.toString();
              this.principalOffer.tel = result.result.phoneNumber;

              this.prospectModel.isCompletOffer = true; // get the state of prospectOffer from dataBase
              this.agencyInfo.agencyName = result.result.agencyName;
              this.agencyInfo.agencyPhone = result.result.agencyPhone;
              this.agencyInfo.agencyAdress = result.result.agencyAdress;
              this.agencyInfo.agencyTime = result.result.agencyTime;
              this.prospectModel.firstName = result.result.firstName;
              if (result.result.gender)
                this.prospectModel.gender = result.result.gender.trim();
              this.prospectModel.lastName = result.result.lastName;
              this.prospectModel.mobile = result.result.phoneNumber;
              this.prospectModel.mail = result.result.email;
              this.FolderProgress = result.result.folderProgress;
              if (
                result.result.prospectOffer != undefined &&
                result.result.prospectOffer
              ) {
                this.prospectModel.offerId = Number(
                  result.result.prospectOffer.offerId
                );
                this.prospectModel.offerImage =
                  result.result.prospectOffer.offerPicture;
                this.prospectModel.offerLabel =
                  result.result.prospectOffer.offerLabel;
              }
              if (result.result.prospectProduct != undefined) {
                this.prospectModel.productId = Number(
                  result.result.prospectProduct.id
                );
                this.prospectModel.productImage =
                  result.result.prospectProduct.picture;
                this.prospectModel.productLabel =
                  result.result.prospectProduct.libelle;
              }
              this.prospectModel.dateBirth = moment(
                result.result.dateBirth,
                "DD-MM-YYYY"
              ).format("DD-MM-YYYY");
              this.prospectModel.nbChild = result.result.nbChild;
              this._prospect.objectProspect = this.prospectModel;

              break;

            default:
          }
        },
        (error) => {
          console.log(this.principalOffer);
        }
      );
  }

  downloadFile(file) {
    // const linkSource = 'data:' +  + ';base64,' + file.fileContent;
    console.log(file);
    var linkSource = "";
    if (file.mimeType == "application/doc") {
      linkSource = "data:application/msword;base64," + file.fileContent;
    } else {
      linkSource = "data:" + file.mimeType + ";base64," + file.fileContent;
    }
    const downloadLink = document.createElement("a");
    const fileName = file.name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  private setFolderProgress(): void {
    if (this.prospect.folderProgress == "11") {
      this.folderProgress.isFolderCompleted = false;
      this.folderProgress.areAttachedDocumentsCompliant = false;
      this.folderProgress.hasAnsweredPhoneCall = false;
      this.folderProgress.cardAvailable = false;
      this.folderProgress.accountedCreated = false;
    } else if (this.prospect.folderProgress == "09") {
      this.folderProgress.isFolderCompleted = true;
      this.folderProgress.areAttachedDocumentsCompliant = true;
      this.folderProgress.hasAnsweredPhoneCall =
        this.prospect.hasAnsweredPhoneCall;
    } else if (this.prospect.folderProgress == "19") {
      this.folderProgress.isFolderCompleted = true;
      this.folderProgress.areAttachedDocumentsCompliant = true;
      this.folderProgress.hasAnsweredPhoneCall = true;
    } else if (this.prospect.folderProgress == "05") {
      this.folderProgress.isFolderCompleted = true;
      this.folderProgress.areAttachedDocumentsCompliant = true;
      this.folderProgress.hasAnsweredPhoneCall =
        this.prospect.hasAnsweredPhoneCall;
      this.folderProgress.accountedCreated = true;
    } else if (this.prospect.folderProgress == "13") {
      this.folderProgress.isFolderCompleted = true;
      this.folderProgress.areAttachedDocumentsCompliant = true;
      this.folderProgress.hasAnsweredPhoneCall = false;
      this.folderProgress.cardAvailable = false;
      this.folderProgress.accountedCreated = false;
      this.callBack = true;
    } else if (
      this.prospect.folderProgress == "14" ||
      this.prospect.folderProgress == "07"
    ) {
      this.folderProgress.isFolderCompleted = true;
      this.folderProgress.areAttachedDocumentsCompliant = true;
    } else if (this.prospect.folderProgress == "10") {
      this.folderProgress.isFolderCompleted = true;
      this.folderProgress.areAttachedDocumentsCompliant = false;
    } else if (
      this.prospect.folderProgress == "06" ||
      (this.prospect.step == 6 && this.prospect.acceptConditions == true)
    ) {
      this.folderProgress.isFolderCompleted = true;
    } else {
      this.folderProgress.isFolderCompleted = false;
    }
  }

  getProgressStatus(status: boolean | null): string {
    if (status == null) {
      return "./../../assets/image/svg/circles-loader.svg";
    } else if (status == true) {
      return "./../../assets/image/svg/checked.svg";
    } else {
      return "./../../assets/image/svg/pasTraite.svg";
    }
  }

  getContactButtonTitle() {
    return this.prospect.folderProgress == "13" &&
      this.prospect.paysResidence != "144"
      ? "Me rappeler"
      : "Activer le compte";
  }

  activateAccount(): void {
    const modalRef = this.modalService.open(PopupCRCComponent);
    modalRef.componentInstance.contactAgency = true;
    modalRef.componentInstance.agencyInfo = this.agencyInfo;
  }
}

interface IFolderProgress {
  isFolderCompleted?: boolean;
  areAttachedDocumentsCompliant?: boolean;
  hasAnsweredPhoneCall?: boolean;
  accountedCreated?: boolean;
  cardAvailable?: boolean;
}
