import {
  Component,
} from "@angular/core";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "V2 EER";
  showScript = false;
  public cihPathLogo = "assets/image/logoFooter2.png";
  public nortonPathLogo = "assets/image/norton_secure_seal.png";
  //@ViewChild('lhc_status_container') el:ElementRef;
  constructor() {}

  onActivate(event) {
    window.scroll(0, 0);
  }
}
