import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps-menu',
  templateUrl: './steps-menu.component.html',
  styleUrls: ['./steps-menu.component.scss']
})
export class StepsMenuComponent implements OnInit {
 public urlImgSteps = 'assets/image/bkg-steps.png';
 picurl = 'assets/image/confirmOff.png';
  constructor() { }

  ngOnInit() {// var bar = new ProgressBar.Path('#step-path', {
    //   easing: 'easeInOut',
    //   duration: 5000
    //  });
   }

}
