import { Component, OnInit, EventEmitter, Output, NgZone } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// import {  ProspectServiceProxy, B3GResponseOfCreateStep1ViewModel } from './../../service-proxies/service-proxies';
import {
  ProspectServiceProxy,
  B3GResponseOfProspectInfoDto,
} from "./../../service-proxies/service-proxies";

import { Subscriber } from "./../../../assets/models/Client";

@Component({
  selector: "app-page-confirmation",
  templateUrl: "./page-confirmation.component.html",
  styleUrls: ["./page-confirmation.component.scss"],
})
export class PageConfirmationComponent implements OnInit {
  @Output() client = new Subscriber();

  identif: string;
  showCop = false;
  private sub: any;
  constructor(
    private route: ActivatedRoute,
    private _prospect: ProspectServiceProxy,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.sendtoken();
  }

  ngOnInit() {}
  renovyer() {
    this.sendtoken();
  }
  sendtoken() {
    this.sub = this.route.params.subscribe((params) => {
      this.identif = params["identifiant"];
      localStorage.setItem("token", params["identifiant"]);
      this._prospect.confirmEmail(this.identif, "").subscribe(
        (result: B3GResponseOfProspectInfoDto) => {
          if (result.statusCode !== "000") {
            this.ngZone
              .run(() => this.router.navigate(["/NotPageFound"]))
              .then();
          } else {
            this.showCop = true;
            this.client.FirstName = result.result.firstName;
            this.client.LastName = result.result.lastName;
            this.client.Gender = result.result.gender;
            this.client.idClient = result.result.idClient;
            this.client.phoneNumber = result.result.phoneNumber;
          }
        },
        (err) => {
          this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
        }
      );
    });
  }
}
