import { AuthServiceProxy } from "./../../service-proxies/service-proxies";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
} from "@angular/core";
import { Router } from "@angular/router";
import { sharedDataService } from "src/app/sharedModels.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal-dossierexist",
  templateUrl: "./modal-dossierexist.component.html",
  styleUrls: ["./modal-dossierexist.component.scss"],
})
export class ModalDossierexistComponent implements OnInit {
  @Input() fonctionnaire;
  @Input() showConfirm;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router,
    private _prospect: sharedDataService,
    private _prospectLogin: AuthServiceProxy,
    private modalService: NgbModal,
    private ngZone: NgZone
  ) {}

  ngOnInit() {}

  OpenLogOnPage() {
    this.modalService.dismissAll();
    this.ngZone.run(() => this.router.navigate(["/Login"])).then();
  }
  nonConfirmer() {
    this.modalService.dismissAll();
    //this.passEntry.emit(false);
  }
  confirmer() {
    this.modalService.dismissAll();
    this.passEntry.emit(true);
  }
}
