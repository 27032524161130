import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
} from "@angular/core";
import {
  ProspectServiceProxy,
  ProspectProductServiceProxy,
  ProspectInfoDto,
  ProspectProductDto,
  ChangeEmail,
  OtpInput,
  TokenInput,
} from "../../service-proxies/service-proxies";
import { ProspectService } from "src/app/service-proxies/prospect.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { interval, Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-modal-modify-mails",
  templateUrl: "./modal-modify-mails.component.html",
  styleUrls: ["./modal-modify-mails.component.scss"],
})
export class ModalModifyMailsComponent implements OnInit {
  emailValue: string;
  showOtpForm = false;
  editMail = false;
  prospect: ProspectInfoDto;
  @Input() newEmail;
  arryOfNumber;
  otp = "";
  messageErreur;
  disableResendOTP;
  timout = 60000;
  intervall = 60;
  @Input() token;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private _prospectService: ProspectServiceProxy,
    private prospe: ProspectService,
    private modalService: NgbModal,
    private ngZone: NgZone
  ) {
    this.arryOfNumber = this.rundomPermutation();
  }

  ngOnInit() {}

  rundomPermutation() {
    let arry: Array<any> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    for (let idx = 0; idx < arry.length; idx++) {
      const swpIdx = idx + Math.floor(Math.random() * (arry.length - idx));
      // now swap elements at idx and swpIdx
      const tmp = arry[idx];
      arry[idx] = arry[swpIdx];
      arry[swpIdx] = tmp;
    }
    return this.PermutationAvoidLast(arry);
  }

  PermutationAvoidLast(arry) {
    const tmp = arry[arry.length - 2];
    arry[arry.length - 2] = arry[arry.length - 1];
    arry[arry.length - 1] = tmp;
    return arry;
  }

  keyOfOtp(number) {
    this.otp += number.toString();
  }

  modifyMail() {
    this.editMail = true;
    // (document.getElementById('neEmail') as HTMLLabelElement).classList.add('active');
  }

  getProspect() {
    this.prospect = this.prospe.prospect;
    this.newEmail = this.prospect.email;
  }

  closeEditEmail() {
    this.editMail = false;
  }
  messageErreurChangeMail = "";
  chengeEmail() {
    this._prospectService
      .changeMail(
        new ChangeEmail({ prospectToken: this.token, mail: this.newEmail }),
        ""
      )
      .subscribe((e) => {
        if (e.statusCode == "000") {
          this.editMail = false;
        } else if (e.statusCode == "88989") {
          this.messageErreurChangeMail = "l'email existe deja";
        }
      });
  }

  confirmEmail() {
    this._prospectService
      .checkEmailOtp(new OtpInput({ otp: this.otp, token: this.token }), "")
      .subscribe((e) => {
        if (e.statusCode == "000") {
          this.passEntry.emit("sucess");
          this.modalService.dismissAll();
        } else if (e.statusCode == "12890") {
          this.messageErreur = "Code confidentiel incorrect";
        } else if (e.statusCode == "12889") {
          this.messageErreur =
            "Vous avez dépassé 5 minutes, merci de cliquer sur renvoyer le code et essayer de nouveau";
        } else {
          this.messageErreur =
            "Une erreur est survenue, veuillez réessayer plus tard";
        }
      });
  }
  subscription: Subscription;
  verifierEmail() {
    this._prospectService
      .mobileConfirmEmail(
        new TokenInput({ prospectToken: this.token, email: this.newEmail }),
        ""
      )
      .subscribe((e) => {
        if (e.statusCode == "000") {
          this.showOtpForm = true;
          this.disableResendOTP = true;
          setTimeout(() => {
            this.disableResendOTP = false;
            this.intervall = 60;
            this.subscription.unsubscribe();
          }, this.timout);
          this.subscription = interval(1000).subscribe(() => {
            this.intervall--;
            return;
          });
        }
      });
  }
  clean() {
    this.otp = "";
  }
  cancel() {
    this.showOtpForm = false;
    this.timout = 60000;
    this.intervall = 60;
    this.subscription.unsubscribe();
  }
  nbrRestant;
  retou = false;
  RenvoyerSMS() {
    this._prospectService
      .resendMail(
        new TokenInput({ prospectToken: this.token, email: this.newEmail }),
        ""
      )
      .subscribe((e) => {
        if (e.statusCode == "000") {
          this.disableResendOTP = true;
          setTimeout(() => {
            this.disableResendOTP = false;
            this.intervall = 60;
            this.subscription.unsubscribe();
          }, this.timout);
          this.subscription = interval(1000).subscribe(() => {
            this.intervall--;
            return;
          });
          this.nbrRestant = e.result.split("/")[1];
        } else if (e.statusCode == "12889") {
          this.retou = true;
        }
      });
  }
  RetourntoLandingPage() {
    this.modalService.dismissAll();
    this.ngZone.run(() => this.router.navigate(["/LandingPage"])).then();
  }
}
