export enum Status {
  OK = "000",
  KO = "555",
  NOTFOUND="444",

}

export enum StatusSuiviDossier {
  Done = "01",
  OnGoing = "02",
  Error="03"
}


export enum OffersValue {
  Code30 = 1,
  Code18 = 2,
  Digitalis  = 4,
  Sayidati = 3,
  CompteSurCarnet = 5
}
