


export class Subscriber {
  idClient: string;
  isCustomer: boolean;
  Gender: string;
  Radical: string;
  FirstName: string;
  phoneNumber : string;
  codeCountry : string;
  LastName: string;
  BirthDay: string;
  NbChild: number;
  Mail: string;
  Gsm: string;
  OperatorLibelle: string;
  OperatorId: number;
  CG: boolean;
  Profession: string;
  CIN: string;
  SituationProfessionel: string;
  PaysResidance: string;
  Card: string;
  OfferId: string;
  OfferLabel: string;
  OfferPicture: string;
  AgenceName: string;
  TimeSlot: string;
  Appointement: string;
  BasicOffre: string;
  ComplementaryOffer: string;
  IsFonctionnaire;
  PaysNaissance;
  situationFamilial;
  Adresse;
  dateValidite;
  lieuNaissance;
  fatca;
  phoneNumberWithoutAreaCode: string;
  areaCode: string;
  nationalite : string;
}

export class Identification {
 gender? : string;
 identification? : string;
 adresseCIN? : string;
 dateValidite? : Date;
 lieuNaissance? : string;
 dateBirth? : Date;
 nomMere? : string;
 nomPere? : string;
 adrDiffCourrier? : string;
 adrDiffResidence? : string;
}

export class CollecteInformation {
situationFamilial? : string;
nationalite? : string = 'Marocaine';
paysResidence? : string = '144';
paysNaissance? : string = 'Maroc';
fonctionnaire?: boolean;
motif? : string;
autreMotif? : string;
souscriptionOffre? : string
fatca? : boolean;
situationProffessionnelle? : string;
profession? : string;
secteurActivite? : string;
origineDeFonds?: string
revenuMensuel?: string
autreCompte?: boolean
socioProfessional?: string
nomBanque?: string
motivationOuvCompte?: string
}
export class Login {
  eMail: string;
  passWord: string;
  ConfpassWord: string;
}
export class child {
  idChild: string;
  firstName: string;
  lastName: string;
  BirthDate: string;
  cardLibelle: string;
  cardID: string;
  cardPath: string;
  showMe: boolean;
  gender:string;
  idRecap: string;
  ActiveButtonValue: boolean;

  // file
  fileName:string;
  fileSize:string;
  fileContent:string;
  fileType:string;

}
export class recapChild {
  _idValue: string
  _child: child;
  _show: boolean;
}

export class boxChild {
  idBloc: string;
  valueBloc: string;
  divChildId: string;
  divChildShow: boolean;
  divRecap: string;
  divRecapShow: boolean;
  rowChild: child;
}
