import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal-prospect-exist",
  templateUrl: "./modal-prospect-exist.component.html",
  styleUrls: ["./modal-prospect-exist.component.scss"],
})
export class ModalProspectExistComponent implements OnInit {
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private ngZone: NgZone
  ) {}

  ngOnInit() {}
  OpenLogOnPage() {
    this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
  }
  connecte() {
    this.modalService.dismissAll();
    this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
  }
}
