import { Component, OnInit, NgZone,ChangeDetectorRef } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { WizardComponent } from "../wizard/wizard.component";
import { Subscriber, Login } from "./../../../assets/models/Client";
import * as progressbar from "progressbar.js";
import {
  AuthServiceProxy,
  LoginInput,
  ProspectFirstStep,
  B3GResponseOfListOfOperatorViewModel,
  MobileConfirmGSMInput,
  CountryServiceProxy,
  CountryDto,
  OperatorViewModel,
  OperatorServiceProxy,
  ProspectServiceProxy,
} from "./../../service-proxies/service-proxies";
import { sharedDataService } from "src/app/sharedModels.service";
import { Status } from "src/app/Globals.enum";
import { ModalProspectExistComponent } from "../modal-prospect-exist/modal-prospect-exist.component";
import { HttpClient } from "@angular/common/http";
import { map, mergeMap as _observableMergeMap, shareReplay } from "rxjs/operators";
import { BehaviorSubject, interval, combineLatest, Subscription, EMPTY } from "rxjs";
import { ModalModifyMailsComponent } from "../modal-modify-mails/modal-modify-mails.component";
import { DeviceDetectorService } from "ngx-device-detector";
import { ModalNoMoroccanComponent } from "../modal-no-moroccan/modal-no-moroccan.component";
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';

declare var gtag_report_conversion;

@Component({
  selector: "app-content-stepone",
  templateUrl: "./content-stepOne.component.html",
  styleUrls: ["./content-stepOne.component.scss"],
  providers: [
    [{ provide: WizardComponent, useExisting: ContentStepOneComponent }],
  ],
})
export class ContentStepOneComponent implements OnInit {
  
  declarativeFormCaptchaValue;
  public client: Subscriber = new Subscriber();
  public log: Login = new Login();
  public statusLabel: string = "";
  cgValue: boolean = false;
  mismatch: boolean = true;
  phone_number_standard = "";

  preferredCountries = ["ma", "fr", "us", "it", "be", "nl", "es"];
  prospectToken;
  disableResendOTP;
  timout = 60000;
  emailIsexiste = false;
  PhoneIsexiste = false;
  selectedOperator: OperatorViewModel;
  isNotValidPhoneDebut = false;
  isNotValidPhone = false;
  isProspectAddingError: boolean;
  isOperatorsExist: boolean;
  gsm;
  otp = false;
  model: any = {};
  InputStat = false;
  TypeInput = "password";
  _listOperator: OperatorViewModel[] = [];
  valueBtn = [];
  varNb: number;
  pwd;

  selectedResidenceCountry = 'ma';

  toCollecte = true;
  isActivationCaptcha = environment.ActivationCaptcha;

  disablebuttonCollecteInfo: boolean = true;

  private filteredCountriesSubject = new BehaviorSubject<string>("");
  filterCountriesAction$ = this.filteredCountriesSubject.asObservable();

  private filteredNationalitesSubject = new BehaviorSubject<string>("");
  filteredNationalitesAction$ = this.filteredNationalitesSubject.asObservable();
  
  countriesFromAPI$ = this._countryProxy.getAll("")
  .pipe(
    map(({ result: countries }) =>
      countries.sort((countryLh, countryRh) =>
        countryLh.libelleFr.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
        countryRh.libelleFr.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      )
    ),
    shareReplay(1)
  );

  countries$ = combineLatest([
    this.countriesFromAPI$,
    this.filterCountriesAction$,
  ]).pipe(
    map(([countries, filterKeyword]) =>
      countries.filter((countrie) =>
        filterKeyword
          ? countrie.libelleFr.toLowerCase().startsWith(filterKeyword.toLowerCase())
          : true
      )
    )
  );

  nationalites$ = combineLatest([
    this.countriesFromAPI$,
    this.filteredNationalitesAction$,
  ]).pipe(
    map(([countries, filterKeyword]) =>
      countries.filter((countrie) =>
        filterKeyword
          ? countrie.libelleFr.toLowerCase().startsWith(filterKeyword.toLowerCase())
          : true
      )
    )
  );

  
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private _operator: OperatorServiceProxy,
    private _prospect: ProspectServiceProxy,
    private flag: sharedDataService,
    private http: HttpClient,
    private _prospectLogin: AuthServiceProxy,
    private ngZone: NgZone,
    private _countryProxy: CountryServiceProxy,
    private deviceService: DeviceDetectorService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.client.codeCountry = 'ma'
  }

  compareOperatorId(leftOperator: any, rightOperator: any) {
    return rightOperator && leftOperator && leftOperator.operatorId == rightOperator.operatorId;
  }

  selectedCountry(event, country: CountryDto) {
    if(event.isUserInput) {
      this.selectedResidenceCountry = country.code;
      if (country.id != '144' || this.client.codeCountry != 'ma') {
        this.selectedOperator = this._listOperator.find(x => x.operatorId == '4');
      }
    }
  }

  disableSelect() {
    console.log(this.client.codeCountry);
    
    return this.client.PaysResidance != '144' || this.client.codeCountry != 'ma';
  }

  onKey(event: any): void {
    this.filteredCountriesSubject.next(event.target.value);
  }

  onKeyNationalites(event: any): void {
    this.filteredNationalitesSubject.next(event.target.value);
  }

  phoneNumberChanged($event) {
    const inputClass = document.getElementById(
      "loginValueLabel"
    ) as HTMLLabelElement;
    if (this.client.Gsm !== "") {
      inputClass.classList.add("active");
    } else {
      inputClass.classList.remove("active");
    }
    this.isNotValidPhone = false;
    this.client.Gsm = $event.areaCode + $event.number;
    this.client.areaCode = $event.areaCode;
    this.client.phoneNumberWithoutAreaCode = $event.number;
    this.isNotValidPhoneDebut = this.client.Gsm[$event.code] == "0";
  }

  ngOnInit() {
    const bar = new progressbar.Path("#step-path", {
      easing: "easeInOut",
      duration: 5000,
    });
    bar.set(0.1625);
    bar.animate(0.214);

    this.loadOperators();
    const picurl = document.getElementById("idmgInfo") as HTMLImageElement;
    picurl.src = "assets/image/info.png";
    const spanClassConfirm = document.getElementById(
      "LibelleInfo"
    ) as HTMLSpanElement;
    spanClassConfirm.classList.add("active");

    this.isProspectAddingError = false;
    this.isOperatorsExist = true;
    this.client.codeCountry == 'ma';
    this.client.PaysResidance = '144';
    this.client.nationalite = '144';
  }
  
  isMatching(event) {
    this.pwd = event.target.value;
    if (this.log.passWord === this.pwd) {
      this.mismatch = false;
    } else {
      this.mismatch = true;
    }
  }

  isMatching1(event) {
    if (this.log.passWord === this.pwd) {
      this.mismatch = false;
    } else {
      this.mismatch = true;
    }
  }

  isOperatorDisabled(operator) {
    return operator.operatorId == 4 && (this.client.codeCountry == 'ma' || this.client.PaysResidance == '144');
  }

  onSubmit() {
    this.client.Mail = this.model.email;
    this.flag.objectProspect = { mail: this.model.email };
    let pPhone: string;
    pPhone = this.client.Gsm;
    if (pPhone.length < 9) {
      this.isNotValidPhone = true;
      return;
    }
    if (this.flag.countryFlag === "ma") {
      if (pPhone.length == 9) {
        if (pPhone.charAt(0) !== "6" && pPhone.charAt(0) !== "7") {
          this.isNotValidPhone = true;
          return;
        } else {
          this.isNotValidPhone = false;
        }
      } else if (pPhone.length === 10) {
        if (pPhone.charAt(1) !== "6" && pPhone.charAt(1) !== "7") {
          this.isNotValidPhone = true;
          return;
        } else {
          this.isNotValidPhone = false;
        }
      } else if (pPhone.length === 12) {
        if (pPhone.charAt(0) + pPhone.charAt(1) + pPhone.charAt(2) === "212") {
          if (pPhone.charAt(3) !== "6" && pPhone.charAt(3) !== "7") {
            this.isNotValidPhone = true;
            return;
          } else {
            this.isNotValidPhone = false;
          }
        } else {
          this.isNotValidPhone = true;
          return;
        }
      } else if (pPhone.length == 13) {
        if (
          pPhone.charAt(0) +
            pPhone.charAt(1) +
            pPhone.charAt(2) +
            pPhone.charAt(3) ===
          "2120"
        ) {
          if (pPhone.charAt(4) !== "6" && pPhone.charAt(4) !== "7") {
            this.isNotValidPhone = true;
            return;
          } else {
            this.isNotValidPhone = false;
          }
        } else {
          this.isNotValidPhone = true;
        }
      } else {
        this.isNotValidPhone = true;
        return;
      }
    }

    this.log.eMail = this.client.Gsm;

    if (this.cgValue === true) {
      const prospect = new ProspectFirstStep();
      prospect.firstName = this.client.FirstName;
      prospect.lastName = this.client.LastName;
      prospect.mail = this.client.Mail;
      prospect.mobile = this.client.Gsm;
      prospect.nbChild = 0;
      prospect.countryCode = this.client.codeCountry.toUpperCase();
      prospect.operatorId = Number(this.selectedOperator.operatorId);
      prospect.password = this.log.passWord;
      prospect.confirmPassowrd = this.log.passWord;
      prospect.canal = "Web";
      prospect.paysResidence = Number(this.client.PaysResidance);
      prospect.areaCode = this.client.areaCode;
      prospect.phoneNumberWithoutAreaCode = this.client.phoneNumberWithoutAreaCode;
      prospect.nationalite = this.client.nationalite;
      this.emailIsexiste = false;
      this.PhoneIsexiste = false;
      
      this._prospect.mobileAddProspect(prospect, "").subscribe(
        (result) => {
          switch (result.statusCode) {
            case "000":
              const bar = new progressbar.Path("#step-path", {
                easing: "easeInOut",
                duration: 5000,
              });
              bar.set(0.214);
              bar.animate(0.365);
              const picurl = document.getElementById(
                "idmg"
              ) as HTMLImageElement;
              picurl.src = "assets/image/confirm.png";
              const spanClassConfirm = document.getElementById(
                "LibelleConfirm"
              ) as HTMLSpanElement;
              spanClassConfirm.classList.add("active");
              const list: Number[] = new Array(10);
              this.varNb = 0;
              while (this.varNb < 10) {
                const n = Math.floor(Math.random() * 10);
                if (list.indexOf(n) === -1) {
                  list[this.varNb] = n;
                  this.varNb += 1;
                }
              }
              for (let i = 0; i < 10; i++) {
                this.valueBtn[i] = list[i];
              }
              this.prospectToken = result.result;
              let ngbModalOptions: NgbModalOptions = {
                backdrop: "static",
              };
              const modalRef = this.modalService.open(
                ModalModifyMailsComponent,
                ngbModalOptions
              );
              modalRef.componentInstance.token = this.prospectToken;
              modalRef.componentInstance.newEmail = this.client.Mail;
              modalRef.componentInstance.passEntry.subscribe(
                (receivedEntry) => {
                  if (receivedEntry == "sucess") {
                    this.otp = true;
                    this.disableResendOTP = true;
                    setTimeout(() => {
                      this.disableResendOTP = false;
                      this.intervall = 60;
                      this.subscription.unsubscribe();
                    }, this.timout);
                    this.subscription = interval(1000).subscribe(() => {
                      this.intervall--;
                      return;
                    });
                  }
                }
              );

              this.http
                .get(
                  "http://tbl.tradedoubler.com/report?organization=2242210&event=406760&leadNumber=auto"
                )
                .subscribe(
                  (data) => {},
                  (error) => {}
                );
              // const modalRef = this.modalService.open(ModalStepOneComponent);
              // modalRef.result.then((result) => {
              // }, (reason) => {
              //   this.router.navigate(['/LandingPage']);
              // });
              break;
            case "30001801":
              //     this.modalService.open(ModalDossierexistComponent)
              this.modalService.open(ModalProspectExistComponent);
              /* this.emailIsexiste = true */
              break;
            case "30001802":
              //  this.modalService.open(ModalDossierexistComponent)
              this.modalService.open(ModalProspectExistComponent);
              break;
            case "30021401":
              //  this.modalService.open(ModalDossierexistComponent)
              this.modalService.open(ModalProspectExistComponent);
              break;
            case "30021402":
              //  this.modalService.open(ModalDossierexistComponent)
              this.modalService.open(ModalProspectExistComponent);
              break;
            case "9989":
              //  this.modalService.open(ModalDossierexistComponent)
              this.modalService.open(ModalProspectExistComponent);
              /*  this.PhoneIsexiste = true */
              break;
            case "400":
                this.modalService.open(ModalNoMoroccanComponent);
              break;
            default:
              this.statusLabel = result.label;
              //      this.isProspectAddingError=true;

              break;
            // default :
            //        this.router.navigate(['/NotPageFound']);
            //     break;
          }
        },
        (err) => {
          this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
        }
      );
    }
  }

  errorEmail = false;
  
/*  ngAfterViewChecked() {
    if (this.toCollecte) {
      
      if(this.client.FirstName !=undefined && this.client.FirstName !='' && 
         this.client.LastName !=undefined  && this.client.LastName !='' &&  
         this.model.email !=undefined  && this.model.email !='' &&  
         this.gsm != undefined &&  this.gsm != '' &&
         this.selectedOperator!=undefined && this.selectedOperator.operatorId!=''
         && this.log.passWord != undefined && this.log.passWord != '' && 
         this.log.ConfpassWord != undefined && this.log.ConfpassWord != ''
         && this.cgValue){
          if(this.errorEmail || this.declarativeFormCaptchaValue != undefined || this.isCaptchaRequired != false ||
             this.mismatch || this.isNotValidPhoneDebut || this.isNotValidPhone )
            this.disablebuttonCollecteInfo = false;           
      } else{    
             this.disablebuttonCollecteInfo = true; 
      }
    } 
    this.cdRef.detectChanges();
  } */

     // this.model.motdepasse.match(/(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 !@#$&()\\-`.+,/\]]{6,}/) === null
     // this.client.FirstName.match(/[a-zA-ZÀ-ȕ .'_-]+/) === null &&
     // this.client.LastName.match(/[a-zA-ZÀ-ȕ .'_-]+/) === null &&
     /*
     this.errorEmail &&  !this.model.email  &&
     this.isNotValidPhoneDebut && this.isNotValidPhone && this.mismatch && this.cgValue &&
     */
       
     /*if(!this.declarativeFormCaptchaValue ||
         this.isCaptchaRequired  ||
         this.model.email != "" ||  this.model.email != undefined ||
         !this.model.email.errors ||
         this.client.FirstName !=undefined || this.client.FirstName !=''  ||
         this.client.LastName !=undefined  || this.client.LastName !='' ||
         this.client.Gsm != undefined || this.client.Gsm != '' ||
         this.mismatch != undefined  ||  this.selectedOperator != undefined ||
         this.selectedOperator.operatorId != '' ||
          this.cgValue != undefined
         ){
          this.disablebuttonCollecteInfo = false;
         }
        else {
          this.disablebuttonCollecteInfo = true;
         } */
    

  
 
  codeCountryChange($event) {
    this.client.codeCountry = $event; 
    if ($event != 'ma') {
      this.selectedOperator = this._listOperator.find(x => x.operatorId == '4');
    }
  }

  loadOperators() {
    this._operator.getListOperator("").subscribe(
      (result: B3GResponseOfListOfOperatorViewModel) => {
        switch (result.statusCode) {
          case Status.OK:
            this._listOperator = result.result;
            break;
          default:
            this.isOperatorsExist = false;
            break;
        }
      }
    );
  }

  clickNumber(value: string) {
    for (let i = 1; i < 7; i++) {
      const input = document.getElementById("text-" + i) as HTMLInputElement;
      if (input.value === "" || input.value === null) {
        input.value = value;
        this.changeInputValue(value, i);
        return;
      } else {
        input.value = input.value;
        this.changeInputValue(input.value, i);
      }
    }
  }

  clearInput() {
    this.isOptpValid = true;
    const emptyvalue = "";
    for (let j = 6; j > 0; j--) {
      const input = document.getElementById("text-" + j) as HTMLInputElement;
      const valInput = input.value;
      if (input.value.toString() !== "") {
        input.value = emptyvalue;
        this.changeInputValue(emptyvalue, j);
        return false;
      } else {
        input.value = valInput;
        this.changeInputValue(valInput, j);
      }
    }
  }

  clearAll() {
    this.isOptpValid = true;
    for (let j = 1; j < 7; j++) {
      const input = document.getElementById("text-" + j) as HTMLInputElement;
      this.changeInputValue("", j);
    }
  }

  resolved($event) {
    console.log($event);
  }
  
  
  modelChangeFn($event) {
    if (this.model.email.split("@")[1] != undefined) {
      if (!this.model.email.split("@")[1].includes(".")) {
        this.errorEmail = true;
      } else {
        this.errorEmail = false;
      }
    }
  }
  valone;
  valtwo;
  valtree;
  valfour;
  valfive;
  valsix;
  changeInputValue(value: string, i: number) {
    const input = document.getElementById("text-" + i) as HTMLInputElement;
    input.value = value;
    switch (i) {
      case 1: {
        this.valone = value;
        break;
      }
      case 2: {
        this.valtwo = value;
        break;
      }
      case 3: {
        this.valtree = value;
        break;
      }
      case 4: {
        this.valfour = value;
        break;
      }
      case 5: {
        this.valfive = value;
        break;
      }
      case 6: {
        this.valsix = value;
        break;
      }
      default: {
        // console.log('Invalid choice');
        break;
      }
    }

    if (!Number(value) && value !== "0") {
      input.classList.add("isRequired");
      input.classList.remove("isValid");
      return;
    } else input.classList.remove("isRequired");
    input.classList.add("isValid");
    return;
  }

  changeStyles() {
    let lst = [1, 2, 3, 4, 5, 6];
    lst.forEach((el) => {
      const input = document.getElementById("text-" + el) as HTMLInputElement;
      if (
        input.value === "" ||
        input.value === undefined ||
        input.value === null
      ) {
        input.classList.add("isRequired");
        input.classList.remove("isValid");
      } else {
        input.classList.remove("isRequired");
        input.classList.add("isValid");
      }
    });
  }
  IsHidden() {
    if (!this.InputStat) {
      this.TypeInput = "text";
      this.InputStat = true;
    } else {
      this.TypeInput = "password";
      this.InputStat = false;
    }
  }
  loading: boolean = false;
  isOptpValid: boolean = true;
  messageError;
  ConfirmSMS() {
    //this.loading = true;
    this.isOptpValid = true;
    // this.ngxLoader.start();

    this.changeStyles();
    if (this.valone === "" || this.valone === undefined) {
      return;
    }
    if (this.valtwo === "" || this.valtwo === undefined) {
      return;
    }
    if (this.valtree === "" || this.valtree === undefined) {
      return;
    }
    if (this.valfour === "" || this.valfour === undefined) {
      return;
    }
    if (this.valfive === "" || this.valfive === undefined) {
      return;
    }
    if (this.valsix === "" || this.valsix === undefined) {
      return;
    }
    const val =
      this.valone +
      this.valtwo +
      this.valtree +
      this.valfour +
      this.valfive +
      this.valsix;
    let ConfirmGSMInput = new MobileConfirmGSMInput();
    ConfirmGSMInput.otp = val;
    ConfirmGSMInput.numberPhone = this.client.Gsm;
    ConfirmGSMInput.prospectToken = this.prospectToken;
    ConfirmGSMInput.password = this.log.passWord;
    ConfirmGSMInput.canal = "Web";

    this._prospect.mobileConfirmGSM(ConfirmGSMInput, "").subscribe(
      (result) => {
        localStorage.setItem("sessionId", "");
        switch (result.statusCode) {
          case "000":
            var model = new LoginInput();
            model.clientId = "eerweb";
            model.clientSecret = "secret";
            model.userName = this.client.Gsm;
            model.password = this.log.passWord;
            model.canal = "Web";
            model.deviceInfo = JSON.stringify(this.deviceService.getDeviceInfo());
            this._prospectLogin.login(model, "").subscribe((e) => {
              localStorage.setItem("sessionId", e.result.sessionId);
              this.ngZone
                .run(() => this.router.navigate(["/VotreOffre"]))
                .then();
            });
            break;
          case "12890":
            this.messageError = "Code confidentiel incorrect";
            this.isOptpValid = false;
            break;
          case "12889":
            this.messageError =
              "Vous avez dépassé 5 minutes, merci de cliquer sur renvoyer le code et essayer de nouveau";
            this.isOptpValid = false;
            break;
          default:
            this.messageError =
              "Une erreur est survenue, veuillez réessayer plus tard";
            this.isOptpValid = false;
            break;
        }
      },
      (err) => {
        this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
      }
    );
  }
  nbrRestant;
  intervall = 60;
  subscription: Subscription;
  retour = false;
  RenvoyerSMS() {
    this._prospect.resendOtp(this.prospectToken, "").subscribe(
      (result) => {
        switch (result.statusCode) {
          case "000":
            this.disableResendOTP = true;
            setTimeout(() => {
              this.disableResendOTP = false;
              this.intervall = 60;
              this.subscription.unsubscribe();
            }, this.timout);
            this.subscription = interval(1000).subscribe(() => {
              this.intervall--;
              return;
            });
            this.nbrRestant = result.result.split("/")[1];
            break;
          case "12889":
            this.retour = true;
            break;
          default:
            break;
        }
      },
      (err) => {
        this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
      }
    );
  }
  retourer() {
    this.ngZone.run(() => this.router.navigate(["/LandingPage"])).then();
  }
}
