import {
  AuthServiceProxy,
  LoginInput,
  B3GResponseOfLoginOutput,
  IdentityServiceProxy,
  CheckMailProspectInput,
  CheckPhoneNumberInput,
  ChangePasswordInput,
  ConfirmChangePasswordInput,
} from "./../../service-proxies/service-proxies";
import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  sharedDataService,
  SuiviDossier,
  PrincipalOffer,
} from "src/app/sharedModels.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalSendMailPwdComponent } from "../modal-send-mail-pwd/modal-send-mail-pwd.component";
import { PopupChangepassSecussComponent } from "../popup-changepass-secuss/popup-changepass-secuss.component";

@Component({
  selector: "app-forgot-pwd",
  templateUrl: "./forgot-pwd.component.html",
  styleUrls: ["./forgot-pwd.component.scss"],
})
export class ForgotPwdComponent implements OnInit {
  userName;
  messageError;
  preferredCountries = ["ma", "fr", "us", "it", "be", "nl", "es"];
  phone_number_standard = "";
  phoneNumber = "";
  showForm = true;
  otp = "";
  arryOfNumber;
  mismatch;
  mismatch1;
  model = new ChangePasswordInput();
  constructor(
    private router: Router,
    private _prospect: sharedDataService,
    private _prospectLogin: AuthServiceProxy,
    private modalService: NgbModal,
    private identityServiceProxy: IdentityServiceProxy,
    private ngZone: NgZone
  ) {
    this.arryOfNumber = this.rundomPermutation();
  }

  ngOnInit() {
    localStorage.removeItem("email");
  }

  BackHome() {
    this.ngZone.run(() => this.router.navigate(["/LandingPage"])).then();
  }

  OpenLogOnPage() {
    this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
  }

  token;
  pwdIncorre = false;
  onSubmit() {
    this.pwdIncorre = false;
    this.identityServiceProxy
      .checkNumberPhoneSendPhonenumber(
        new CheckPhoneNumberInput({ email: this.userName }),
        ""
      )
      .subscribe((value) => {
        if (value.statusCode == "000") {
          this.messageError = "";
          this.token = value.result;
          this.arryOfNumber = this.rundomPermutation();
          this.showForm = false;
        } else if (value.statusCode == "10000") {
          this.messageError = "Ce e-mail n'existe pas";
          this.pwdIncorre = true;
        } else {
          this.messageError =
            "une erreur est survenu, veuillez ressayer plutard";
          this.pwdIncorre = true;
        }
      });
  }
  RenvoyerSMS() {
    this.identityServiceProxy
      .checkNumberPhoneSendPhonenumber(
        new CheckPhoneNumberInput({ email: this.userName }),
        ""
      )
      .subscribe((value) => {
        if (value.statusCode == "000") {
        }
      });
  }
  TypeInput = "password";
  InputStat: boolean = false;
  showError = false;
  messageError1;
  onSubmitConfirmation() {
    if (!this.mismatch) {
      this.identityServiceProxy
        .confirmchangepassword(
          new ConfirmChangePasswordInput({
            prospectToken: this.token,
            email: this.userName,
            otp: this.otp,
            password: this.model.password,
            confirmpassword: this.model.cofirmPassword,
          }),
          ""
        )
        .subscribe((value) => {
          if (value.statusCode == "000") {
            this.modalService.open(PopupChangepassSecussComponent);
            this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
          } else if (value.statusCode == "12889") {
            this.showError = true;
            this.messageError1 =
              "Vous avez dépassé 5 minutes, merci de cliquer sur renvoyer le code et essayer de nouveau";
          } else if (value.statusCode == "12890") {
            this.showError = true;
            this.messageError1 = "Code confidentiel incorrect";
          } else if (value.statusCode == "10303") {
            this.showError = true;
            this.messageError1 =
              "Le mot de passe doit contenir au moins 6 caractères alphanumériques ";
          } else {
            this.messageError1 = "Code confidentiel incorrect";
            this.showError = true;
          }
        });
    }
  }
  envoyer() {}
  getNumberPone($event) {
    this.userName = $event.number;
  }

  rundomPermutation() {
    let arry: Array<any> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    for (let idx = 0; idx < arry.length; idx++) {
      const swpIdx = idx + Math.floor(Math.random() * (arry.length - idx));
      // now swap elements at idx and swpIdx
      const tmp = arry[idx];
      arry[idx] = arry[swpIdx];
      arry[swpIdx] = tmp;
    }
    return this.PermutationAvoidLast(arry);
  }
  PermutationAvoidLast(arry) {
    const tmp = arry[arry.length - 2];
    arry[arry.length - 2] = arry[arry.length - 1];
    arry[arry.length - 1] = tmp;
    return arry;
  }
  keyOfOtp(number) {
    this.otp += number.toString();
  }
  clean() {
    this.otp = "";
  }
  isMatching(event) {
    let pwd = event.target.value;
    if (this.model.password === this.model.cofirmPassword) {
      this.mismatch = false;
    } else {
      this.mismatch = true;
    }
  }
  isMatching1(event) {
    if (this.model.password === this.model.cofirmPassword) {
      this.mismatch = false;
    } else {
      this.mismatch = true;
    }
  }
  IsHidden() {
    if (!this.InputStat) {
      this.TypeInput = "text";
      this.InputStat = true;
    } else {
      this.TypeInput = "password";
      this.InputStat = false;
    }
  }
}
