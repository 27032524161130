import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-send-mail-pwd',
  templateUrl: './modal-send-mail-pwd.component.html',
  styleUrls: ['./modal-send-mail-pwd.component.scss']
})
export class ModalSendMailPwdComponent implements OnInit {
  @Input() Email: string;
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }
  close() {
    this.modalService.dismissAll();
  }
}
