import { Component, OnInit, Input } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-page-votre-offre',
  templateUrl: './page-votre-offre.component.html',
  styleUrls: ['./page-votre-offre.component.scss']
})
export class PageVotreOffreComponent implements OnInit {

  @Input() testoffre;
  constructor() { }

  ngOnInit() {
  }

}
