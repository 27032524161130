import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { IdentityServiceProxy, CheckTokenInput } from './service-proxies/service-proxies';
import { switchMap } from 'rxjs/operators';

// import { IdentityClient, AccountClient } from '../services/ApiHandler';

@Injectable()
export class tokenVerfier implements CanActivate {

    // add the service we need
    constructor(private router: Router, private route: ActivatedRoute, private identityServiceProxy: IdentityServiceProxy) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        if (next.params['token1'] && next.params['token2']) {

            return true;
        }

        this.router.navigate(['LogIn'])
        return false

    }

}
