import {
  AuthServiceProxy,
  LoginInput,
  B3GResponseOfLoginOutput,
  ProspectServiceProxy,
  B3GResponseOfProspectInfoDto,
} from "./../../service-proxies/service-proxies";
import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  sharedDataService,
  SuiviDossier,
  PrincipalOffer,
} from "src/app/sharedModels.service";
import { Login } from "src/assets/models/Client";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { StatusSuiviDossier, Status } from "src/app/Globals.enum";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalProspectExistComponent } from "src/app/subscriptionSteps/modal-prospect-exist/modal-prospect-exist.component";
import { ModalClientExistComponent } from "../modal-client-exist/modal-client-exist.component";
import { PopupFatcaComponent } from "src/app/subscriptionSteps/popup-fatca/popup-fatca.component";
import { ModalDossierexistComponent } from "src/app/subscriptionSteps/modal-dossierexist/modal-dossierexist.component";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-authentication-page",
  templateUrl: "./authentication-page.component.html",
  styleUrls: ["./authentication-page.component.scss"],
})
export class AuthenticationPageComponent implements OnInit {
  InputStat: boolean = false;
  TypeInput = "password";
  model = new LoginInput();
  preferredCountries = ["ma", "fr", "us", "it", "be", "nl", "es"];
  phone_number_standard = "";
  serName = "";
  //  _loginValue:string;
  //  _pwdValue:string;

  constructor(
    private router: Router,
    private _prospect: sharedDataService,
    private ngZone: NgZone,
    private _prospectLogin: AuthServiceProxy,
    private _client: ProspectServiceProxy,
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {}

  // OpenFollowUpFile() {

  //   this.router.navigate(['/FolowUpFile']);
  //  }
  pwdIncorre = false;
  compteVerroui = false;
  onSubmit() {
    // this.ngxService.start();
    this.model.clientId = "eerweb";
    this.model.clientSecret = "secret";
    this.model.deviceInfo = JSON.stringify(this.deviceService.getDeviceInfo());
    this.model.canal = "Web";
    this._prospectLogin
      .login(this.model, "")
      .subscribe((result: B3GResponseOfLoginOutput) => {
        localStorage.setItem("sessionId", "");
        switch (result.statusCode) {
          case "000":
            //this.router.navigate(['/FolowUpFile']);
            localStorage.setItem("sessionId", result.result.sessionId);
            this._client
              .getProspectInfo(localStorage.getItem("sessionId"))
              .subscribe((result: B3GResponseOfProspectInfoDto) => {
                switch (result.statusCode) {
                  case Status.OK:
                    switch (result.result.folderProgress) {
                      case "00":
                        this.ngZone
                          .run(() => this.router.navigate(["/LogIn"]))
                          .then();
                        break;
                      case "12":
                        const modalRef1 = this.modalService.open(
                          ModalClientExistComponent
                        );
                        modalRef1.componentInstance.showClient = true;
                        break;
                      case "11":
                        const modalRef2 = this.modalService.open(
                          ModalClientExistComponent
                        );
                        modalRef2.componentInstance.showRefuse = true;
                        break;
                      default:
                        this.ngZone
                          .run(() => this.router.navigate(["/FolowUpFile"]))
                          .then();
                        break;
                    }
                    break;
                  case "10300":
                    const modalRef2 =
                      this.modalService.open(PopupFatcaComponent);
                    break;
                }
              });
            break;
          case "30020702":
            this.compteVerroui = false;
            this.pwdIncorre = true;
            break;
          case "30020703":
            this.pwdIncorre = false;
            this.compteVerroui = true;
            //  const modalRef2 = this.modalService.open(ModalDossierexistComponent)
            //  modalRef2.componentInstance.passEntry.subscribe((receivedEntry) => {
            //   this.forgotpwd()
            // })
            break;

          default:
            break;
        }
      });
  }
  BackHome() {
    this.ngZone.run(() => this.router.navigate(["/LandingPage"])).then();
  }
  forgotpwd() {
    this.ngZone.run(() => this.router.navigate(["/ForgotPwd"])).then();
  }
  IsHidden() {
    if (!this.InputStat) {
      this.TypeInput = "text";
      this.InputStat = true;
    } else {
      this.TypeInput = "password";
      this.InputStat = false;
    }
  }
  getNumberPone($event) {
    this.model.userName = $event.areaCode + $event.number;
  }
}
