import { Component, OnInit } from '@angular/core';
import { ModalHelpCenterComponent } from '../modal-help-center/modal-help-center.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  help() {
    this.modalService.open(ModalHelpCenterComponent);
  }

}
