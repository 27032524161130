import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[cinControle]'
})
export class CinControleDirective {
    // Allow decimal numbers. The \. is only allowed once to occur
    private regex: RegExp = new RegExp(/^[A-Z]{1}[0-9]{3,7}$/g);
    private regex2: RegExp = new RegExp(/^[A-Z]{2}[0-9]{2,7}$/g);
    // alhabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
    // @Input('cin') set cin(value) {
    //     if (value != undefined) {
    //         this.val = value
    //     }
    // };
    // val
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        let current: string = this.el.nativeElement.value;

        //let next: string = current[this.el.nativeElement.selectionStart].concat(event.key);
        let next: string = current.slice(0, this.el.nativeElement.selectionStart) + event.key + current.slice(this.el.nativeElement.selectionStart)
        if ((next && !String(next).match(this.regex)) || (next && !String(next).match(this.regex2))) {
            event.preventDefault();
        }
    }
}