import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import { child, Subscriber } from './../../../assets/models/Client';

@Component({
  selector: 'app-offre-complementaire',
  templateUrl: './offre-complementaire.component.html',
  styleUrls: ['./offre-complementaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OffreComplementaireComponent implements OnInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
  if(this.idEnfant === this.myChild.idChild) {
  this.name=this.myChild.firstName + ' ' + this.myChild.lastName;
  this.birthdt = this.myChild.BirthDate;
  this.card=this.myChild.cardLibelle;
  this.pathImg=this.myChild.cardPath;}
  }

  private dataInternal: number;
  set data(data: number) {
    // you might do something special in here
    this.dataInternal = data;
  }
  get data() {
    // you might do something special in here
    return this.dataInternal;
  }

  classOffre: string;
  idName: string;
  childNameValue: string;

  @Input() myChild: child;

 // public showme: boolean = true;
  idEnfant;
  name;
  birthdt;
  card;
  pathImg;
  constructor() {
  }

  ngOnInit() {

    // this.idRecap = 'idRecap-'+ this.myI;
    this.idEnfant=this.myChild.idChild;
    this.name=this.myChild.firstName + ' ' + this.myChild.lastName;
    this.birthdt = this.myChild.BirthDate;
    this.card=this.myChild.cardLibelle;
    this.pathImg=this.myChild.cardPath;
  //  this.myChild.idRecap=this.idRecap;
   // console.log('show value in offre complementaire ' + this.showComponent);

  }


}
5
