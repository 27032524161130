import { Injectable } from '@angular/core';
import { ProspectInfoDto } from './service-proxies';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {
prospect : ProspectInfoDto
  constructor() { }
}
