import { Component, OnInit, Input  } from '@angular/core';
import { Subscriber } from '../../../assets/models/Client';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})

export class WizardComponent implements OnInit {

  @Input() pageStep: String;
  @Input() identifiant: string;
  confirmation: string;
  currentClient: Subscriber = new Subscriber();
  idIdentif: string;

  constructor() {
   this.confirmation = 'Confirmation-1234';
   this.idIdentif = '1234';
  }

  ngOnInit() {
    this.getClient(this.idIdentif);
  }
  getClient(id: string): Subscriber {
  // if(true)
      this.currentClient.FirstName= 'testF';
      this.currentClient.LastName = 'testL'
      this.currentClient.Gender ='Mlle';
      this.currentClient.Gsm='0632328577';
      return this.currentClient;
  }

}
