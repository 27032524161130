export class ProspectModel {
id?: number ;
gender?: string ;
firstName?: string ;
lastName?: string ;
mail?: string ;
mobile?: string;
dateBirth?: string;
nbChild?: number ;
operatorLabel?: string;
offerId?: number;
offerImage?: string;
offerLabel?: string;
productId?: number;
productImage?: string;
productLabel?: string;
fileRectoId?: number;
fileVersoId?: number;
fileEngagement?: number;
isCompletOffer?: boolean;
isFileConforme?: boolean;
isFonctionaire? : boolean;
status?: string;
getCall?: boolean;
getRIB?: boolean;
getCard?: boolean;

childrens?: ProspectChild[] = [];
 }

 export class ProspectChild {
    id?: number;
    gender?: string;
    firstName?: string;
    lastName?: string;
    mail?: string;
    mobile?: string;
    dateBirth?: string;
    nbChild?: number;
    operatorLabel?: string;
    offerId?: number;
    offerImage?: string;
    offerLabel?: string;
    productId?: number;
    productImage?: string;
    productLabel?: string;
    fileId?: number;
    isCompletOffer?: boolean;
    status?: string;
    isFileConforme?: boolean;

}
//{ }
