import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.scss']
})

export class ChatPageComponent implements OnInit {

  constructor() { 
  }

  ngOnInit() { 
  }
  ngAfterViewInit() {
   console.log(document.getElementById("lhc_status_container"));
    
  }

}
