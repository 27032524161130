import { Component, NgZone, OnInit } from "@angular/core";
import {
  IdentityServiceProxy,
  ChangePasswordInput,
  CheckTokenInput,
} from "../../service-proxies/service-proxies";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopupChangepassSecussComponent } from "../popup-changepass-secuss/popup-changepass-secuss.component";

@Component({
  selector: "app-change-pwd",
  templateUrl: "./change-pwd.component.html",
  styleUrls: ["./change-pwd.component.scss"],
})
export class ChangePwdComponent implements OnInit {
  model = new ChangePasswordInput();
  token;
  InputStat: boolean = false;
  TypeInput = "password";
  constructor(
    private identityServiceProxy: IdentityServiceProxy,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.identityServiceProxy
      .forgotPasswordTwo(
        new CheckTokenInput({ token: this.route.snapshot.params["token1"] }),
        this.route.snapshot.params["token2"]
      )
      .subscribe((Value) => {
        switch (Value.statusCode) {
          case "000":
            if (Value.result) {
              this.token = Value.result;
              return;
            } else {
              this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
            }
            break;

          default:
            this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
            break;
        }
      });
  }
  onSubmit() {
    this.model.userId = localStorage.getItem("email");
    this.identityServiceProxy
      .forgotPasswordThree(this.model, this.token)
      .subscribe((value) => {
        switch (value.statusCode) {
          case "000":
            if (value.result) {
              this.modalService.open(PopupChangepassSecussComponent);
              this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
            }
            break;

          default:
            break;
        }
      });
  }
  IsHidden() {
    if (!this.InputStat) {
      this.TypeInput = "text";
      this.InputStat = true;
    } else {
      this.TypeInput = "password";
      this.InputStat = false;
    }
  }
}
