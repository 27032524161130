import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

@Component({
  selector: "app-modal-client-exist",
  templateUrl: "./modal-client-exist.component.html",
  styleUrls: ["./modal-client-exist.component.scss"],
})
export class ModalClientExistComponent implements OnInit {
  typeError;
  showClient;
  showRefuse;
  listenFunc: Function;
  @ViewChild("target", { static: true }) toggleButton: ElementRef;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private renderer: Renderer2,
    private ngZone: NgZone
  ) {
    this.listenFunc = this.renderer.listen("window", "click", (e: Event) => {
      if (e.target !== this.toggleButton.nativeElement) {
        this.modalService.dismissAll();
        this.ngZone.run(() => this.router.navigate(["/LandingPage"])).then();
      }
    });
  }
  ngOnInit() {}
  ngOnDestroy() {
    this.listenFunc();
  }
  close() {
    this.modalService.dismissAll();
    this.ngZone.run(() => this.router.navigate(["/LandingPage"])).then();
  }
}
