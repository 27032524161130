import { Routes } from '@angular/router';
import { tokenVerfier } from './auth.guard';
import { PageNotFoundComponent } from './sharedPage/page-not-found/page-not-found.component';
import { SubscriptionPageComponent } from './subscriptionSteps/subscription-page/subscription-page.component';
import { ContentStepTwoComponent } from './subscriptionSteps/content-stepTwo/content-stepTwo.component';
import { ContentStepFourComponent } from './subscriptionSteps/content-stepFour/content-stepFour.component';
import { AuthenticationPageComponent } from './logOn/authentication-page/authentication-page.component';
import { FollowUpFilePageComponent } from './logOn/followUpFile-page/followUpFile-page.component';
import { ForgotPwdComponent } from './logOn/forgot-pwd/forgot-pwd.component';
import { ChangePwdComponent } from './logOn/change-pwd/change-pwd.component';
import { PageVosInformationsComponent } from './subscriptionSteps/page-vos-informations/page-vos-informations.component';
import { PagePriseContactComponent } from './subscriptionSteps/page-prise-contact/page-prise-contact.component';
import { PageConfirmationComponent } from './subscriptionSteps/page-confirmation/page-confirmation.component';
import { PageVotreContratComponent } from './subscriptionSteps/page-votre-contrat/page-votre-contrat.component';
import { PageVotreOffreComponent } from './subscriptionSteps/page-votre-offre/page-votre-offre.component';
import { GetProspectService } from './service-proxies/get-prospect.service';
import { Landing2Component } from './landing2/landing2.component';
import { ChatPageComponent } from './sharedPage/chat-page/chat-page.component';

export const routes: Routes = [
  { path: '', component: Landing2Component },
  { path: 'LandingPage', component: Landing2Component },
  { path: 'LogIn', component: AuthenticationPageComponent },
  { path: 'FolowUpFile', component: FollowUpFilePageComponent },
  { path: 'OtpStep', component: ContentStepTwoComponent },
  { path: 'FourStep', component: ContentStepFourComponent },
  { path: 'Subscription/:type', component: SubscriptionPageComponent },
  { path: 'ForgotPwd', component: ForgotPwdComponent },
  { path: 'CheckToken/:token1/:token2', component: ChangePwdComponent, canActivate: [tokenVerfier] },

  { path: 'VosInformations', component: PageVosInformationsComponent },
  { path: 'Confirmation/:identifiant', component: PageConfirmationComponent },
  {
    path: 'VotreOffre', component: PageVotreOffreComponent,
    resolve: {
      someKey: GetProspectService
    }
  },
  {
    path: 'PriseDeContact', component: PagePriseContactComponent, resolve: {
      someKey: GetProspectService
    }
  },
  {
    path: 'VotreContrat', component: PageVotreContratComponent, resolve: {
      someKey: GetProspectService
    }
  },

  { path: 'landing', component: Landing2Component },
  { path: 'Chat', component: ChatPageComponent },

  { path: 'NotPageFound', component: PageNotFoundComponent },
];




