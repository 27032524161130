import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-invt',
  templateUrl: './modal-invt.component.html',
  styleUrls: ['./modal-invt.component.scss']
})
export class ModalInvtComponent implements OnInit {
  @Input() systemPhone:string
  constructor(private modalService: NgbModal, private route:Router) { }

  ngOnInit() {
    console.log(this.systemPhone);
    
  }
  cancel(){
    this.modalService.dismissAll()
  }
  download(){
    if(this.systemPhone=='ios'){
      window.location.href = 'https://apps.apple.com/fr/app/cih-mobile/id664637221';
    }
    else if(this.systemPhone
      == 'android'){
        window.location.href = 'https://play.google.com/store/apps/details?id=com.b3g.cih.online&hl=fr&gl=FR';
      }
  }

}
