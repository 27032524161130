import { Component, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-popup-crc",
  templateUrl: "./popup-crc.component.html",
  styleUrls: ["./popup-crc.component.scss"],
})
export class PopupCRCComponent {
  @Input() callMeBack: boolean;
  @Input() contactAgency: boolean;
  @Input() agencyInfo: any;
  @Input() activateAccount: boolean;

  constructor(private modalService: NgbModal) {}

  dismiss() {
    this.modalService.dismissAll();
    if (this.callMeBack) {
      window.location.reload();
    }
  }
}
