import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';


@NgModule({
    providers: [
        ApiServiceProxies.CountryServiceProxy,
        ApiServiceProxies.AgencyMapServiceProxy,
      //  ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ProspectServiceProxy,
        //ApiServiceProxies.OfferServiceProxy,
        ApiServiceProxies.AuthServiceProxy,
        ApiServiceProxies.IdentityServiceProxy,
        ApiServiceProxies.ProfessionServiceProxy,
        ApiServiceProxies.ProspectProductServiceProxy,
        //   ApiServiceProxies.ProspectLoginServiceProxy,
        //   ApiServiceProxies.MediaFileServiceProxy,
        ApiServiceProxies.OperatorServiceProxy,
        ApiServiceProxies.ProspectServiceProxy,
        ApiServiceProxies.DataFromBDServiceProxy
    ]
})
export class ServiceProxyModule { }
