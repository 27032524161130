import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-no-moroccan',
  templateUrl: './modal-no-moroccan.component.html',
  styleUrls: ['./modal-no-moroccan.component.scss']
})
export class ModalNoMoroccanComponent {

  constructor(private modalService: NgbModal) { }

  close() {
    this.modalService.dismissAll();
  }

  findUs() {}

}
