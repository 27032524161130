import { sharedDataService } from 'src/app/sharedModels.service';
import { Component, Output, Input, OnInit, EventEmitter } from '@angular/core';

import { CountryCode } from './../../assets/models/country-code';
import { Country } from './../../assets/models/country.model';
import * as _ from 'google-libphonenumber';

@Component({
  selector: 'ngx-intl-tel-input',
  templateUrl: './ngx-intl-tel-input.component.html',
  styleUrls: ['./ngx-intl-tel-input.component.css'],
  providers: [CountryCode]
})
export class NgxIntlTelInputComponent implements OnInit {
  private _selectedResidenceCountryCode: string;
  @Input()
  value = '';
  @Input()
  preferredCountries: Array<string> = [];
  @Output()
  valueChange: EventEmitter<{ number: string, areaCode: string, code: number, iso: string }> = new EventEmitter<{ number: string, areaCode: string, code: number, iso: string }>();
  @Output()
  codeCountryChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() set selectedResidenceCountry(newValue: string) {
    this._selectedResidenceCountryCode = newValue;
    const country = this.allCountries.find(c => c.iso2.toLocaleLowerCase() == newValue.toLocaleLowerCase());
    this.selectedCountry = country;
    this.valueFlag.countryFlag = country.flagClass;
    this.valueFlag.countryCode = country.dialCode;
    //this.phone_number = this.valueFlag.countryCode
    if (this.phone_number.length > 0) {
      this.value = this.selectedCountry.dialCode + this.phone_number;
      this.valueChange.emit({ number: this.phone_number, areaCode: this.selectedCountry.dialCode, code: this.selectedCountry.dialCode.length, iso: this.selectedCountry.iso2 });
    }
  }

  phone_number = '';
  allCountries: Array<Country> = [];
  preferredCountriesInDropDown: Array<Country> = [];
  selectedCountry: Country = new Country();
  constructor(private countryCodeData: CountryCode, private valueFlag: sharedDataService) {
    this.fetchCountryData();
  }

  ngOnInit() {
    if (this.preferredCountries.length) {
      this.preferredCountries.forEach(iso2 => {
        const preferredCountry = this.allCountries.filter(c => {
          return c.iso2 === iso2;
        });
        this.preferredCountriesInDropDown.push(preferredCountry[0]);
      });
    }
    if (this.preferredCountriesInDropDown.length) {
      this.selectedCountry = this.preferredCountriesInDropDown[0];
    } else {
      this.selectedCountry = this.allCountries[0];
    }
    this.valueFlag.countryFlag = this.selectedCountry.flagClass;
    this.valueFlag.countryCode = this.selectedCountry.dialCode;
    // this.phone_number = this.selectedCountry.dialCode;
    // alert('on init ' + this.selectedCountry.dialCode);
  }

  public onPhoneNumberChange(): void {
    this.value = this.selectedCountry.dialCode + this.phone_number;

    if (this.value[this.selectedCountry.dialCode.length + 1] == '0') {
      this.value.substring(this.selectedCountry.dialCode.length + 1)
      this.valueChange.emit({ number: this.phone_number, areaCode: this.selectedCountry.dialCode, code: this.selectedCountry.dialCode.length, iso: this.selectedCountry.iso2 });
    }
    else {
      this.valueChange.emit({ number: this.phone_number, areaCode: this.selectedCountry.dialCode, code: this.selectedCountry.dialCode.length, iso: this.selectedCountry.iso2 });
    }
  }

  public onCountrySelect(country: Country, el): void {
    this.selectedCountry = country;
    this.valueFlag.countryFlag = country.flagClass;
    this.valueFlag.countryCode = country.dialCode;

    this.codeCountryChange.emit(this.selectedCountry.iso2);
    el.focus();
  }

  public onInputKeyPress(event): void {
    // if (this.selectedCountry.flagClass === 'ma') {
    //let pattern = /^[1-9]/;
    const pattern = /^[1-9][0-9]$/
    const pat = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pat.test(inputChar)) {
      event.preventDefault();
    }

    if (event.key == '0' && (document.getElementById('phone') as HTMLInputElement).selectionStart == 0) {
      event.preventDefault();
    }
    if (this.phone_number[0] === '0') {
      this.phone_number = this.phone_number.substring(1)
    }
  }

  protected fetchCountryData(): void {
    this.countryCodeData.allCountries.forEach(c => {
      const country = new Country();
      country.name = c[0].toString();
      country.iso2 = c[1].toString();
      country.dialCode = c[2].toString();
      country.priority = +c[3] || 0;
      country.areaCode = +c[4] || null;
      country.flagClass = country.iso2.toLocaleLowerCase();
      country.placeHolder = this.getPhoneNumberPlaceHolder(
        country.iso2.toUpperCase()
      );
      this.allCountries.push(country);
    });
  }

  protected getPhoneNumberPlaceHolder(countryCode: string): string {
    const phoneUtil = _.PhoneNumberUtil.getInstance();
    const pnf = _.PhoneNumberFormat;
    try {
      const phoneNumber = phoneUtil.parse('2236512366', countryCode);
      return phoneUtil.format(phoneNumber, pnf.INTERNATIONAL);
    } catch (e) {
      // console.log('CountryCode: '' + countryCode + '' ' + e);
      return e;
    }
  }
}
