

import { NgModule } from '@angular/core';
import { MatDatepickerModule, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';



@NgModule({
  imports: [MatDatepickerModule, MatInputModule,
    MatFormFieldModule, MatNativeDateModule, MatSelectModule, MatIconModule, MatCheckboxModule],
exports: [MatDatepickerModule, MatInputModule,MatCheckboxModule,
  MatFormFieldModule, MatNativeDateModule, MatSelectModule, MatIconModule],
  providers: [MatDatepickerModule, {provide: MAT_DATE_LOCALE, useValue: 'fr'},
                                   {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
                                   {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},],
 })

export class MaterialModule { }


