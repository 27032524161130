import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-popup-fatca",
  templateUrl: "./popup-fatca.component.html",
  styleUrls: ["./popup-fatca.component.scss"],
})
export class PopupFatcaComponent implements OnInit {
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private ngZone: NgZone
  ) {}

  ngOnInit() {}

  Fermer() {
    this.modalService.dismissAll();
    this.ngZone.run(() => this.router.navigate(["/LandingPage"])).then();
  }
}
