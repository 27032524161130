import { Component,EventEmitter,Output  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { sharedDataService } from 'src/app/sharedModels.service';

@Component({
  selector: 'app-model-aidesocial',
  templateUrl: './model-aidesocial.component.html',
  styleUrls: ['./model-aidesocial.component.scss']
})
export class ModalAideSocialComponent {

  @Output() addedContent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private modalService: NgbModal ,private router: Router,private sharedService: sharedDataService) { }

  close() {
    this.modalService.dismissAll();
  }

  buttonClicked() { 
    console.log("etape1")
    this.sharedService.updateShowFileContent(true);
    this.modalService.dismissAll();
  }

  findUs() {}

}
