import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mre-modal',
  templateUrl: './mre-modal.component.html',
  styleUrls: ['./mre-modal.component.scss']
})
export class MreModalComponent implements OnInit {

  constructor(private zone: NgZone,
    private router: Router,
    private modalService: NgbModal) { }

  ngOnInit() {
  }

  close() {
    this.modalService.dismissAll();
    this.zone.run(() => this.router.navigate(["/LandingPage"])).then();
  }

  openUrl(url) {
    window.open(url, '_blank').focus();
  }
}
