import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap, catchError } from 'rxjs/operators'
import { ProspectServiceProxy } from './service-proxies';
import { ProspectService } from './prospect.service';

@Injectable({
  providedIn: 'root'
})
export class GetProspectService implements Resolve<any>  {

  constructor(private _prospectService: ProspectServiceProxy, private prospect: ProspectService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    
       return this._prospectService.getProspectInfo(localStorage.getItem('sessionId')).pipe(catchError(error => {
          return EMPTY
       }), mergeMap(result => {
        if (result.result != undefined) {
          return of(this.prospect.prospect = result.result)
        }
       })
       )
    

 }
  
}
