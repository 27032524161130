import { Component, OnInit, HostListener, Inject  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalVideoComponent } from './modal-video/modal-video.component';
import { trigger, state, transition, style, animate } from '@angular/animations'; 
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'], animations:[ 
    trigger('fade',
    [ 
      state('void', style({ opacity : 0})),
      transition(':enter',[ animate(300)]),
      transition(':leave',[ animate(500)]),
    ]
)]

})
export class LandingPageComponent implements OnInit {
  title = 'My first AGM project';
  lat = 51.678418;
  lng = 7.809007;
  constructor(private modalService: NgbModal, private router: Router, private route: ActivatedRoute, @Inject(DOCUMENT) document) {
    //   this.route.params.subscribe(params => console.log(params));
    //  this.logger.log('Your log message goes here');
  }
  ngOnInit() { }

  OpenSubscriptionPage() {

    this.router.navigate(['/VosInformations']);

  }
  OpenLogOnPage() {

    this.router.navigate(['/LogIn']);

  }

  showVideo() {
    const modalRef = this.modalService.open(ModalVideoComponent).result.then((result) => {
    }, (reason) => {
      this.router.navigate(['/LandingPage']);
    });
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 5) {
       let element = document.getElementById('navbar');
       element.classList.add('sticky');
     } else {
      let element = document.getElementById('navbar');
        element.classList.remove('sticky'); 
     }
  }

}
