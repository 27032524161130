import { Component, HostListener, OnInit, Inject, NgZone } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import { DeviceDetectorService } from "ngx-device-detector";
import { ModalInvtComponent } from "./modal-invt/modal-invt.component";

@Component({
  selector: "app-landing2",
  templateUrl: "./landing2.component.html",
  styleUrls: ["./landing2.component.scss"],
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate(300)]),
      transition(":leave", [animate(500)]),
    ]),
  ],
})
export class Landing2Component implements OnInit {
  deviceInfo;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) document,
    private deviceService: DeviceDetectorService,
    private ngZone: NgZone
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    console.log(this.deviceService.getDeviceInfo());
    if (this.deviceService.isMobile()) {
      const modalRef = this.modalService.open(ModalInvtComponent);
      modalRef.componentInstance.systemPhone = this.deviceInfo.os.toLowerCase();
    }
  }

  ngOnInit() {}

  OpenSubscriptionPage() {
    this.ngZone.run(() => this.router.navigate(["/VosInformations"])).then();
  }

  OpenLogOnPage() {
    this.ngZone.run(() => this.router.navigate(["/LogIn"])).then();
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (window.pageYOffset > 5) {
      let element = document.getElementById("navbar");
      element.classList.add("sticky");
    } else {
      let element = document.getElementById("navbar");
      element.classList.remove("sticky");
    }
  }
}
