import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class sharedDataService {

  // phone flag
  private _countryFlag: string;
  public get countryFlag():string{return this._countryFlag  };
  public set countryFlag(value:string){ this._countryFlag=value };

  // phone flag
  private _countryCode: string;
  public get countryCode():string{return this._countryCode  };
  public set countryCode(value:string){ this._countryCode=value };

  // object
  private _objectProspect: object;
  public get objectProspect():object{return this._objectProspect  };
  public set objectProspect(value:object){ this._objectProspect=value };

 // suivi dossier
  private _objectSuiviDossier: object;
  public get objectSuiviDossier():object{return this._objectSuiviDossier  };
  public set objectSuiviDossier(value:object){ this._objectSuiviDossier=value };

  // prospect

 public  viewprospect:viewProspect;

 private showFileContentSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showFileContent$: Observable<boolean> = this.showFileContentSubject.asObservable();


constructor() { }

updateShowFileContent(value: boolean): void {
  console.log("etape2")
  this.showFileContentSubject.next(value);
}

}


export class viewProspect {

  private _id: string;
  get id():string{return this._id };
  set id(value:string){ this._id=value };

  private _gender: string;
  get gender():string{return this._gender };
  set gender(value:string){ this._gender=value };

  private _firstName: string;
  get firstName():string{return this._firstName };
  set firstName(value:string){ this._firstName=value };

  private _lastName: string;
  get lastName():string{return this._lastName };
  set lastName(value:string){ this._lastName=value };

  private _mail: string;
  get mail():string{return this._mail };
  set mail(value:string){ this._mail=value };

  private _mobile: string;
  get mobile():string{return this._mobile };
  set mobile(value:string){ this._mobile=value };

  private _operatorId: string;
  get operatorId():string{return this.operatorId };
  set operatorId(value:string){ this._operatorId=value };

  private _operatorLabel: string;
  get operatorLabel():string{return this.operatorLabel };
  set operatorLabel(value:string){ this._operatorLabel=value };

  private _dateBirth: string;
  get dateBirth():string{return this.dateBirth };
  set dateBirth(value:string){ this._dateBirth=value };

  private _nbChild: number;
  get nbChild():number{return this.nbChild };
  set nbChild(value:number){ this._nbChild=value };

  private _nationality: string;
  get nationality():string{return this._nationality };
  set nationality(value:string){ this._nationality=value };

  private _situationProfessional: string;
  get situationProfessional():string{return this._situationProfessional };
  set situationProfessional(value:string){ this._situationProfessional=value };

  private _profession: string;
  get profession():string{return this._profession };
  set profession(value:string){ this._profession=value };

  private _identifier: string;
  get identifier():string{return this._identifier };
  set identifier(value:string){ this._identifier=value };

  private _offerId: string;
  get offerId():string{return this._offerId };
  set offerId(value:string){ this._offerId=value };

  private _offerLabel: string;
  get offerLabel():string{return this._offerLabel };
  set offerLabel(value:string){ this._offerLabel=value };

  private _offerPicture: string;
  get offerPicture():string{return this._offerPicture };
  set offerPicture(value:string){ this._offerPicture=value };

  private _productId: string;
  get productId():string{return this._productId };
  set productId(value:string){ this._productId=value };

  private _productLabel: string;
  get productLabel():string{return this._productLabel };
  set productLabel(value:string){ this._productLabel=value };

  private _agenceName: string;
  get AgenceName():string{return this._agenceName };
  set AgenceName(value:string){ this._agenceName=value };

  private _timeSlot: string;
  get TimeSlot():string{return this._timeSlot };
  set TimeSlot(value:string){ this._timeSlot=value };

private _appointement: string;
get Appointement():string{return this._appointement };
set Appointement(value:string){ this._appointement=value };

private _basicOffre: string;
get BasicOffre():string{return this._basicOffre };
set BasicOffre(value:string){ this._basicOffre=value };

private _complementaryOffer: string;
get ComplementaryOffer():string{return this._complementaryOffer };
set ComplementaryOffer(value:string){ this._complementaryOffer=value };

private _cin: string;
get CIN():string{return this._cin };
set CCIN(value:string){ this._cin=value };

private _fonctionnaire: boolean;
get Fonctionnaire():boolean{return this._fonctionnaire };
set FFonctiaire(value:boolean){ this._fonctionnaire=value };

    }



  /// partie gestion du dossier


  export class SuiviDossier {

    private _principalOffer: PrincipalOffer;
    get principalOffer():PrincipalOffer{return this._principalOffer };
    set principalOffer(value:PrincipalOffer){ this._principalOffer=value };

    // private _eighteenOffer: EighteenOffer[];
    // get eighteenOffer():EighteenOffer[]{return this._eighteenOffer };
    // set eighteenOffer(value:EighteenOffer[]){ this._eighteenOffer=value };

    private _complementaryOffer: ComplementaryOffer[];
    get complementaryOffer():ComplementaryOffer[]{return this._complementaryOffer };
    set complementaryOffer(value:ComplementaryOffer[]){ this._complementaryOffer=value };

  };
  export class PrincipalOffer {

    private _identifiant: number;
    get identifiant():number{return this._identifiant };
    set identifiant(value:number){ this._identifiant=value };

    private _gender: string;
    get gender():string{return this._gender };
    set gender(value:string){ this._gender=value };

    private _nom: string;
    get nom():string{return this._nom };
    set nom(value:string){ this._nom=value };

    private _prenom: string;
    get prenom():string{return this._prenom };
    set prenom(value:string){ this._prenom = value };

    private _mail: string;
    get mail():string{return this._mail };
    set mail(value:string){ this._mail = value };

    private _tel: string;
    get tel():string{return this._tel };
    set tel(value:string){ this._tel = value };

    private _dateNaissance: string;
    get dateNaissance():string{return this._dateNaissance };
    set dateNaissance(value:string){ this._dateNaissance = value };

    private _offreImage: string;
    get offreImage():string{return this._offreImage };
    set offreImage(value:string){ this._offreImage = value };

    private _conforme: boolean;
    get conforme():boolean{return this._conforme };
    set conforme(value:boolean){ this._conforme = value };

    private _status: string;
    get status():string{return this._status };
    set status(value:string){ this._status = value };

    private _cardImage: string;
    get cardImage():string{return this._cardImage };
    set cardImage(value:string){ this._cardImage = value };

    private _operatorLabel: string;
    get operatorLabel():string{return this._operatorLabel };
    set operatorLabel(value:string){ this._operatorLabel=value };

    private _offerId: string;
    get offerId():string{return this._offerId };
    set offerId(value:string){ this._offerId=value };

    private _offerLabel: string;
    get offerLabel():string{return this._offerLabel };
    set offerLabel(value:string){ this._offerLabel };

    private _offerImg: string;
    get offerImg():string{return this._offerImg };
    set offerImg(value:string){ this._offerImg };

    private _nbChild: number;
    get nbChild():number{return this._nbChild };
    set nbChild(value:number){ this._nbChild };

    private _isCompletOffer: boolean;
    get isCompletOffer():boolean{return this._isCompletOffer };
    set isCompletOffer(value:boolean){ this._isCompletOffer };

    private _productId: string;
    get productId():string{return this._productId };
    set productId(value:string){ this._productId=value };

    private _productLabel: string;
    get productLabel():string{return this._productLabel };
    set productLabel(value:string){ this._productLabel };

    private _productImg: string;
    get productImg():string{return this._productImg };
    set productImg(value:string){ this._productImg };

    private _child: Array<ChildP>;
    get child():Array<ChildP>{return this._child };
    set child(value:Array<ChildP>){ this._child };

    private _isFileConforme: boolean;
    get isFileConforme():boolean{return this._isFileConforme };
    set isFileConforme(value:boolean){ this._isFileConforme };



}

export class ChildP {

  private _identifiant: string;
  get identifiant():string{return this._identifiant};
  set identifiant(value:string){this._identifiant=value};

  private _gender: string;
  get gender():string{return this._gender };
  set gender(value:string){ this._gender=value };

  private _nom: string;
  get nom():string{return this._nom };
  set nom(value:string){ this._nom=value };

  private _prenom: string;
  get prenom():string{return this._prenom };
  set prenom(value:string){ this._prenom = value };

  private _dateNaissance: string;
  get dateNaissance():string{return this._dateNaissance };
  set dateNaissance(value:string){ this._dateNaissance = value };

  private _offreImage: string;
  get offreImage():string{return this._offreImage };
  set offreImage(value:string){ this._offreImage = value };

  private _conforme: boolean;
  get conforme():boolean{return this._conforme };
  set conforme(value:boolean){ this._conforme = value };

  private _status: string;
  get status():string{return this._status };
  set status(value:string){ this._status = value };

  private _cardImage: string;
  get cardImage():string{return this._cardImage };
  set cardImage(value:string){ this._cardImage = value };

  private _isBoy: boolean;
  get isBoy():boolean{return this._isBoy };
  set isBoy(value:boolean){ this._isBoy = value };

  private _offerId: string;
    get offerId():string{return this._offerId };
    set offerId(value:string){ this._offerId=value };

    private _offerLabel: string;
    get offerLabel():string{return this._offerLabel };
    set offerLabel(value:string){ this._offerLabel };

    private _offerImg: string;
    get offerImg():string{return this._offerImg };
    set offerImg(value:string){ this._offerImg };

    private _nbChild: number;
    get nbChild():number{return this._nbChild };
    set nbChild(value:number){ this._nbChild };

    private _isCompletOffer: boolean;
    get isCompletOffer():boolean{return this._isCompletOffer };
    set isCompletOffer(value:boolean){ this._isCompletOffer };

    private _productId: string;
    get productId():string{return this._productId };
    set productId(value:string){ this._productId=value };

    private _productLabel: string;
    get productLabel():string{return this._productLabel };
    set productLabel(value:string){ this._productLabel };

    private _productImg: string;
    get productImg():string{return this._productImg };
    set productImg(value:string){ this._productImg };

    private _isFileConforme: boolean;
    get isFileConforme():boolean{return this._isFileConforme };
    set isFileConforme(value:boolean){ this._isFileConforme };

    private _mediaId: number;
    get mediaId():number{return this._mediaId };
    set mediaId(value:number){ this._mediaId=value };

}

class ComplementaryOffer {

  private _libelle: string;
  get libelle():string{return this._libelle };
  set libelle(value:string){ this._libelle = value };

  private _offreImage: string;
  get offreImage():string{return this._offreImage };
  set offreImage(value:string){ this._offreImage = value };

  private _status: boolean;
  get status():boolean{return this._status };
  set status(value:boolean){ this._status = value };

}
