
import { Component, Input, NgModule, ElementRef, OnInit } from '@angular/core';
import { ViewChild, AfterViewInit } from '@angular/core';

import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { ProspectServiceProxy, B3GResponseOfString } from 'src/app/service-proxies/service-proxies';
import { sharedDataService } from 'src/app/sharedModels.service';

@Component({
  selector: 'app-refuse-popup',
  templateUrl: './refuse-popup.component.html',
  styleUrls: ['./refuse-popup.component.scss']
})
export class RefusePopupComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private router: Router, private _eref: ElementRef,
    private _sharedProspect: sharedDataService, private _prospect: ProspectServiceProxy, private modalService: NgbModal) {
  }

  ngOnInit() {
  }
  close() {
    this.modalService.dismissAll();
  }

  /*   decliner() {
      let p= new ProspectModel();
      p.id =this._sharedProspect.objectProspect['_id'];
      this._prospect.(p,'')
      .subscribe((result: B3GResponseOfString ) => {
        switch (result.statusCode) {
          case "000":
          this.modalService.dismissAll();
              this.router.navigate(['/LandingPage']);
               break;
          default:
               break;
        }
     },(err) => {this.router.navigate(['/NotPageFound'])})
    } */

}
