import { Status } from "./../../Globals.enum";
import { OffreComplementaireComponent } from "./../offre-complementaire/offre-complementaire.component";
import {
  Component,
  OnInit,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
  NgZone,
} from "@angular/core";
import { Router } from "@angular/router";
import * as progressbar from "progressbar.js";
import { FormControl } from "@angular/forms";
import {
  Subscriber,
  recapChild,
  child,
  Identification,
  CollecteInformation,
} from "../../../assets/models/Client";

import {
  CountryServiceProxy,
  CountryDto,
  B3GResponseOfListOfCountryDto,
  ChildProspectDto,
  DataFromBDServiceProxy,
  FileInput,
} from "../../service-proxies/service-proxies";
import {
  MediaFileProspectDto,
} from "../../service-proxies/service-proxies";

import {
  ProfessionServiceProxy,
  B3GResponseOfListOfProfessionalDto,
  ProfessionalDto,
  InfoProspect,
  OffersInput,
} from "../../service-proxies/service-proxies";
import {
  ProspectServiceProxy,
  MobileCompleteProspectDto,
  ProspectProductServiceProxy,
  InfoOCR,
  ProspectInfoDto,
  ProspectProductDto,
  OfferDto,
} from "../../service-proxies/service-proxies";
import { sharedDataService } from "src/app/sharedModels.service";
import { NgxImageCompressService } from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProspectService } from "src/app/service-proxies/prospect.service";
import { DatePipe } from "@angular/common";
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { PopupFatcaComponent } from "../popup-fatca/popup-fatca.component";
import { SlickComponent } from "ngx-slick";
import { MreModalComponent } from "../mre-modal/mre-modal.component";
import { ModalAideSocialComponent } from "src/app/subscriptionSteps/modal-aidesocial/model-aidesocial.component";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-content-stepthree",
  templateUrl: "./content-stepThree.component.html",
  styleUrls: ["./content-stepThree.component.scss"],
  animations: [
    trigger("rotatedState", [
      state("default", style({ transform: "rotate(45deg)" })),
      state("rotated", style({ transform: "rotate(135deg)" })),
      transition("rotated => default", animate("500ms ease-out")),
      transition("default => rotated", animate("500ms ease-out")),
    ]),
    trigger("collapse", [
      state("false", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("true", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate("600ms ease-in")),
      transition("true => false", animate("600ms ease-out")),
    ]),
  ],
})
export class ContentStepThreeComponent implements OnInit {
  showFileContent: boolean = false;
  private showFileContentSubscription: Subscription;

  animationStateOffer = true;
  animationStateIdentification = true;
  animationStateCollecte = true;
  imgTest;
  _offerId: number;
  _cardId: number;
  base64textStringRecto;
  base64textStringVerto;
  activebtn: boolean;
  selected = 144;
  completeProspectDto = new MobileCompleteProspectDto();
  restOfInfo = new InfoProspect();
  motif;
  autreMotif;
  @Input() getDateFromPageOffer;
  imgResultAfterCompress;
  desactiver: boolean = false;
  isCINRecto: boolean = true;
  isCINVerso: boolean = true;
  isJAC = true;
  isJAR = true;
  isJustif = true;
  isEngagementFile: boolean = true;
  isSizeRectoValid: boolean = true;
  isSizeVersoValid: boolean = true;
  isSizeJustifValid: boolean = true;
  isSizeJAC: boolean = true;
  isSizeJAR: boolean = true;
  isSizeJustif: boolean = true;
  isvalidRecto: boolean = false;
  isvalidVerso: boolean = false;
  isvalidJUC = false;
  isvalidJUR = false;
  isvalidEngagment: boolean = false;
  isSizeEngagementValid: boolean = true;
  fileRecto = null;
  fileVerso = null;
  juAC = null;
  juAR = null;
  enagement = null;
  validateCardCodeTrente: boolean = true;
  isExistAdditionalOffer = false;
  fileRectoAllElement: FileRectoAllElement = new FileRectoAllElement();
  fileVersoAllElement: FileVersoAllElement = new FileVersoAllElement();
  FileJustifAllElement: FileJustifAllElement = new FileJustifAllElement();
  justificatifAddresseCourier: Justificatif = new Justificatif();
  justificatifAddresseResidence: Justificatif = new Justificatif();
  csr: Justificatif = new Justificatif();
  csv: Justificatif = new Justificatif();
  fileEnagementAllElement: FileEngagementAllElement =
    new FileEngagementAllElement();
  fileRectoByteString: string = null;
  listChildAPI: Array<ChildProspectDto>;
  ListVille;
  ListVilleBackup;
  stateIdentification: string = "default";
  stateCollecte: string = "default";
  stateOffre: string = "default";
  status = "insert";
  @ViewChild("slickModal", { static: false }) slickModal: SlickComponent;
  @ViewChild("slickModal1", { static: false }) slickModal1: SlickComponent;
  public CData: string;
  public childNom: string;
  public childPrenom: string;
  public childDateNaiss: string;
  public childCardImg: string;
  public childCardLibelle: string;
  public childInfo: recapChild;
  public show: boolean = false;
  public _child: child;
  public sRecap: boolean = false;
  isFatca: boolean = false;
  listChildAdding: Array<child>;

  @ViewChild("ViewOffreComp", { static: false }) offrecomp: OffreComplementaireComponent;
  @ViewChild("fileRectoInput", { static: false }) fileRectoInput: ElementRef;

  client: Subscriber = new Subscriber();
  infoIdentification: Identification = new Identification();
  infoCollecter: CollecteInformation = new CollecteInformation();
  _listCountry: CountryDto[] = [];
  _listCountryBackup: CountryDto[] = [];
  _preferredCountry: CountryDto[] = [];
  _listSitProf: ProfessionalDto[] = [];
  _listSitProfBackup: ProfessionalDto[] = [];
  _listCards: ProspectProductDto[] = [];
  _listAditionalOffer: OfferDto;
  listSocioProfessionnelle;
  listSocioProfessionnelleBackup;
  listProfession = [];

  ListForm: any[] = [];
  ListChildNb: any[] = [];

  date = new FormControl(new Date());
  selectedSlide;
  getImage;
  getLabel;
  getIdCard;
  getPrixCard;
  // card;
  checkChild = false;
  disableButon = false;
  minDate = new Date();
  maxDateBirth = new Date();

  chooseCard = null;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1187,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  slideConfigOffer = {
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1187,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  getwidthwindow = window.innerWidth;
  slideConfigChildOffers = { slidesToShow: 2, slidesToScroll: 2 };
  listChildToShow = [];
  public nextChild: number;
  paramData;

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    if (window.innerWidth > 600) {
      this.slideConfigChildOffers = { slidesToShow: 3, slidesToScroll: 3 };
    } else {
      this.slideConfigChildOffers = { slidesToShow: 2, slidesToScroll: 2 };
    }
  }
  motifData;
  listBanque;
  listeNationalite;
  listeSecteurActivite;
  listeSituatuionProfessionnelle;
  listeSituationFamilialle;
  listeOrigineFonds;
  listMotivation;
  motifDataBackup;
  listBanqueBackup;
  listeNationaliteBackup;
  listeSecteurActiviteBackup;
  listeSituatuionProfessionnelleBackup;
  listeSituationFamilialleBackup;
  listeOrigineFondsBackup;
  listMotivationBackup;
  toOffers = false;
  toCollecte = false;
  collecte = false;
  newEmail;
  editMail = false;
  constructor(
    private router: Router,
    private _countryProxy: CountryServiceProxy,
    private _vProspectConfirmer: sharedDataService,
    private _sitProf: ProfessionServiceProxy,
    private _prospectProduct: ProspectProductServiceProxy,
    private _prospectService: ProspectServiceProxy,
    private cdRef: ChangeDetectorRef,
    private imageCompress: NgxImageCompressService,
    private http: HttpClient,
    private modalService: NgbModal,
    private prospe: ProspectService,
    private datepipe: DatePipe,
    private _datafrombd: DataFromBDServiceProxy,
    private ngZone: NgZone
  ) {

    this.showFileContentSubscription = this._vProspectConfirmer.showFileContent$.subscribe(value => {
      this.showFileContent = value;
    });

    this.infoCollecter.fatca = false;
    if (this.prospe.prospect == undefined || this.prospe.prospect.step == 6) {
      this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
    }

    this._countryProxy.getCities("").subscribe((e) => {
      this.ListVille = e.result.sort((a, b) =>
        a.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );
      this.ListVille = this.ListVille.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.ListVilleBackup = this.ListVille;
    });
    this.getJSON().subscribe((data) => {
      this.paramData = data;
    });
    this.getbauque().subscribe((data) => {
      this.listBanque = data.sort((a, b) =>
        a.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );
      this.listBanqueBackup = this.listBanque;
    });
    // this.getData().subscribe(data => {
    //   this.listeNationalite = data.nationalite
    //   this.listeNationaliteBackup = data.nationalite
    // })
    this._datafrombd.getAllOrigine("").subscribe((data) => {
      this.listeOrigineFonds = data.result.sort((a, b) =>
        a.origineFonds.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.origineFonds.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );
      this.listeOrigineFondsBackup = this.listeOrigineFonds;
    });
    this._datafrombd.getAllSecteur("").subscribe((data) => {
      this.listeSecteurActivite = data.result.sort((a, b) =>
        a.seteurActivite.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.seteurActivite.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );
      this.listeSecteurActiviteBackup = this.listeSecteurActivite;
    });
    this._datafrombd.getAllSituationFamiliale("").subscribe((data) => {
      this.listeSituationFamilialle = data.result.sort((a, b) =>
        a.situationFamilial1.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.situationFamilial1.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );
      this.listeSituationFamilialleBackup = this.listeSituationFamilialle;
    });
    this._datafrombd.getAllMotif("").subscribe((data) => {
      this.motifData = data.result.sort((a, b) =>
        a.motif1.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.motif1.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );
      this.motifDataBackup = this.motifData;
    });
    this._datafrombd.getAllMotivationCompte("").subscribe((data) => {
      this.listMotivation = data.result.sort((a, b) =>
        a.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );
      this.listMotivationBackup = this.listMotivation;
    });
    this._prospectService.getCollSocio("").subscribe((data) => {
      this.listSocioProfessionnelle = data.result.sort((a, b) =>
        a.libelleCodeSocioProfessional
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "") >
          b.libelleCodeSocioProfessional
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );
      this.listSocioProfessionnelleBackup = this.listSocioProfessionnelle;
    });
    if (this.prospe.prospect.step == 1) {
      this.stateIdentification = "rotated";
      this.toCollecte = true;
      setTimeout(() => {
        this.animationStateIdentification = false;
      }, 50);
    } else if (this.prospe.prospect.step == 2) {
      this.stateCollecte = "rotated";
      this.collecte = true;
      setTimeout(() => {
        this.animationStateCollecte = false;
      }, 50);
    } else if (
      this.prospe.prospect.step != 2 &&
      this.prospe.prospect.step != 1
    ) {
      setTimeout(() => {
        this.animationStateOffer = false;
      }, 50);
    }
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.maxDateBirth.setFullYear(this.maxDateBirth.getFullYear() - 18);
  }
  public getJSON(): Observable<any> {
    return this.http.get("../../../assets/data/param.json");
  }
  public getMotif(): Observable<any> {
    return this.http.get("../../../assets/data/motif.json");
  }
  public getbauque(): Observable<any> {
    return this.http.get("../../../assets/data/banques.json");
  }
  public getData(): Observable<any> {
    return this.http.get("../../../assets/data/otherData.json");
  }
  disablebutton: boolean = true;
  disablebuttonIdentification: boolean = true;
  disablebuttonCollecteInfo: boolean = true;
  disablebuttonOffer: boolean = true;
  private regex: RegExp = new RegExp(/^[A-Z]{1,2}[0-9]*$/g);
  ngAfterViewChecked() {
    if (this.toCollecte) {
      //     if (this.prospect.step == 1) {

      if (
        this.isSizeRectoValid &&
        this.isCINRecto &&
        this.isSizeVersoValid &&
        this.isCINVerso &&
        this.infoIdentification.identification != "" &&
        this.infoIdentification.identification != undefined &&
        this.infoIdentification.adresseCIN != "" &&
        this.infoIdentification.adresseCIN != undefined &&
        this.infoIdentification.dateValidite != undefined &&
        this.infoIdentification.dateBirth != undefined &&
        String(this.infoIdentification.identification).match(this.regex) &&
        this.infoIdentification.lieuNaissance != undefined &&
        this.infoIdentification.nomPere != "" &&
        this.infoIdentification.nomPere != undefined &&
        this.infoIdentification.nomMere != "" &&
        this.infoIdentification.nomMere != undefined &&
        this.isvalidRecto != false &&
        this.isvalidVerso != false
      ) {
        if (
          !this.AARchecked &&
          this.AACchecked &&
          this.infoIdentification.adrDiffCourrier != "" &&
          this.justificatifAddresseCourier != null &&
          this.infoIdentification.adrDiffCourrier != undefined &&
          this.isvalidJUC
        ) {
          this.disablebuttonIdentification = false;
        } else if (
          this.AARchecked &&
          this.AACchecked &&
          this.infoIdentification.adrDiffResidence != "" &&
          this.infoIdentification.adrDiffResidence != undefined &&
          this.justificatifAddresseResidence != null &&
          this.justificatifAddresseCourier != null &&
          this.infoIdentification.adrDiffCourrier != "" &&
          this.infoIdentification.dateValidite != undefined &&
          this.isvalidJUC &&
          this.isvalidJUR
        ) {
          this.disablebuttonIdentification = false;
        } else if (
          this.AARchecked &&
          this.infoIdentification.adrDiffResidence != "" &&
          this.justificatifAddresseResidence != null &&
          this.infoIdentification.adrDiffResidence != undefined &&
          !this.AACchecked &&
          this.isvalidJUR
        ) {
          this.disablebuttonIdentification = false;
        } else if (!this.AACchecked && !this.AARchecked) {
          this.disablebuttonIdentification = false;
        } else {
          this.disablebuttonIdentification = true;
        }
      } else {
        this.disablebuttonIdentification = true;
      }
      // }
      // else {
      //   if (this.infoIdentification.identification != '' && this.infoIdentification.identification != undefined && this.infoIdentification.adresseCIN != '' && this.infoIdentification.adresseCIN != undefined && this.infoIdentification.dateValidite != undefined && this.infoIdentification.dateBirth != undefined && String(this.infoIdentification.identification).match(this.regex) && this.infoIdentification.lieuNaissance != undefined && this.infoIdentification.nomPere != '' && this.infoIdentification.nomPere != undefined && this.infoIdentification.nomMere != '' && this.infoIdentification.nomMere != undefined) {
      //     if (!this.AARchecked && this.AACchecked && this.infoIdentification.adrDiffCourrier != 'Aucune' && this.infoIdentification.adrDiffCourrier != '' && this.infoIdentification.adrDiffCourrier != undefined) {
      //       this.disablebuttonIdentification = false;
      //     }
      //     else if (this.AARchecked && this.AACchecked && this.infoIdentification.adrDiffResidence != '' && this.prospect.adrDiffResidence != 'Aucune' && this.prospect.adrDiffCourrier != 'Aucune' && this.infoIdentification.adrDiffResidence != undefined && this.infoIdentification.adrDiffCourrier != '' && this.infoIdentification.adrDiffCourrier != undefined) {
      //       this.disablebuttonIdentification = false;
      //     }
      //     else if (this.AARchecked && this.AACchecked && this.infoIdentification.adrDiffResidence != '' && this.prospect.adrDiffResidence == 'Aucune' && this.infoIdentification.adrDiffResidence != undefined && this.infoIdentification.adrDiffCourrier != '' && this.infoIdentification.adrDiffCourrier != undefined && this.justificatifAddresseResidence != null && this.isvalidJUR) {
      //       this.disablebuttonIdentification = false;
      //     }
      //     else if (this.AARchecked && this.AACchecked && this.infoIdentification.adrDiffResidence != '' && this.prospect.adrDiffCourrier == 'Aucune' && this.infoIdentification.adrDiffResidence != undefined && this.infoIdentification.adrDiffCourrier != '' && this.infoIdentification.adrDiffCourrier != undefined && this.justificatifAddresseCourier != null && this.isvalidJUC) {
      //       this.disablebuttonIdentification = false;
      //     }
      //     else if (this.AARchecked && this.AACchecked && this.infoIdentification.adrDiffResidence != '' && this.prospect.adrDiffResidence != 'Aucune' && this.prospect.adrDiffCourrier != 'Aucune' && this.infoIdentification.adrDiffResidence != undefined && this.infoIdentification.adrDiffCourrier != '' && this.infoIdentification.adrDiffCourrier != undefined && this.justificatifAddresseCourier != null && this.isvalidJUC && this.justificatifAddresseResidence != null && this.isvalidJUR) {
      //       this.disablebuttonIdentification = false;
      //     }
      //     else if (this.AARchecked && this.infoIdentification.adrDiffResidence != '' && this.prospect.adrDiffResidence != 'Aucune' && this.infoIdentification.adrDiffResidence != 'Aucune' && this.infoIdentification.adrDiffCourrier != undefined && !this.AACchecked) {
      //       this.disablebuttonIdentification = false;
      //     }
      //     else if (this.AARchecked && this.infoIdentification.adrDiffResidence != '' && this.prospect.adrDiffResidence == 'Aucune' && this.justificatifAddresseResidence != null && this.isvalidJUR && this.infoIdentification.adrDiffCourrier != undefined && !this.AACchecked) {
      //       this.disablebuttonIdentification = false;
      //     }
      //     else if (!this.AARchecked && this.AACchecked && this.prospect.adrDiffCourrier == 'Aucune' && this.infoIdentification.adrDiffCourrier != '' && this.infoIdentification.adrDiffCourrier != undefined && this.justificatifAddresseCourier != null && this.isvalidJUC) {
      //       this.disablebuttonIdentification = false;
      //     }
      //     else if (!this.AACchecked && !this.AARchecked) {
      //       this.disablebuttonIdentification = false;
      //     }
      //     else {
      //       this.disablebuttonIdentification = true;
      //     }
      //   }
      //   else {
      //     this.disablebuttonIdentification = true;
      //   }
      // }
    }
    if (this.collecte) {
      //if (this.prospect.step == 2) {
      // (this.situationPro.affichageAutreInfo &&
      // this.infoCollecter.souscriptionOffre != "Aide Sociale" &&
      if (this.infoCollecter.fonctionnaire != true) {
        if (
          this.infoCollecter.fonctionnaire != undefined &&
          this.infoCollecter.motif != undefined &&
          this.infoCollecter.nationalite != undefined &&
          this.infoCollecter.paysNaissance != undefined &&
          ((this.situationPro.affichageAutreInfo &&
            this.infoCollecter.secteurActivite != undefined &&
            this.infoCollecter.socioProfessional != undefined &&
            this.infoCollecter.profession != undefined) ||
            this.situationPro.affichageAutreInfo == false) &&
          this.infoCollecter.situationFamilial != undefined &&
          this.infoCollecter.situationProffessionnelle != undefined &&
          this.infoCollecter.origineDeFonds != "" &&
          this.infoCollecter.origineDeFonds != undefined &&
          this.infoCollecter.revenuMensuel != "" &&
          this.infoCollecter.revenuMensuel != undefined &&
          this.infoCollecter.fatca != undefined &&
          this.infoCollecter.souscriptionOffre != undefined &&
          this.infoCollecter.autreCompte != undefined
        ) {
          if (
            this.infoCollecter.autreCompte &&
            this.infoCollecter.nomBanque != "" &&
            this.infoCollecter.nomBanque != undefined
          ) {
            this.disablebuttonCollecteInfo = false;
          } else if (!this.infoCollecter.autreCompte) {
            this.disablebuttonCollecteInfo = false;
          } else if (
            this.infoCollecter.motif == "Autre" &&
            (this.infoCollecter.autreMotif == "" ||
              this.infoCollecter.autreMotif == undefined)
          ) {
            this.disablebuttonCollecteInfo = true;
          }else if (
            this.infoCollecter.souscriptionOffre == "Autres" &&
            (this.infoCollecter.motivationOuvCompte == undefined ||
              this.infoCollecter.motivationOuvCompte == "")
          ) {
            this.disablebuttonCollecteInfo = true;
          } else {
            this.disablebuttonCollecteInfo = true;
          }
        } else {
          this.disablebuttonCollecteInfo = true;
        }
      } else {
        if (
          this.isSizeEngagementValid &&
          this.isEngagementFile &&
          this.isvalidEngagment != false &&
          this.infoCollecter.motif != undefined &&
          this.infoCollecter.nationalite != undefined &&
          this.infoCollecter.paysNaissance != undefined &&
          this.infoCollecter.secteurActivite != undefined &&
          this.infoCollecter.situationFamilial != undefined &&
          this.infoCollecter.situationProffessionnelle != undefined &&
          this.infoCollecter.origineDeFonds != "" &&
          this.infoCollecter.origineDeFonds != undefined &&
          this.infoCollecter.revenuMensuel != "" &&
          this.infoCollecter.revenuMensuel != undefined &&
          this.infoCollecter.fatca != undefined &&
          this.infoCollecter.souscriptionOffre != undefined &&
          this.infoCollecter.autreCompte != undefined
        ) {
          if (
            this.infoCollecter.autreCompte &&
            this.infoCollecter.nomBanque != "" &&
            this.infoCollecter.nomBanque != undefined &&
            this.infoCollecter.nomBanque != undefined
          ) {
            this.disablebuttonCollecteInfo = false;
          } else if (!this.infoCollecter.autreCompte) {
            this.disablebuttonCollecteInfo = false;
          } else if (
            this.infoCollecter.motif == "Autre" &&
            (this.infoCollecter.autreMotif == "" ||
              this.infoCollecter.autreMotif == undefined)
          ) {
            this.disablebuttonCollecteInfo = true;
          } else if (
            this.infoCollecter.souscriptionOffre == "Autres" &&
            (this.infoCollecter.motivationOuvCompte == undefined ||
              this.infoCollecter.motivationOuvCompte == "")
          ) {
            this.disablebuttonCollecteInfo = true;
          } else {
            this.disablebuttonCollecteInfo = true;
          }
        } else {
          this.disablebuttonCollecteInfo = true;
        }
      }
      //       }
      //       else {
      //         if (this.prospe.prospect.fonctionnaire != true && !this.infoCollecter.fonctionnaire) {
      // console.log('dhdhdjhdhjdfdhdh');

      //           if ((this.infoCollecter.motif != undefined || (this.infoCollecter.motif == 'Autre' && this.infoCollecter.autreMotif != '' && this.infoCollecter.autreMotif != undefined)) && this.infoCollecter.nationalite != undefined && this.infoCollecter.paysNaissance != undefined && this.infoCollecter.paysResidence != undefined && this.infoCollecter.secteurActivite != undefined && this.infoCollecter.situationFamilial != undefined && this.infoCollecter.situationProffessionnelle != undefined && this.infoCollecter.origineDeFonds != '' && this.infoCollecter.origineDeFonds != undefined && this.infoCollecter.revenuMensuel != '' && this.infoCollecter.revenuMensuel != undefined && this.infoCollecter.fatca != undefined && this.infoCollecter.souscriptionOffre != undefined && this.infoCollecter.souscriptionOffre != '' && this.infoCollecter.autreCompte != undefined && (Number(this.infoCollecter.paysResidence) == 144 || Number(this.prospe.prospect.paysResidence)==144 && Number(this.infoCollecter.paysResidence) != 144 && this.isSizeCSRectoValid && this.isSizeCSVersoValid && this.isvalidCSR && this.isvalidCSV)) {
      //             console.log('dhdhdjhdhjdfdhdhbbbbbbbbbbbbbbbb', this.infoCollecter)
      //             if (this.infoCollecter.autreCompte && this.infoCollecter.nomBanque != '' && this.infoCollecter.nomBanque != undefined) {
      //               this.disablebuttonCollecteInfo = false;
      //             }
      //             else if (!this.infoCollecter.autreCompte) {
      //               this.disablebuttonCollecteInfo = false;
      //             }
      //             else {
      //               this.disablebuttonCollecteInfo = true;
      //             }
      //           }
      //           else {
      //             console.log('dhdhdjhdhjdfdhdhlllllllllllllllllllllllllllllll');
      //             this.disablebuttonCollecteInfo = true;
      //           }
      //         }
      //         else {
      //           if (((!this.prospe.prospect.fonctionnaire && this.isSizeEngagementValid && this.isEngagementFile && this.isvalidEngagment!= false) || this.prospe.prospect.fonctionnaire) && this.infoCollecter.motif != undefined || (this.infoCollecter.motif == 'Autre' && this.infoCollecter.autreMotif != '' && this.infoCollecter.autreMotif != undefined) && this.infoCollecter.nationalite != undefined && this.infoCollecter.paysNaissance != undefined && this.infoCollecter.paysResidence != undefined && this.infoCollecter.secteurActivite != undefined && this.infoCollecter.situationFamilial != undefined && this.infoCollecter.situationProffessionnelle != undefined && this.infoCollecter.origineDeFonds != '' && this.infoCollecter.origineDeFonds != undefined && this.infoCollecter.revenuMensuel != '' && this.infoCollecter.revenuMensuel != undefined && this.infoCollecter.fatca != undefined && this.infoCollecter.souscriptionOffre != undefined && this.infoCollecter.souscriptionOffre != '' && this.infoCollecter.autreCompte != undefined && (Number(this.infoCollecter.paysResidence) == 144 || Number(this.infoCollecter.paysResidence) != 144 && this.isSizeCSRectoValid && this.isSizeCSVersoValid && this.isvalidCSR!=false && this.isvalidCSV!=false && this.isCSR && this.isCSV)) {
      //             if (this.infoCollecter.autreCompte && this.infoCollecter.nomBanque != '' && this.infoCollecter.nomBanque != undefined) {
      //               this.disablebuttonCollecteInfo = false;
      //             }
      //             else if (!this.infoCollecter.autreCompte && this.infoCollecter.autreCompte != undefined) {
      //               this.disablebuttonCollecteInfo = false;
      //             }
      //             else {
      //               this.disablebuttonCollecteInfo = true;
      //             }

      //           }
      //           else {
      //             this.disablebuttonCollecteInfo = true;
      //           }
      //         }
      //       }
    }

    
  /* if (this.toOffers) {
      const currentDate: Date = new Date();
      const dateOfBirth: Date = new Date(this.infoIdentification.dateBirth);
     const age: number = Math.floor((currentDate.getTime() - dateOfBirth.getTime()) / (1000 * 60 * 60 * 24) / 365);
      const gender = this.infoIdentification.gender;

      if(this.showFileContent == false && age >= 50 && age < 60 && this.infoCollecter.souscriptionOffre != "Aide Sociale" && gender.trim() == "Mr")
      {
        this.disablebuttonOffer = false;
      }else if(this.showFileContent == true){
        this.disablebuttonOffer = true;
      }else{
        this.disablebuttonOffer = true;
      }
    }*/

    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    console.log("etape3");
    this.showFileContentSubscription.unsubscribe();
  }

  ngOnInit() {
    if (this.prospe.prospect.step >= 3) {
      this.stateOffre = "rotated";
      this.toOffers = true;
      const bar = new progressbar.Path("#step-path", {
        easing: "easeInOut",
        duration: 5000,
      });
      bar.set(0.365);
      bar.animate(0.528);
      const picurlOffre = document.getElementById(
        "idmgOffre"
      ) as HTMLImageElement;
      picurlOffre.src = "assets/image/offre.png";
      const spanClassOffre = document.getElementById(
        "LibelleOffre"
      ) as HTMLSpanElement;
      spanClassOffre.classList.add("active");
    } else {
      const bar = new progressbar.Path("#step-path", {
        easing: "easeInOut",
        duration: 5000,
      });
      bar.set(0.214);
      bar.animate(0.365);
      const picurl = document.getElementById("idmg") as HTMLImageElement;
      picurl.src = "assets/image/confirm.png";
      const spanClassConfirm = document.getElementById(
        "LibelleConfirm"
      ) as HTMLSpanElement;
      spanClassConfirm.classList.add("active");
    }
    this.infoIdentification.gender = this.gender;
    this.loadCountries();
    this.loadProfessionalSituation();
    this.getProspect();
    this.getClient();
    //this.loadCards();
    //this.loadAdditionalOffer();
    let nb: number = this.client.NbChild;
    if (nb > 0) {
      for (let i = 1; i <= nb; i++) {
        this.listChildToShow.push({
          id: i,
          display: false,
          submit: false,
          child: null,
        });
      }
    }

    if (this.prospe.prospect.paysResidence != '144') {
      this.modalService.open(
        MreModalComponent,
        { backdrop: 'static', keyboard: false }
      );
    }
  }
  descriptionCard;
  loadCards(offerId) {
    this.productSelected = undefined;
    this._listCards = [];

    this._prospectProduct.getProducts(offerId, "").subscribe(
      (result) => {
        switch (result.statusCode) {
          case Status.OK:
            this.idOffer = offerId;
            this._listCards = result.result;
            this.descriptionCard = this._listCards[0].description;
            if (this.prospe.prospect.step > 3) {
              setTimeout(() => {
                for (let i = 0; i < this._listCards.length; i++) {
                  if (
                    this._listCards[i].id ==
                    this.prospect.prospectOffer.productID
                  ) {
                    this.slickModal1.slickGoTo(i);
                    this.productSelected = new ProspectProductDto();
                    this.productSelected.id =
                      this.prospect.prospectOffer.productID;
                    this.idOffer = this.prospect.prospectOffer.offerId;
                    // setTimeout(() => {
                    //   for (let i = 0; i < this._listCards.length; i++) {
                    //     if (this._listCards[i].id == this.prospect.prospectOffer.productID) {
                    //       this.slickModal1.slickGoTo(i)
                    //       this.idOffer = this.prospect.prospectOffer.offerId
                    //       this.productSelected.id = this.prospect.prospectOffer.productID
                    //     }
                    //   }
                    // }, 0.001);
                  }
                }
              }, 0.001);
            }
            break;
          default:
            break;
        }
      },
      (err) => {
        this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
      }
    );
  }

  loadCountries() {
    this._countryProxy.getAll("").subscribe(
      (result: B3GResponseOfListOfCountryDto) => {
        switch (result.statusCode) {
          case Status.OK:
            this._listCountry = result.result.sort((a, b) =>
              a.libelleFr.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                b.libelleFr.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                ? 1
                : -1
            );
            this._listCountryBackup = this._listCountry;
            this._listCountry = this._listCountry.filter((c) => {
              return c.code != "CU";
            });
            const preferred = this._listCountry.filter((c) => {
              return c.code === "MA";
            });
            this._preferredCountry.push(preferred[0]);
            break;
          default:
            break;
        }
      },
      (err) => {
        //this.router.navigate(['/NotPageFound']);
      }
    );
  }

  loadProfessionalSituation() {
    this._sitProf.getAll("").subscribe(
      (result: B3GResponseOfListOfProfessionalDto) => {
        switch (result.statusCode) {
          case Status.OK:
            this._listSitProf = result.result.sort((a, b) =>
              a.libelle.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                b.libelle.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                ? 1
                : -1
            );
            this._listSitProfBackup = this._listSitProf;
            if (this.prospect.step > 2) {
              this.situationPro = this._listSitProf.filter(
                (e) => e.id == this.prospect.situationProfession
              )[0];
              this.situationProBackup = this.situationPro;
              if (this.situationPro.affichageAutreInfo == false) {
                this._prospectService
                  .getProfession(this.infoCollecter.socioProfessional, "")
                  .subscribe((e) => {
                    this.listProfession = e.result.sort((a, b) =>
                      a.labelCodeProfession
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "") >
                        b.labelCodeProfession
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                        ? 1
                        : -1
                    );
                    this.infoCollecter.profession = this.prospect.profession;
                  });
              }
            }
            break;
          default:
            break; 
        }
      },
      (err) => {
        this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
      }
    );
  }
  productSelected = new ProspectProductDto();
  onSelectionChange(slide) {
    this.productSelected = slide;
    const ShowElement = document.getElementById(
      "OfferPrincipale"
    ) as HTMLDivElement;
    const ShowElementMobile = document.getElementById(
      "OfferPrincipaleMobile"
    ) as HTMLDivElement;
    ShowElement.classList.remove("hideOffre");
    ShowElement.classList.add("showOffre");
    this.selectedSlide = Object.assign({}, this.selectedSlide, slide);
    this.getImage = this.selectedSlide.picture;
    this.getLabel = this.selectedSlide.libelle;
    this.getPrixCard = this.selectedSlide.prix;
    this.descriptionCard = this.selectedSlide.description;
    this.getIdCard = "Code 30";
    this._cardId = this.selectedSlide.id;
    this.validateCardCodeTrente = true;
  }
  editMailAttempt() {
    this.editMail = true;
  }
  closeEditEmail() {
    this.editMail = false;
  }

  getClient(): Subscriber {
    if (this._vProspectConfirmer.objectProspect == undefined) {
      //  this.router.navigate(['/NotPageFound']);
    } else {
      if (this._vProspectConfirmer.objectProspect["_id"])
        this.client.idClient = this._vProspectConfirmer.objectProspect["_id"];
      else {
        this.client.idClient = this._vProspectConfirmer.objectProspect["id"];
      }
      this.client.Gender = this._vProspectConfirmer.objectProspect["_gender"];
      this.client.FirstName =
        this._vProspectConfirmer.objectProspect["_firstName"];
      this.client.LastName =
        this._vProspectConfirmer.objectProspect["_lastName"];
      this.client.BirthDay =
        this._vProspectConfirmer.objectProspect["_dateBirth"];
      this.client.Gsm = this._vProspectConfirmer.objectProspect["_mobile"];
      this.client.Mail = this._vProspectConfirmer.objectProspect["_mail"];
      this.client.NbChild = this._vProspectConfirmer.objectProspect["_nbChild"];
      this.client.IsFonctionnaire =
        this._vProspectConfirmer.objectProspect["_fonctionnaire"];
      if (this._vProspectConfirmer.objectProspect["_offerId"])
        this.client.OfferId =
          this._vProspectConfirmer.objectProspect["_offerId"];
      else {
        this.client.OfferId =
          this._vProspectConfirmer.objectProspect["offerId"];
      }
      this.client.OfferLabel =
        this._vProspectConfirmer.objectProspect["_offerLabel"];
      this.client.OfferPicture =
        this._vProspectConfirmer.objectProspect["_offerPicture"];

      for (var _i = 1; _i <= this.client.NbChild; _i++) {
        this.ListChildNb.push(_i);
        this.EnfantChecked.push(false);
      }
      return this.client;
    }
  }
  prospect: ProspectInfoDto;
  datealidite: Date;
  getProspect() {
    this.prospect = this.prospe.prospect;
    this.newEmail = this.prospect.email;
    if (this.prospect.step == 1) {
      this.toCollecte = true;
    }
    if (this.prospect.step == 2) {
      this.collecte = true;
      this.status = "modifier";
      this.infoIdentification = Object.assign({}, this.prospect);
      this.datealidite = new Date(this.infoIdentification.dateValidite);
      if (this.infoIdentification.adrDiffCourrier != "Aucune") {
        this.AACchecked = true;
      } else {
        this.infoIdentification.adrDiffCourrier = "";
      }
      if (this.infoIdentification.adrDiffResidence != "Aucune") {
        this.AARchecked = true;
      } else {
        this.infoIdentification.adrDiffResidence = "";
      }
    }
    if (this.prospect.step != 2 && this.prospect.step != 1) {
      this.toOffers = true;
      this.infoIdentification = Object.assign({}, this.prospect);
      this.datealidite = new Date(this.infoIdentification.dateValidite);
      if (this.infoIdentification.adrDiffCourrier != "Aucune") {
        this.AACchecked = true;
      } else {
        this.infoIdentification.adrDiffCourrier = "";
      }
      if (this.infoIdentification.adrDiffResidence != "Aucune") {
        this.AARchecked = true;
      } else {
        this.infoIdentification.adrDiffResidence = "";
      }
      this.infoCollecter = Object.assign({}, this.prospect);

      this.infoCollecter.situationProffessionnelle =
        this.prospect.situationProfession.toString();

        /*if(this.infoCollecter.souscriptionOffre = "Aide sociale"){
          this.prospect.prospectOffer.offerId = "9";
          this.listOffer = this.prospect.prospectOffers;
        }else{
          this.listOffer = this.prospect.prospectOffers;
        }*/

        this.listOffer = this.prospect.prospectOffers;
        
      if (this.prospect.step == 3) {
        this.loadCards(this.listOffer[0].offerId);
      }

      if (this.prospect.step > 3) {
        this.productSelected.id = this.prospect.prospectOffer.productID;
        setTimeout(() => {
          for (let i = 0; i < this.listOffer.length; i++) {
            if (
              this.listOffer[i].offerId == this.prospect.prospectOffer.offerId
            ) {
              this.slickModal.slickGoTo(i);
            }
          }
        }, 0.001);
        this.loadCards(this.prospect.prospectOffer.offerId);
      }
    }
  }

  @Output() showChildOnChange = new EventEmitter();
  EnfantChecked: Array<boolean> = [];
  onSelectionChild(e, i: number) {
    this.checkChild = e.target.checked;
    this.listChildToShow[i - 1].display = e.target.checked;

    if (!e.target.checked) {
      this.listChildToShow[i - 1].submit = e.target.checked;
    }
    this.EnfantChecked[i - 1] = e.target.checked;
  }
  onChooseEpargne(e) {
    if (e.target.checked) {
      this._offerId = this._listAditionalOffer.id;
    } else {
      this._offerId = null;
    }
  }

  onFileChangedCinRecto(event) {
    this.fileRecto = null;
    this.isCINRecto = false;
    if (event.target.files) {
      let file = event.target.files[0];
      this.isCINRecto = true;
      this.isvalidRecto = true;
      this.fileRectoAllElement.name = file.name;

      const validImageTypes = ["image/jpeg", "image/png"];
      const fileType = file["type"];
      this.isSizeRectoValid = file["size"] < 15000000;
     
      if (file) {
        var reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }

      if (!validImageTypes.includes(fileType)) return;
      this.selectFileRecto(event);
    }
  }
  /* onFileChangedjustif(event) {
    this.fileRecto = null;
    if (event.target.files) {
      let file = event.target.files[0];

      this.FileJustifAllElement.name = file.name;

      const validImageTypes = ["image/jpeg", "image/png", "application/pdf"];
      const fileType = file["type"];
      this.isSizeJustif = file["size"] < 15000000;
     
      if (file) {
        var reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }

      if (!validImageTypes.includes(fileType)) return;
      this.selectJustifRecto(event);
    }
  }*/

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.fileRectoAllElement.content = btoa(binaryString);
  }

  handleReaderLoadedVerto(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.fileVersoAllElement.content = btoa(binaryString);
  }

  handleReaderLoadedJAC(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.justificatifAddresseCourier.content = btoa(binaryString);
  }

  handleReaderLoadedJAR(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.justificatifAddresseResidence.content = btoa(binaryString);
  }

  handleReaderLoadedEngagement(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.fileEnagementAllElement.content = btoa(binaryString);
  }

  onFileChangedCinVerso(event) {
    this.fileVerso = null;
    this.isCINVerso = false;
    if (event.target.files[0]) {
      let file = event.target.files[0];
      const validImageTypes = ["image/jpeg", "image/png"];
      const fileType = file["type"];
      
      if (file) {
        var reader = new FileReader();
        reader.onload = this.handleReaderLoadedVerto.bind(this);
        reader.readAsBinaryString(file);
      }

      this.isCINVerso = true;
      this.isvalidVerso = true;

      this.fileVersoAllElement.name = file.name;
      this.isSizeVersoValid = file["size"] < 15000000;
      
      if (file) {
        if (!validImageTypes.includes(fileType)) return;
         this.selectFileVerso(event);
      }
    }
  }

  onFileChangedEngagement(event) {
    this.enagement = null;
    this.isEngagementFile = false;
    if (event.target.files[0]) {
      let file = event.target.files[0];
      const validImageTypes = ["image/jpeg", "image/png"];
      const fileType = file["type"];

      if (file) {
        var reader = new FileReader();
        reader.onload = this.handleReaderLoadedEngagement.bind(this);
        reader.readAsBinaryString(file);
      }
      this.isEngagementFile = true;
      this.isvalidEngagment = true;

      this.fileEnagementAllElement.name = file.name;
      this.isSizeEngagementValid = file["size"] < 15000000;

      if (file) {
        if (validImageTypes.includes(fileType)) return;
          this.selectFileEngagement(event);
      }
    }
  }

  onFileChangedJAC(event) {
    this.juAC = null;
    this.isvalidJUC = false;
    if (event.target.files[0]) {
      let file = event.target.files[0];
      const validImageTypes = ["image/jpeg", "image/png"];
      const fileType = file["type"];
      
      if (file) {
        var reader = new FileReader();
        reader.onload = this.handleReaderLoadedJAC.bind(this);
        reader.readAsBinaryString(file);
      }

      this.isJAC = true;
      this.isvalidJUC = true;
      this.justificatifAddresseCourier.name = file.name;
      this.isSizeJAC = file["size"] < 15000000;
     
      if (file) {
        if (!validImageTypes.includes(fileType))
          return; 
        this.selectFileJAC(event);
      }
    }
  }

  onFileChangedJAR(event) {
    this.juAR = null;
    this.isvalidJUR = false;
    if (event.target.files[0]) {
      let file = event.target.files[0];
      const validImageTypes = ["image/jpeg", "image/png"];
      const fileType = file["type"];
      if (file) {
        var reader = new FileReader();
        reader.onload = this.handleReaderLoadedJAR.bind(this);
        reader.readAsBinaryString(file);
      }
      this.isJAR = true;
      this.isvalidJUR = true;
      this.justificatifAddresseResidence.name = file.name;
      this.isSizeJAR = file["size"] < 15000000;
     
      if (file) {
        if (validImageTypes.includes(fileType)) return;
        this.selectFileJAR(event);
      }
    }
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        let canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context = canvas.getContext("2d");

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }
  imgResultBeforeCompress: string;
  //imgResultAfterCompress: string;
  file: any;
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;
  selectFileVerso(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileVerso(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  selectFileJAC(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileJAC(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  selectFileJAR(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileJAR(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  selectFileRecto(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileRecto(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  selectJustifRecto(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileJustif(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  selectFileEngagement(event) {
    var fileName: any;
    this.file = event.target.files[0];
    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFileEngagement(this.localUrl, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  compressFileVerso(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.fileVersoAllElement.size = imageBlob.size.toString();
        this.fileVersoAllElement.type = imageBlob.type;
        this.fileVersoAllElement.name = fileName;
        this.fileVerso = imageBlob.size;
        const imageFile = new File([result], fileName, {
          type: this.fileVersoAllElement.type,
        });
        this.fileVersoAllElement.content =
          this.localCompressedURl.split(",")[1];
      });
  }

  compressFileRecto(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    let imageFile;
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.fileRectoAllElement.size = imageBlob.size.toString();
        this.fileRectoAllElement.type = imageBlob.type;
        this.fileRectoAllElement.name = fileName;
        this.fileRecto = imageBlob.size;
        imageFile = new File([result], fileName, {
          type: this.fileRectoAllElement.type,
        });
        this.fileRectoAllElement.content =
          this.localCompressedURl.split(",")[1];
      });
  }
  compressFileJustif(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    let imageFile;
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.FileJustifAllElement.size = imageBlob.size.toString();
        this.FileJustifAllElement.type = imageBlob.type;
        this.FileJustifAllElement.name = fileName;
        this.fileRecto = imageBlob.size;
        imageFile = new File([result], fileName, {
          type: this.FileJustifAllElement.type,
        });
        this.FileJustifAllElement.content =
          this.localCompressedURl.split(",")[1];
      });
  }

  compressFileJAC(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    let imageFile;
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.justificatifAddresseCourier.size = imageBlob.size.toString();
        this.justificatifAddresseCourier.type = imageBlob.type;
        this.justificatifAddresseCourier.name = fileName;
        this.fileRecto = imageBlob.size;
        imageFile = new File([result], fileName, {
          type: this.justificatifAddresseCourier.type,
        });
        this.justificatifAddresseCourier.content =
          this.localCompressedURl.split(",")[1];
      });
  }

  compressFileJAR(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    let imageFile;
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.justificatifAddresseResidence.size = imageBlob.size.toString();
        this.justificatifAddresseResidence.type = imageBlob.type;
        this.justificatifAddresseResidence.name = fileName;
        this.fileRecto = imageBlob.size;
        imageFile = new File([result], fileName, {
          type: this.justificatifAddresseResidence.type,
        });
        this.justificatifAddresseResidence.content =
          this.localCompressedURl.split(",")[1];
      });
  }

  compressFileEngagement(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    this.imageCompress
      .compressFile(
        image,
        orientation,
        this.paramData.ratio,
        this.paramData.quality
      )
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        this.fileEnagementAllElement.size = imageBlob.size.toString();
        this.fileEnagementAllElement.type = imageBlob.type;
        this.fileEnagementAllElement.name = fileName;
        this.enagement = imageBlob.size;
        const imageFile = new File([result], fileName, {
          type: this.fileEnagementAllElement.type,
        });
        this.fileEnagementAllElement.content =
          this.localCompressedURl.split(",")[1];
      });
  }
  validateIdentification = false;
  validateCIN() {
    if (
      this.infoIdentification.identification.length < 4 ||
      this.infoIdentification.identification.length > 8
    ) {
      this.validateIdentification = true;
    } else {
      this.validateIdentification = false;
    }
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }
  gender = "Mme";
  dateBirth;
  AACchecked = false;
  AARchecked = false;
  listOffer;
  onGenderMr(event) {
    if (event.target.checked) {
      this.gender = "Mr";
      this.infoIdentification.gender = this.gender;
    }
  }
  onGenderMme(event) {
    if (event.target.checked) {
      this.gender = "Mme";
      this.infoIdentification.gender = this.gender;
    }
  }
  getBirthDate(event) {
    let dt: any;
    dt = this.datepipe.transform(event.value, "dd-MM-yyyy");
    this.infoIdentification.dateBirth = event.value;
  }
  getDateValidite(event) {
    let dt: any;
    dt = this.datepipe.transform(event.value, "dd/MM/yyyy");
    this.infoIdentification.dateValidite = event.value;
  }
  onCheckAAC($event) {
    this.AACchecked = $event.target.checked;
    if (!$event.target.checked) {
      this.justificatifAddresseCourier = new Justificatif();
      this.infoIdentification.adrDiffCourrier = "";
    }
  }
  onCheckAAR($event) {
    this.AARchecked = $event.target.checked;
    if (!$event.target.checked) {
      this.justificatifAddresseResidence = new Justificatif();
      this.infoIdentification.adrDiffResidence = "";
    }
  }

  onFatca(event) {
    this.isFatca = event.target.value == 1;
    if (this.isFatca) {
      this.infoCollecter.fatca = true;
    } else {
      this.infoCollecter.fatca = false;
    }
  }
  
  onFatcaConfirmation(event) {
    if (event.target.value == 1) {
      this.infoCollecter.fatca = true;
    } else {
      this.isFatca = false;
      this.infoCollecter.fatca = false;
    }
  }
  
  fonc;
  onFonctionnaire(event) {
    if (event.target.value == 1) {
      this.infoCollecter.fonctionnaire = true;
      this.fonc = true;
      this.infoCollecter.situationProffessionnelle = "10";
      this.situationPro = this._listSitProfBackup.filter(
        (e) => e.id == Number(this.infoCollecter.situationProffessionnelle)
      )[0];
    } else {
      this.infoCollecter.fonctionnaire = false;
      this.fonc = false;
      this.isvalidEngagment = false;
      this.infoCollecter.situationProffessionnelle = undefined;
      this.situationPro = undefined;
    }
  }
  onChangeBank(event) {
    if (event.target.value == 1) {
      this.infoCollecter.autreCompte = true;
    } else {
      this.infoCollecter.autreCompte = false;
      this.infoCollecter.nomBanque = "";
    }
  }
  saveIdentification() {
    if (this.status == "insert") {
      if (this.fileRecto === null) {
        this.isCINRecto = false;
        return;
      } else {
        this.isCINRecto = true;
      }
      if (this.fileVerso === null) {
        this.isCINVerso = false;
        return;
      } else {
        this.isCINVerso = true;
      }
      if (!this.isSizeRectoValid) {
        this.isvalidRecto = false;
        return;
      }
      if (!this.isSizeVersoValid) {
        this.isvalidVerso = false;
        return;
      }
    }

    this.completeProspectDto.identification = new InfoOCR({
      value: this.infoIdentification.identification,
      modified: true,
    });
    this.completeProspectDto.gender = this.infoIdentification.gender;
    this.completeProspectDto.lieuNaissance = new InfoOCR({
      value: this.infoIdentification.lieuNaissance,
      modified: true,
    });
    this.completeProspectDto.nomMere = new InfoOCR({
      value: this.infoIdentification.nomMere,
      modified: true,
    });
    this.completeProspectDto.nomPere = new InfoOCR({
      value: this.infoIdentification.nomPere,
      modified: true,
    });
    this.completeProspectDto.adresseCIN = new InfoOCR({
      value: this.infoIdentification.adresseCIN,
      modified: true,
    });
    this.completeProspectDto.dateBirth = new InfoOCR({
      value: this.datepipe.transform(
        this.infoIdentification.dateBirth,
        "dd-MM-yyyy"
      ),
      modified: true,
    });
    this.completeProspectDto.dateValididte = new InfoOCR({
      value: this.datepipe.transform(
        this.infoIdentification.dateValidite,
        "dd-MM-yyyy"
      ),
      modified: true,
    });
    this.completeProspectDto.canal = "Web";
    this.completeProspectDto.insertOrModif = "insert";
    if (this.AACchecked) {
      this.completeProspectDto.adrDiffCourrier =
        this.infoIdentification.adrDiffCourrier;
    } else {
      this.completeProspectDto.adrDiffCourrier = "Aucune";
    }
    if (this.AARchecked) {
      this.completeProspectDto.adrDiffResidence =
        this.infoIdentification.adrDiffResidence;
    } else {
      this.completeProspectDto.adrDiffResidence = "Aucune";
    }
    // file
    let recto = new MediaFileProspectDto();
    recto.size = +this.fileRectoAllElement.size;
    recto.fileContent = this.fileRectoAllElement.content;
    recto.mimeType = this.fileRectoAllElement.type;
    recto.name = this.fileRectoAllElement.name;
    recto.prospectId = +this.client.idClient;
    this.completeProspectDto.cinRecto = recto;
    let verso = new MediaFileProspectDto();
    verso.size = +this.fileVersoAllElement.size;
    verso.fileContent = this.fileVersoAllElement.content;
    verso.mimeType = this.fileVersoAllElement.type;
    verso.name = this.fileVersoAllElement.name;
    verso.prospectId = +this.client.idClient;
    this.completeProspectDto.cinVerso = verso;
    let jac = new MediaFileProspectDto();
    if (this.AACchecked == true) {
      jac.size = +this.justificatifAddresseCourier.size;
      jac.fileContent = this.justificatifAddresseCourier.content;
      jac.mimeType = this.justificatifAddresseCourier.type;
      jac.name = this.justificatifAddresseCourier.name;
      this.completeProspectDto.justificatifAdresseCourier = jac;
    } else {
      this.completeProspectDto.justificatifAdresseCourier =
        new MediaFileProspectDto();
      this.completeProspectDto.justificatifAdresseCourier.fileContent = null;
    }
    let jar = new MediaFileProspectDto();
    if (this.AARchecked == true) {
      jar.size = +this.justificatifAddresseResidence.size;
      jar.fileContent = this.justificatifAddresseResidence.content;
      jar.mimeType = this.justificatifAddresseResidence.type;
      jar.name = this.justificatifAddresseResidence.name;
      this.completeProspectDto.justificatifAdresseResidence = jar;
    } else {
      this.completeProspectDto.justificatifAdresseResidence =
        new MediaFileProspectDto();
      this.completeProspectDto.justificatifAdresseResidence.fileContent = null;
    }

    this._prospectService
      .mobileCompleteProspect(
        this.completeProspectDto,
        localStorage.getItem("sessionId")
      )
      .subscribe(
        (result) => {
          if (result.statusCode == "000") {
            const bar = new progressbar.Path("#step-path", {
              easing: "easeInOut",
              duration: 5000,
            });
            bar.set(0.214);
            bar.animate(0.365);
            const picurl = document.getElementById("idmg") as HTMLImageElement;
            picurl.src = "assets/image/confirm.png";
            const spanClassConfirm = document.getElementById(
              "LibelleConfirm"
            ) as HTMLSpanElement;
            spanClassConfirm.classList.add("active");
            if (this.toOffers == true) {
              this.stateOffre = "default";
              this.animationStateOffer = true;
              setTimeout(() => {
                this.toOffers = false;
              }, 600);
            }
            this._prospectService
              .faceMatching(
                new FileInput({ file: null }),
                localStorage.getItem("sessionId")
              )
              .subscribe((e) => {
                this._prospectService
                  .getProspectInfo(localStorage.getItem("sessionId"))
                  .subscribe((el) => {
                    this.prospect = el.result;
                    this.prospe.prospect = el.result;
                    // this.toCollecte = false
                    this.animationStateIdentification = true;
                    setTimeout(() => {
                      this.toCollecte = false;
                    }, 600);
                    this.stateIdentification = "default";
                    this.stateCollecte = "rotated";
                    this.collecte = true;
                    setTimeout(() => {
                      this.animationStateCollecte = false;
                    }, 50);
                    if (
                      this.infoCollecter.revenuMensuel != "" &&
                      this.infoCollecter.revenuMensuel != undefined
                    ) {
                      setTimeout(() => {
                        this.animationStateCollecte = false;
                        (
                          document.getElementById(
                            "rmensuel"
                          ) as HTMLLabelElement
                        ).classList.add("active");
                      }, 50);
                    }
                  });
              });
          } else {
            alert("une erreur est survenu, merci de reessayer plutard");
          }
        },
        (err) => {
          this.ngZone.run(() => this.router.navigate(["/NotPageFound"])).then();
        }
      );
  }
  idOffer;
  collectInfo() {

    this.restOfInfo.situationFamilial = this.infoCollecter.situationFamilial;

    this.restOfInfo.paysNaissance = this.infoCollecter.paysNaissance;
    this.restOfInfo.motif = this.infoCollecter.motif;

    if (this.infoCollecter.souscriptionOffre == "Autres") 
      this.restOfInfo.autreSouscriptionOffre = this.infoCollecter.motivationOuvCompte;
    this.restOfInfo.revenuMensuelle = this.infoCollecter.revenuMensuel;
    this.restOfInfo.secteurActivite = this.infoCollecter.secteurActivite;
    this.restOfInfo.profession = this.infoCollecter.profession;
    this.restOfInfo.souscriptionOffre = this.infoCollecter.souscriptionOffre;
    this.restOfInfo.origineFonds = this.infoCollecter.origineDeFonds;
    this.restOfInfo.situationProfessionnelle = Number(
      this.infoCollecter.situationProffessionnelle
    );
    this.restOfInfo.fatca = this.infoCollecter.fatca;
    this.restOfInfo.socioProfessinal = this.infoCollecter.socioProfessional;

    this.restOfInfo.canal = "WEB";
    if (this.infoCollecter.autreCompte) {
      this.restOfInfo.otherAccount = this.infoCollecter.autreCompte;
      this.restOfInfo.nmBanque = this.infoCollecter.nomBanque;
    } else {
      this.restOfInfo.otherAccount = this.infoCollecter.autreCompte;
    }
    let engagement = new MediaFileProspectDto();
    if (this.infoCollecter.fonctionnaire) {
      this.restOfInfo.fonctionnaire = this.infoCollecter.fonctionnaire;
      engagement.size = +this.fileEnagementAllElement.size;
      engagement.fileContent = this.fileEnagementAllElement.content;
      engagement.mimeType = this.fileEnagementAllElement.type;
      engagement.name = this.fileEnagementAllElement.name;
      this.restOfInfo.actEngagement = engagement;
    } else {
      this.restOfInfo.fonctionnaire = this.infoCollecter.fonctionnaire;
      this.restOfInfo.actEngagement = new MediaFileProspectDto();
      this.restOfInfo.actEngagement.fileContent = null;
    }

    this._prospectService
      .mobileTheRestOfInfo(this.restOfInfo, localStorage.getItem("sessionId"))
      .subscribe((e) => {
        if (e.statusCode == "000") {
          const bar = new progressbar.Path("#step-path", {
            easing: "easeInOut",
            duration: 5000,
          });
          bar.set(0.365);
          bar.animate(0.528);

          const picurlOffre = document.getElementById(
            "idmgOffre"
          ) as HTMLImageElement;
          picurlOffre.src = "assets/image/offre.png";
          const spanClassOffre = document.getElementById(
            "LibelleOffre"
          ) as HTMLSpanElement;
          spanClassOffre.classList.add("active");

          this._prospectService
            .getProspectInfo(localStorage.getItem("sessionId"))
            .subscribe((el) => {
              this.prospect = el.result;
              this.prospe.prospect = el.result;
              this.listOffer = e.result;
              this.loadCards(this.listOffer[0].offerId);
              // this.collecte = false
              this.animationStateCollecte = true;
              this.stateCollecte = "default";
              this.stateOffre = "rotated";
              setTimeout(() => {
                this.collecte = false;
              }, 600);
              this.toOffers = true;

              setTimeout(() => {
                this.animationStateOffer = false;
              }, 50);
            });
        } else if (e.statusCode == "10300") {
          this.modalService.open(PopupFatcaComponent);
        }
      });
  }

  onSelectionChangeOffer(slide) {
    this.loadCards(slide.offerId);
  }

 // handleAddedContent(): void {
    // Logique à exécuter lorsque du contenu est ajouté dans la popup
   // console.log('Contenu ajouté dans la popup');
    //this.showFileContent = true; // Afficher le contenu du fichier
  //}
  //parentData(data:any){
  //  alert(data);
  //}

  handleAddedContent(added: boolean): void {
    // Mettre à jour showFileContent en fonction de la valeur émise
    this.showFileContent = added;
  }

  setffer() {

    /* const currentDate: Date = new Date();
    const dateOfBirth: Date = new Date(this.infoIdentification.dateBirth);
   const age: number = Math.floor((currentDate.getTime() - dateOfBirth.getTime()) / (1000 * 60 * 60 * 24) / 365);
    console.log(age);
    const gender = this.infoIdentification.gender;
    //if(age >= 50 && age < 60 && this.infoCollecter.souscriptionOffre != "Aide Sociale" && gender.trim() == "Mr"){

   if(this.showFileContent == false && age >= 50 && age < 60 && this.infoCollecter.souscriptionOffre != "Aide Sociale" && gender.trim() == "Mr"){
       this.modalService.open(ModalAideSocialComponent);
   }else{

      let inputOffer = new OffersInput();
      inputOffer.offerId = this.idOffer;
      inputOffer.productId = Number(this.productSelected.id);
      inputOffer.canal = "Web";
          // file
    let recto = new MediaFileProspectDto();
    recto.size = +this.FileJustifAllElement.size;
    recto.fileContent = this.FileJustifAllElement.content;
    recto.mimeType = this.FileJustifAllElement.type;
    recto.name = this.FileJustifAllElement.name;
    recto.prospectId = +this.client.idClient;
     inputOffer.justificatifPensionRetraite = recto;
     
      this._prospectService
        .setOffers(inputOffer, localStorage.getItem("sessionId"))
        .subscribe((e) => {
          if (e.statusCode == "000") {
            this.ngZone
              .run(() => this.router.navigate(["/PriseDeContact"]))
              .then();
          }
        });  
    }
   */

    let inputOffer = new OffersInput();
    inputOffer.offerId = this.idOffer;
    inputOffer.productId = Number(this.productSelected.id);
    inputOffer.canal = "Web";
    this._prospectService
      .setOffers(inputOffer, localStorage.getItem("sessionId"))
      .subscribe((e) => {
        if (e.statusCode == "000") {
          this.ngZone
            .run(() => this.router.navigate(["/PriseDeContact"]))
            .then();
        }
      });

  }

  arryOfNumber;
  otp = "";
  showOtpForm = false;


 
  showStep(step) {
    switch (step) {
      case "Identification":
        if (this.prospe.prospect.step != 1) {
          this.status = "modifier";
          this.stateIdentification =
            this.stateIdentification === "default" ? "rotated" : "default";
          if (this.toCollecte == true) {
            this.animationStateIdentification = true;
            setTimeout(() => {
              this.toCollecte = false;
            }, 600);
          } else {
            this.toCollecte = true;
            setTimeout(() => {
              this.animationStateIdentification = false;
              (
                document.getElementById("cin") as HTMLLabelElement
              ).classList.add("active");
              (
                document.getElementById("adrcin") as HTMLLabelElement
              ).classList.add("active");
              (document.getElementById("np") as HTMLLabelElement).classList.add(
                "active"
              );
              (document.getElementById("nm") as HTMLLabelElement).classList.add(
                "active"
              );
              if (this.AARchecked) {
                (
                  document.getElementById("aar") as HTMLLabelElement
                ).classList.add("active");
              }
              if (this.AACchecked) {
                (
                  document.getElementById("aac") as HTMLLabelElement
                ).classList.add("active");
              }
            }, 50);
          }
        }
        break;
      case "Collecte":
        if (this.prospe.prospect.step != 1 && this.prospe.prospect.step != 2) {
          this.stateCollecte =
            this.stateCollecte === "default" ? "rotated" : "default";

          if (this.collecte == true) {
            this.animationStateCollecte = true;
            setTimeout(() => {
              this.collecte = false;
            }, 600);
          } else {
            this.collecte = true;
            setTimeout(() => {
              this.animationStateCollecte = false;
              (
                document.getElementById("rmensuel") as HTMLLabelElement
              ).classList.add("active");
              (
                document.getElementById(
                  "souscriptionOffre1"
                ) as HTMLLabelElement
              ).classList.add("active");
              (
                document.getElementById("autreMotif") as HTMLLabelElement
              ).classList.add("active");
            }, 50);
            this.isvalidEngagment = false;
          }
        }
        break;
      case "Offres":
        if (
          this.prospe.prospect.step != 3 &&
          this.prospe.prospect.step != 1 &&
          this.prospe.prospect.step != 2
        ) {
          this.stateOffre =
            this.stateOffre === "default" ? "rotated" : "default";
          if (this.toOffers == true) {
            this.animationStateOffer = true;
            setTimeout(() => {
              this.toOffers = false;
            }, 600);
          } else {
            this.toOffers = true;
            setTimeout(() => {
              this.animationStateOffer = false;
            }, 50);
          }
        }
        break;
      default:
        break;
    }
  }
  afterChange(event) {
    if (this.listOffer.length > 1) {
      this.loadCards(this.listOffer[event.currentSlide].offerId);
    }
  }
  afterChangeCard(event) {
    this.descriptionCard = this._listCards[event.currentSlide].description;
  }
  getProfessionFromSocio(id) {
    this.infoCollecter.profession = undefined;
    this._prospectService.getProfession(id, "").subscribe((e) => {
      this.listProfession = e.result.sort((a, b) =>
        a.labelCodeProfession.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.labelCodeProfession.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );
    });
  }
  situationPro = new ProfessionalDto();
  situationProBackup = new ProfessionalDto();
  checkFonctionaire(id) {
    this.situationPro = this._listSitProfBackup.filter((e) => e.id == id)[0];
    this.situationProBackup = this.situationPro;
    this.infoCollecter.profession = undefined;
    this.infoCollecter.socioProfessional = undefined;
    this.infoCollecter.secteurActivite = undefined;
    if (id == 10) {
      this.infoCollecter.fonctionnaire = true;
    } else {
      this.infoCollecter.fonctionnaire = false;
    }
  }

  onKey(value, list, type) {
    if (value != "") {
      switch (type) {
        case "PN":
          this._listCountry = list.filter((option) =>
            option.libelleFr.toLowerCase().startsWith(value.toLowerCase())
          );
          break;
        case "Mo":
          this.motifData = list.filter((option) =>
            option.motif1.toLowerCase().startsWith(value.toLowerCase())
          );
          break;
        case "SoP":
          this.listSocioProfessionnelle = list.filter((option) =>
            option.libelleCodeSocioProfessional
              .toLowerCase()
              .startsWith(value.toLowerCase())
          );
          break;
        case "SiP":
          this._listSitProf = list.filter((option) =>
            option.libelle.toLowerCase().startsWith(value.toLowerCase())
          );
          break;
        case "SA":
          this.listeSecteurActivite = list.filter((option) =>
            option.seteurActivite.toLowerCase().startsWith(value.toLowerCase())
          );
          break;
        case "OF":
          this.listeOrigineFonds = list.filter((option) =>
            option.origineFonds.toLowerCase().startsWith(value.toLowerCase())
          );
          break;
        case "LN":
          this.ListVille = list.filter((option) =>
            option.toLowerCase().startsWith(value.toLowerCase())
          );
          break;

        default:
          break;
      }
    } else {
      switch (type) {
        case "PN":
          this._listCountry = this._listCountryBackup;
          break;
        case "Mo":
          this.motifData = this.motifDataBackup;
          break;
        case "SoP":
          this.listSocioProfessionnelle = this.listSocioProfessionnelleBackup;
          break;
        case "SiP":
          this._listSitProf = this._listSitProfBackup;
          break;
        case "SA":
          this.listeSecteurActivite = this.listeSecteurActiviteBackup;
          break;
        case "OF":
          this.listeOrigineFonds = this.listeOrigineFondsBackup;
          break;
        case "LN":
          this.ListVille = this.ListVilleBackup;
          break;

        default:
          break;
      }
    }
  }
  
  /*
  onMotivationSelectionChange() {
    if (this.infoCollecter.souscriptionOffre === 'Aide Sociale') {
      this.openAideSocialPopup();
    }
  }
  
  openAideSocialPopup() {
     this.modalService.open(ModalAideSocialComponent);
  } */

  initList() {
    this._listCountry = this._listCountryBackup;
  }
  public onInputKeyPress(event): void {
    const pattern = /^[1-9][0-9]$/;
    const pat = /[0-9.,]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pat.test(inputChar)) {
      event.preventDefault();
    }
  }
}
class FileRectoAllElement {
  public type: string;
  public name: string;
  public size: string;
  public content: string;
}

class FileVersoAllElement {
  public type: string;
  public name: string;
  public size: string;
  public content: string;
}
class FileEngagementAllElement {
  public type: string;
  public name: string;
  public size: string;
  public content: string;
}
class Justificatif {
  public type: string;
  public name: string;
  public size: string;
  public content: string;
}
class FileJustifAllElement {
  public type: string;
  public name: string;
  public size: string;
  public content: string;
}